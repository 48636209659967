import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import ActionsInput from '@components/action/crud/ActionsInput';

import { search } from '@api/actionApi';

import * as actionActions from '@actions/actionActions';
import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

export const transformActionToValue = (action) => ({
  label: action.name || action.id,
  value: action.id,
  action: action,
});

class TripInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      action: this.props.action ? transformActionToValue(this.props.action) : null,
      actions: [],
    };
  }

  componentDidMount() {
    const { actionType, searchActions } = this.props;

    search({ query: actionType }, 0, 40).then((response) => {
      this.setState({
        actions: response.actions.filter((action) => action.type === actionType),
      });
    });
  }

  onChange = (newAction) => {
    this.setState({
      action: newAction,
    });
    const { onChange } = this.props;
    onChange && onChange(newAction ? newAction.action : null);
  };

  render() {
    const { t, actionType, isFetchingActions, searchActions, addToStack, placeholder } = this.props;
    const { action, actions } = this.state;

    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          defaultOptions={actions.map((action) => transformActionToValue(action))}
          placeholder={placeholder ? placeholder : `${t('form.label.search')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingActions}
          isDisabled={isFetchingActions}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              search({ query: inputValue }, 0, 40).then((response) => {
                callback(
                  response.actions
                    .filter((action) => action.type === actionType)
                    .map((action) => transformActionToValue(action))
                );
              });
            }, 400);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('form.label.addAction'),
              component: (
                <ActionsInput
                  targetName={inputValue}
                  callback={(newAction) => {
                    this.onChange(transformActionToValue(newAction));
                  }}
                />
              ),
            });
          }}
          value={action}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(vehicleActions.changeValue(name, value)),
    searchActions: (searchParameters, from, amount) =>
      dispatch(actionActions.getActions(from, amount)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TripInput));
