import { pricingConstants } from '@constants';

import * as pricingApi from '@api/pricingApi';

export const changeValue = (name, value) => ({
  type: pricingConstants.PRICING_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching pricing
 */
export const startGetPricing = () => ({
  type: pricingConstants.GET_PRICING_STARTED,
});

export const getPricingSuccess = (result) => ({
  type: pricingConstants.GET_PRICING_SUCCESS,
  ...result,
});

export const getPricingFailure = (error) => ({
  type: pricingConstants.GET_PRICING_FAILURE,
  ...error,
});

export const getPricing = (id) => {
  return (dispatch) => {
    dispatch(startGetPricing());

    const promise = pricingApi.get(id);

    promise
      .then((result) => {
        dispatch(getPricingSuccess(result));
      })
      .catch((error) => {
        dispatch(getPricingFailure(error));
      });

    return promise;
  };
};

/**
 * Creating pricing
 */
export const changeCreatePricing = (name, value) => ({
  type: pricingConstants.CREATING_PRICING_CHANGE_VALUE,
  name,
  value,
});
export const startCreatePricing = () => ({
  type: pricingConstants.CREATING_PRICING_STARTED,
});

export const createPricingSuccess = (result) => ({
  type: pricingConstants.CREATING_PRICING_SUCCESS,
  ...result,
});

export const createPricingFailure = (error) => ({
  type: pricingConstants.CREATING_PRICING_FAILURE,
  ...error,
});

export const createPricing = (pricing) => {
  return (dispatch) => {
    dispatch(startCreatePricing());

    const promise = pricingApi.create(pricing);

    promise
      .then((result) => {
        dispatch(createPricingSuccess(result));
      })
      .catch((error) => {
        dispatch(createPricingFailure(error));
      });

    return promise;
  };
};

/**
 * Updating pricing
 */
export const changeUpdatePricing = (name, value) => ({
  type: pricingConstants.UPDATING_PRICING_CHANGE_VALUE,
  name,
  value,
});
export const startUpdatePricing = () => ({
  type: pricingConstants.UPDATING_PRICING_STARTED,
});

export const updatePricingSuccess = (result) => ({
  type: pricingConstants.UPDATING_PRICING_SUCCESS,
  ...result,
});

export const updatePricingFailure = (error) => ({
  type: pricingConstants.UPDATING_PRICING_FAILURE,
  ...error,
});

export const updatePricing = (pricing) => {
  return (dispatch) => {
    dispatch(startUpdatePricing());

    const promise = pricingApi.update(pricing);

    promise
      .then((result) => {
        dispatch(updatePricingSuccess(result));
      })
      .catch((error) => {
        dispatch(updatePricingFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting pricing
 */
export const startDeletePricing = () => ({
  type: pricingConstants.DELETE_PRICING_STARTED,
});

export const deletePricingSuccess = (result) => ({
  type: pricingConstants.DELETE_PRICING_SUCCESS,
  ...result,
});

export const deletePricingFailure = (error) => ({
  type: pricingConstants.DELETE_PRICING_FAILURE,
  ...error,
});
export const deletePricing = (pricing) => {
  return (dispatch) => {
    dispatch(startDeletePricing());

    const promise = pricingApi.remove(pricing);

    promise
      .then((result) => {
        dispatch(deletePricingSuccess(result));
      })
      .catch((error) => {
        dispatch(deletePricingFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching pricings
 */
export const startGetPricings = () => ({
  type: pricingConstants.GET_PRICINGS_STARTED,
});

export const getPricingsSuccess = (result, from) => ({
  type: pricingConstants.GET_PRICINGS_SUCCESS,
  from: from,
  ...result,
});

export const getPricingsFailure = (error) => ({
  type: pricingConstants.GET_PRICINGS_FAILURE,
  ...error,
});

export const getPricings = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetPricings());

    const promise = pricingApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getPricingsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getPricingsFailure(error));
      });

    return promise;
  };
};

export const searchPricings = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetPricings());

    const promise = pricingApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getPricingsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getPricingsFailure(error));
      });

    return promise;
  };
};
