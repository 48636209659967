import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';
import SimpleReactValidator from 'simple-react-validator';

import FormInput from '@uiinputs/FormInput';

const convertUserToValue = (user) => ({
  value: user.id,
  label: `${user.firstName} ${user.lastName} - ${user.username}`,
  user: user,
});
export default class TeamForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      users: [],
    };

    this.searchTimeout = null;
  }

  componentDidMount() {
    const { getUsers } = this.props;
    getUsers(0, 200).then((response) => {
      this.setState({
        users: [...response.users],
      });
    });
  }

  sortByLabel = (values) => values.sort((a, b) => a.label.localeCompare(b.label));

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, formTeam, setHasChanged } = this.props;
    onSubmit && onSubmit(formTeam);
    setHasChanged(false);
  }

  render() {
    const { onChange, t, searchUsers, formTeam, setHasChanged } = this.props;
    const { users } = this.state;

    return (
      <div className="step-form" key={`${formTeam.id}-${formTeam.versionNumber}-${formTeam.nonce}`}>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <FormInput
              type="text"
              setHasChanged={setHasChanged}
              label="form.label.name"
              required={true}
              value={formTeam.name}
              onChange={(event) => {
                const newTeam = { ...formTeam };
                newTeam.name = event.target.value;

                onChange('formTeam', newTeam, event);
              }}
            />
            <FormInput
              type="text"
              setHasChanged={setHasChanged}
              label="form.label.transportOrderPrefix"
              required={true}
              value={formTeam.transportOrderPrefix}
              onChange={(event) => {
                const newTeam = { ...formTeam };
                newTeam.transportOrderPrefix = event.target.value;

                onChange('formTeam', newTeam, event);
              }}
            />
            <FormInput
              type="text"
              setHasChanged={setHasChanged}
              label="form.label.consignmentPrefix"
              required={true}
              value={formTeam.consignmentPrefix}
              onChange={(event) => {
                const newTeam = { ...formTeam };
                newTeam.consignmentPrefix = event.target.value;

                onChange('formTeam', newTeam, event);
              }}
            />
            <div className="input-group">
              <div className="input-group no-margin-top">
                <AsyncSelect
                  isMulti={true}
                  closeMenuOnSelect={false}
                  noOptionsMessage={() => t('users.notFound')}
                  placeholder={t('form.label.addUsers')}
                  loadingMessage={() => t('loading')}
                  defaultOptions={this.sortByLabel(users.map((user) => convertUserToValue(user)))}
                  loadOptions={(inputValue, callback) => {
                    if (inputValue && inputValue != '') {
                      if (this.searchTimeout) clearTimeout(this.searchTimeout);
                      this.searchTimeout = setTimeout(() => {
                        searchUsers({ query: inputValue }, 0, 100).then((response) => {
                          callback(
                            this.sortByLabel(response.users.map((user) => convertUserToValue(user)))
                          );
                        });
                      }, 400);
                    } else {
                      callback(users.map((user) => convertUserToValue(user)));
                    }
                  }}
                  value={formTeam.users.map((user) => convertUserToValue(user))}
                  onChange={(e) => {
                    const newTeam = { ...formTeam };
                    if (e && e.length > 0) {
                      const newUsers = e.map((el) => el.user);
                      newTeam.users = newUsers;
                    } else {
                      newTeam.users = [];
                    }
                    onChange('formTeam', newTeam);
                  }}
                />
              </div>
            </div>

            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
