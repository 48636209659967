import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import ADRView from '../general/ADRView';

class ItemCard extends Component {
  render() {
    const { t, goods } = this.props;
    return (
      <div className="goods-card">
        <h2 className="no-margin-bottom">{goods.name}</h2>
        {goods.quantity ? (
          <div className="quantity">
            <b>{t('goods.quantity')}</b>: {goods.quantity}
          </div>
        ) : null}
        {goods.weight && (
          <div className="weight">
            <b>{t('goods.weight')}</b>: {`${goods?.weight?.value} ${goods?.weight?.unit || ''}`}
          </div>
        )}
        {goods.grossWeight && (
          <div className="grossWeight">
            <b>{t('goods.grossWeight')}</b>:{' '}
            {`${goods.grossWeight.value} ${goods?.grossWeight?.unit || ''}`}
          </div>
        )}
        {goods.width && goods.length && goods.height && (
          <div>
            <b>{t('goods.dimensions')}</b>:{' '}
            {`${goods?.length?.value || 0}${goods?.length?.unit || 'cm'} x ${
              goods?.width?.value || 0
            } x ${goods?.height?.value || 0} ${goods?.width?.unit || ''}`}
          </div>
        )}
        {goods.productsType && (
          <div className="productsType">
            <b>{t('goods.productsType')}</b>: {goods.productsType}
          </div>
        )}
        {goods.packagingMaterial && (
          <div className="packagingMaterial">
            <b>{t('goods.packagingMaterial')}</b>: {goods.packagingMaterial}
          </div>
        )}
        {goods.adr ? (
          <>
            <div>
              <b>{t('adr.header')}</b>
            </div>
            <ADRView adr={goods.adr} />
          </>
        ) : null}
      </div>
    );
  }
}
export default withTranslation('translation')(ItemCard);
