import api from '../api';

export const get = (id = '') => {
  return api.get(`/pricing/${id}`);
};

export const create = (pricing) => {
  return api.post(`/pricing`, pricing);
};

export const update = (pricing) => {
  return api.put(`/pricing`, pricing);
};

export const remove = (pricing) => {
  return api.delete(`/pricing/${pricing.id}`);
};

export const latest = (from = 0, amount = 20) => {
  return api.get(`/pricings/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/pricings/${from}/${amount}`, searchParameters);
};
