import { chatConstants, socketConstants, tripConstants, vehicleConstants } from '@constants';

import moment from 'moment';

import { distinct } from '@utils/arrayUtils';
import { updateTripActionInList, updateTripInList } from '@utils/tripUtils';

const initialState = {
  isFetchingVehicle: false,
  formVehicle: null,
  vehicle: null,

  isFetchingActions: false,
  isFetchingActionsFrom: 0,
  actionsTotalResults: 0,
  actionsLoaded: 0,
  actions: [],

  isFetchingEvents: false,
  isFetchingEventsFrom: 0,
  eventsTotalResults: 0,
  eventsLoaded: 0,
  events: [],

  isFetchingLocationUpdateEvents: false,
  locationUpdateEventsTotalResults: 0,
  locationUpdateEvents: [],

  isFetchingChauffeurs: false,
  isFetchingChauffeursFrom: 0,
  chauffeurs: [],

  isFetchingTransportEquipments: false,
  isFetchingTransportEquipmentsFrom: 0,
  transportEquipments: [],

  isAttachingChauffeur: false,
  isDetachingChauffeur: false,

  isAttachingTransportEquipment: false,
  isDetachingTransportEquipment: false,

  isFetchingTrips: false,
  isFetchingTripsFrom: 0,
  tripsTotalResults: 0,
  tripsLoaded: 0,
  trips: [],

  isFetchingVehicles: false,
  isFetchingVehiclesFrom: 0,
  vehiclesTotalResults: 0,
  vehiclesLoaded: 0,
  vehicles: [],

  isFetchingExtendedVehicles: false,
  isFetchingExtendedVehiclesFrom: 0,
  extendedVehiclesTotalResults: 0,
  extendedVehiclesLoaded: 0,
  extendedVehicles: [],
  lastUpdatedExtendedVehicles: moment(),

  isFetchingVehiclesLocation: false,
  vehiclesLocation: [],
};

export default function vehicle(state = initialState, action) {
  switch (action.type) {
    case vehicleConstants.VEHICLE_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case vehicleConstants.GET_VEHICLE_STARTED:
      return { ...state, isFetchingVehicle: true };
    case vehicleConstants.GET_VEHICLE_FAILURE:
      return {
        ...state,
        isFetchingVehicle: false,
        vehicle: null,
        vehicles: [],
        error: action.message || '',
      };
    case vehicleConstants.GET_VEHICLE_SUCCESS:
      return {
        ...state,
        isFetchingVehicle: false,
        vehicle: action.vehicle,
        vehicles: updateVehicleInList(state, action.vehicle),
      };
    case vehicleConstants.GET_EXTENDED_VEHICLE_STARTED:
      return { ...state, isFetchingExtendedVehicle: true };
    case vehicleConstants.GET_EXTENDED_VEHICLE_FAILURE:
      return {
        ...state,
        isFetchingExtendedVehicle: false,
        error: action.message || '',
      };
    case vehicleConstants.GET_EXTENDED_VEHICLE_SUCCESS:
      return {
        ...state,
        isFetchingExtendedVehicle: false,
        vehicle: action.extendedVehicle,
        extendedVehicle: action.extendedVehicle,
        extendedVehicles: updateExtendedVehicleInList(state, action.extendedVehicle),
        lastUpdatedExtendedVehicles: moment(),
      };

    case vehicleConstants.GET_EXTENDED_VEHICLES_STARTED:
    case vehicleConstants.SEARCH_EXTENDED_VEHICLES_STARTED:
      return {
        ...state,
        isFetchingExtendedVehicles: true,
        isFetchingExtendedVehiclesFrom: action.from || 0,
      };
    case vehicleConstants.GET_EXTENDED_VEHICLES_FAILURE:
    case vehicleConstants.SEARCH_EXTENDED_VEHICLES_FAILURE:
      return {
        ...state,
        isFetchingExtendedVehicles: false,
        error: action.message || '',
      };
    case vehicleConstants.GET_EXTENDED_VEHICLES_SUCCESS:
    case vehicleConstants.SEARCH_EXTENDED_VEHICLES_SUCCESS:
      return {
        ...state,
        isFetchingExtendedVehicles: false,
        extendedVehicles:
          action.from > 0
            ? [...state.extendedVehicles, ...action.extendedVehicles]
            : action.extendedVehicles,
        extendedVehiclesLoaded:
          action.from > 0
            ? state.extendedVehicles.length + action.extendedVehicles.length
            : action.extendedVehicles.length,
        extendedVehiclesTotalResults: action.totalResults || 0,
        lastUpdatedExtendedVehicles: moment(),
      };

    case vehicleConstants.GET_VEHICLES_STARTED:
    case vehicleConstants.SEARCH_VEHICLES_STARTED:
      return {
        ...state,
        isFetchingVehicles: true,
        isFetchingVehiclesFrom: action.from || 0,
      };
    case vehicleConstants.GET_VEHICLES_FAILURE:
    case vehicleConstants.SEARCH_VEHICLES_FAILURE:
      return {
        ...state,
        isFetchingVehicles: false,
        error: action.message || '',
      };
    case vehicleConstants.GET_VEHICLES_SUCCESS:
    case vehicleConstants.SEARCH_VEHICLES_SUCCESS:
      return {
        ...state,
        isFetchingVehicles: false,
        vehicles: action.from > 0 ? [...state.vehicles, ...action.vehicles] : action.vehicles,
        vehiclesLoaded:
          action.from > 0 ? state.vehicles.length + action.vehicles.length : action.vehicles.length,
        vehiclesTotalResults: action.totalResults || 0,
      };
    case vehicleConstants.GET_VEHICLES_LOCATION_STARTED:
      return { ...state, isFetchingVehiclesLocation: true };
    case vehicleConstants.GET_VEHICLES_LOCATION_FAILURE:
      return {
        ...state,
        isFetchingVehiclesLocation: false,
        error: action.message || '',
      };
    case vehicleConstants.GET_VEHICLES_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingVehiclesLocation: false,
        vehiclesLocation: action.vehicles,
      };

    case vehicleConstants.GET_VEHICLE_ACTIONS_STARTED:
      return { ...state, isFetchingActions: true };
    case vehicleConstants.GET_VEHICLE_ACTIONS_FAILURE:
      return {
        ...state,
        isFetchingActions: false,
        error: action.message || '',
      };
    case vehicleConstants.GET_VEHICLE_ACTIONS_SUCCESS:
      return {
        ...state,
        isFetchingActions: false,
        actions: action.actions,
      };

    case vehicleConstants.GET_VEHICLE_EVENTS_STARTED:
      return {
        ...state,
        isFetchingEvents: true,
        isFetchingEventsFrom: action.from || 0,
      };
    case vehicleConstants.GET_VEHICLE_EVENTS_FAILURE:
      return { ...state, isFetchingEvents: false, error: action.message || '' };
    case vehicleConstants.GET_VEHICLE_EVENTS_SUCCESS:
      return {
        ...state,
        isFetchingEvents: false,
        events: action.from > 0 ? [...state.events, ...action.events] : action.events,
        eventsLoaded:
          action.from > 0 ? state.events.length + action.events.length : action.events.length,
        eventsTotalResults: action.totalResults || 0,
      };

    case vehicleConstants.GET_VEHICLE_LOCATION_UPDATE_EVENTS_STARTED:
      return { ...state, isFetchingLocationUpdateEvents: true };
    case vehicleConstants.GET_VEHICLE_LOCATION_UPDATE_EVENTS_FAILURE:
      return {
        ...state,
        isFetchingLocationUpdateEvents: false,
        error: action.message || '',
      };
    case vehicleConstants.GET_VEHICLE_LOCATION_UPDATE_EVENTS_SUCCESS:
      return {
        ...state,
        isFetchingLocationUpdateEvents: false,
        locationUpdateEvents: action.events,
        locationUpdateEventsTotalResults: action.totalResults || 0,
      };

    case vehicleConstants.GET_VEHICLE_CHAUFFEURS_STARTED:
      return {
        ...state,
        isFetchingChauffeurs: true,
        isFetchingChauffeursFrom: action.from || 0,
      };
    case vehicleConstants.GET_VEHICLE_CHAUFFEURS_FAILURE:
      return {
        ...state,
        isFetchingChauffeurs: false,
        error: action.message || '',
      };
    case vehicleConstants.GET_VEHICLE_CHAUFFEURS_SUCCESS:
      return {
        ...state,
        isFetchingChauffeurs: false,
        chauffeurs: action.chauffeurs,
      };

    case vehicleConstants.GET_VEHICLE_TRANSPORT_EQUIPMENTS_STARTED:
      return {
        ...state,
        isFetchingTransportEquipments: true,
        isFetchingTransportEquipmentsFrom: action.from,
      };
    case vehicleConstants.GET_VEHICLE_TRANSPORT_EQUIPMENTS_FAILURE:
      return {
        ...state,
        isFetchingTransportEquipments: false,
        error: action.message || '',
      };
    case vehicleConstants.GET_VEHICLE_TRANSPORT_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        isFetchingTransportEquipments: false,
        transportEquipments: action.transportEquipments,
      };

    case vehicleConstants.GET_VEHICLE_TRIPS_STARTED:
      return {
        ...state,
        isFetchingTrips: true,
        isFetchingTripsFrom: action.from || 0,
      };
    case vehicleConstants.GET_VEHICLE_TRIPS_FAILURE:
      return { ...state, isFetchingTrips: false, error: action.message || '' };
    case vehicleConstants.GET_VEHICLE_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        isFetchingTripsFrom: action.from,
        tripsLoaded:
          action.from > 0 ? state.trips.length + action.trips.length : action.trips.length,
        tripsTotalResults: action.totalResults,
        trips: action.from > 0 ? [...state.trips, ...action.trips] : action.trips,
      };

    case vehicleConstants.GET_EXTENDED_VEHICLES_TRIPS_STARTED:
    case vehicleConstants.GET_VEHICLES_NEXT_TRIPS_STARTED:
      return {
        ...state,
        isFetchingTrips: true,
      };
    case vehicleConstants.GET_EXTENDED_VEHICLES_TRIPS_FAILURE:
    case vehicleConstants.GET_VEHICLES_NEXT_TRIPS_FAILURE:
      return { ...state, isFetchingTrips: false, error: action.message || '' };
    case vehicleConstants.GET_EXTENDED_VEHICLES_TRIPS_SUCCESS:
    case vehicleConstants.GET_VEHICLES_NEXT_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        extendedVehicles: [
          ...state.extendedVehicles.map((extendedVehicle) => ({
            ...extendedVehicle,
            trips: action.vehiclesTrips.find(
              (vehiclesTrip) => vehiclesTrip.vehicle === extendedVehicle.id
            )?.trips,
          })),
        ],
      };

    case tripConstants.CANCEL_TRIP_STARTED:
    case tripConstants.CONFIRM_TRIP_STARTED:
    case tripConstants.START_TRIP_STARTED:
    case tripConstants.FINISH_TRIP_STARTED:
      return {
        ...state,
        trips: updateTripInList(state, { ...action.trip, isLoading: true }),
        extendedvehicles: updateVehiclesTrips(state, action, {
          ...action.trip,
          isLoading: true,
        }),
      };
    case tripConstants.CONFIRM_TRIP_SUCCESS:
    case tripConstants.START_TRIP_SUCCESS:
    case tripConstants.FINISH_TRIP_FAILURE:
    case tripConstants.CANCEL_TRIP_FAILURE:
    case tripConstants.CONFIRM_TRIP_FAILURE:
    case tripConstants.START_TRIP_FAILURE:
      return {
        ...state,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        extendedvehicles: updateVehiclesTrips(state, action, {
          isLoading: false,
          ...action.trip,
        }),
      };

    case tripConstants.UPDATE_TRIP_ACTION_STARTED:
      return {
        ...state,
        trips: updateTripActionInList(
          state,
          { ...action.trip },
          { ...action.action, isLoading: true }
        ),
      };
    case tripConstants.UPDATE_TRIP_ACTION_SUCCESS:
    case tripConstants.UPDATE_TRIP_ACTION_FAILURE:
      return {
        ...state,
        trips: updateTripActionInList(
          state,
          { ...action.trip },
          { ...action.action, isLoading: false }
        ),
        extendedVehicles: updateVehiclesTrips(state, action, { ...action.trip }),
      };

    case tripConstants.UPDATING_TRIP_SUCCESS:
      return {
        ...state,
        trips: updateTripInList(state, { ...action.trip }),
        extendedVehicles: updateVehiclesTrips(state, action, { ...action.trip }),
      };
    case tripConstants.CANCEL_TRIP_SUCCESS:
    case tripConstants.FINISH_TRIP_SUCCESS:
      return {
        ...state,
        trips: [...state.trips].filter((trip) => action.trip.id !== trip.id),

        extendedVehicles: [...state.extendedVehicles].map((vt) => ({
          ...vt,
          trips: [...(vt?.trips || [])]?.filter((trip) => action.trip.id !== trip.id),
        })),
      };

    case tripConstants.CREATING_TRIP_SUCCESS:
      return {
        ...state,
        extendedVehicles: addVehiclesTrip(state, action.trip),
      };
    case vehicleConstants.GET_VEHICLES_TRIPS_STARTED:
      return {
        ...state,
        isFetchingVehiclesTrips: true,
        isFetchingVehiclesTripsFrom: action.from,
      };
    case vehicleConstants.GET_VEHICLES_TRIPS_FAILURE:
      return {
        ...state,
        isFetchingVehiclesTrips: false,
        error: action.message || '',
      };
    case vehicleConstants.GET_VEHICLES_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingVehiclesTrips: false,
        vehiclesTrips: action.vehiclesTrips,
      };

    case vehicleConstants.UPDATING_VEHICLE_TRIPS_ORDER_STARTED:
      return {
        ...state,
        trips: updateTrips(state, [
          { ...action.trip1, isLoading: true },
          { ...action.trip2, isLoading: true },
        ]),
      };
    case vehicleConstants.UPDATING_VEHICLE_TRIPS_ORDER_SUCCESS:
      return {
        ...state,
        trips: updateTrips(state, action.trips),
      };

    case vehicleConstants.CREATING_VEHICLE_STARTED:
      return { ...state, isFetchingVehicle: true };
    case vehicleConstants.CREATING_VEHICLE_FAILURE:
      return {
        ...state,
        isFetchingVehicle: false,
        error: action.message || '',
      };
    case vehicleConstants.CREATING_VEHICLE_SUCCESS:
      return {
        ...state,
        isFetchingVehicle: false,
        vehicle: action.vehicle,
        vehicles: [action.vehicle, ...state.vehicles],
      };

    case vehicleConstants.UPDATING_VEHICLE_STARTED:
      return { ...state, isFetchingVehicle: true };
    case vehicleConstants.UPDATING_VEHICLE_FAILURE:
      return {
        ...state,
        isFetchingVehicle: false,
        error: action.message || '',
      };
    case vehicleConstants.UPDATING_VEHICLE_SUCCESS:
      return {
        ...state,
        isFetchingVehicle: false,
        vehicle: action.vehicle,
        vehicles: [...state.vehicles].map((vehicle) => {
          if (action.vehicle.id === vehicle.id) {
            return action.vehicle;
          }
          return vehicle;
        }),
      };

    case vehicleConstants.DELETE_VEHICLE_STARTED:
      return { ...state, isFetchingVehicle: true };
    case vehicleConstants.DELETE_VEHICLE_FAILURE:
      return {
        ...state,
        isFetchingVehicle: false,
        message: action.message || '',
      };
    case vehicleConstants.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        isFetchingVehicle: false,
        vehicle: action.vehicle,
        vehicles: [...state.vehicles].filter((vehicle) => action.vehicle.id !== vehicle.id),
      };

    case vehicleConstants.VEHICLE_ATTACH_CHAUFFEUR_STARTED:
      return { ...state, isAttachingChauffeur: true };
    case vehicleConstants.VEHICLE_ATTACH_CHAUFFEUR_FAILURE:
    case vehicleConstants.VEHICLE_ATTACH_CHAUFFEUR_SUCCESS:
      return {
        ...state,
        isAttachingChauffeur: false,
        vehicles: updateVehicleInList(state, action.extendedVehicle),
        extendedVehicles: updateExtendedVehicleInList(state, action.extendedVehicle),
        chauffeurs: action.extendedVehicle.chauffeurs,
      };

    case vehicleConstants.VEHICLE_DETACH_CHAUFFEUR_STARTED:
      return { ...state, isDetachingChauffeur: true };
    case vehicleConstants.VEHICLE_DETACH_CHAUFFEUR_FAILURE:
    case vehicleConstants.VEHICLE_DETACH_CHAUFFEUR_SUCCESS:
      return {
        ...state,
        isDetachingChauffeur: false,
        vehicles: updateVehicleInList(state, action.extendedVehicle),
        extendedVehicles: updateExtendedVehicleInList(state, action.extendedVehicle),
        chauffeurs: action.extendedVehicle.chauffeurs,
      };

    case vehicleConstants.VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_STARTED:
      return { ...state, isAttachingTransportEquipment: true };
    case vehicleConstants.VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_FAILURE:
    case vehicleConstants.VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isAttachingTransportEquipment: false,
        vehicles: updateVehicleInList(state, action.extendedVehicle),
        extendedVehicles: updateExtendedVehicleInList(state, action.extendedVehicle),
        transportEquipments: action.extendedVehicle.transportEquipments,
      };

    case vehicleConstants.VEHICLE_DETACH_TRANSPORT_EQUIPMENT_STARTED:
      return { ...state, isDetachingTransportEquipment: true };
    case vehicleConstants.VEHICLE_DETACH_TRANSPORT_EQUIPMENT_FAILURE:
    case vehicleConstants.VEHICLE_DETACH_TRANSPORT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isDetachingTransportEquipment: false,
        vehicles: updateVehicleInList(state, action.extendedVehicle),
        extendedVehicles: updateExtendedVehicleInList(state, action.extendedVehicle),
        transportEquipments: action.extendedVehicle.transportEquipments,
      };

    case chatConstants.READ_MESSAGES_SUCCESS:
      return {
        ...state,
        extendedVehicles: state.extendedVehicles.map((extendedVehicle) => {
          const newExtendedVehicle = { ...extendedVehicle };
          newExtendedVehicle.chat =
            action.messages.length > 0 && action.messages[0].chat === newExtendedVehicle.chat.id
              ? { ...newExtendedVehicle.chat, unreadMessages: 0 }
              : newExtendedVehicle.chat;
          return newExtendedVehicle;
        }),
        lastUpdatedExtendedVehicles: moment(),
      };

    case socketConstants.NEW_CHAT_MESSAGE:
      return {
        ...state,
        extendedVehicles: state.extendedVehicles.map((extendedVehicle) => {
          const newExtendedVehicle = { ...extendedVehicle };

          if (
            newExtendedVehicle.chat.id === action.value.chat.id ||
            newExtendedVehicle.chat.id === action.value.chat
          ) {
            newExtendedVehicle.chat = {
              ...extendedVehicle.chat,
              unreadMessages: action.value.chat.unreadMessages,
              latestMessage: action.value,
            };
          }
          newExtendedVehicle.chat =
            action.value.chat.id === newExtendedVehicle.chat.id
              ? { ...newExtendedVehicle.chat, unreadMessages: 0 }
              : newExtendedVehicle.chat;
          return newExtendedVehicle;
        }),
      };

    case socketConstants.NEW_EXTENDED_VEHICLES:
      return {
        ...state,
        extendedVehicles: action.isWithinViewFilters
          ? state.extendedVehicles
              .map((extendedVehicle) => extendedVehicle.id)
              .includes(action.value?.id)
            ? updateExtendedVehicleInList(state, { ...action.value })
            : [...state.extendedVehicles, { ...action.value }]
          : [...state.extendedVehicles].filter((trip) => action.value?.id !== trip.id),
        vehicles: action.isWithinViewFilters
          ? state.vehicles.map((trip) => trip.id).includes(action.value?.id)
            ? updateVehicleInList(state, { ...action.value })
            : [...state.vehicles, { ...action.value }]
          : [...state.vehicles].filter((trip) => action.value?.id !== trip.id),
        lastUpdatedExtendedVehicles: moment(),
      };

    case socketConstants.NEW_TRIP:
      return {
        ...state,
        trips: action.isWithinViewFilters
          ? state.trips.map((trip) => trip.id).includes(action.value?.id)
            ? updateTripInList(state, action.value)
            : [...state.trips, action.value]
          : [...state.trips].filter((trip) => action.value?.id !== trip.id),
        extendedVehicles: updateVehiclesTrips(state, action, action.value),
      };
    default:
      return state;
  }
}

const updateVehicleInList = (state, newVehicle) => {
  return [...state.vehicles].map((vehicle) => {
    if (vehicle.id === newVehicle.id) {
      return { ...newVehicle, trips: vehicle.trips };
    } else return vehicle;
  });
};

const updateExtendedVehicleInList = (state, newExtendedVehicle) => {
  return [...state.extendedVehicles].map((extendedVehicle) => {
    if (extendedVehicle.id === newExtendedVehicle.id) {
      return { ...newExtendedVehicle, trips: extendedVehicle.trips };
    } else return extendedVehicle;
  });
};

const updateTrips = (state, newTrips) => {
  const newTripsId = newTrips.map((t) => t.id);
  return [...state.trips]
    .map((trip) => {
      if (newTripsId.includes(trip.id)) {
        return { ...newTrips.find((t) => t.id === trip.id) };
      } else return trip;
    })
    .sort((a, b) => {
      if (a.startDate && b.startDate) return new Date(a.startDate) - new Date(b.startDate);
      if (a.startDate) {
        return 1;
      }
      if (b.startDate) {
        return -1;
      } else return -1;
    });
};

const addVehiclesTrip = (state, newTrip) => {
  return [...(state?.extendedVehicles || [])].map((vt) => ({
    ...vt,
    trips: (vt?.trips || [])?.map((trip) => trip.id).includes(newTrip.id)
      ? (vt?.trips || [])?.map((trip) => (trip.id === newTrip.id ? { ...newTrip } : trip))
      : vt.id === newTrip.vehicle?.entity?.id
      ? [...(vt?.trips || []), newTrip]
      : [...(vt.trips || [])],
  }));
};

const updateVehiclesTrips = (state, action, newTrip) => {
  const hasMovedVehicle = [...(state?.extendedVehicles || [])].some((vt) =>
    (vt?.trips || [])?.some(
      (trip) =>
        trip.id === newTrip.id && vt.id !== (newTrip.vehicle?.id || newTrip.vehicle?.entity?.id)
    )
  );

  // remove and add
  if (hasMovedVehicle) {
    return [...state.extendedVehicles].map((vt) => ({
      ...vt,
      trips:
        vt.id === (newTrip.vehicle?.id || newTrip.vehicle?.entity?.id)
          ? [...(vt?.trips || []), { ...newTrip }]
          : [...(vt?.trips || [])].filter((trip) => trip.id !== newTrip.id),
    }));
  }

  // just replace
  return [...(state?.extendedVehicles || [])].map((vt) => {
    return {
      ...vt,
      trip: vt.trip?.id === newTrip?.id ? { ...newTrip } : vt.trip,
      trips: action.isWithinViewFilters
        ? (vt?.trips || [])?.map((trip) => trip.id).includes(newTrip.id)
          ? (vt?.trips || [])?.map((trip) => (trip.id === newTrip.id ? { ...newTrip } : trip))
          : vt.id === (newTrip.vehicle?.id || newTrip.vehicle?.entity?.id)
          ? [...(vt?.trips || []), newTrip]
          : [...(vt.trips || [])]
        : [...(vt.trips || [])]
            ?.map((trip) => {
              if (newTrip.id !== trip.id) {
                return trip;
              } else return newTrip;
            })
            ?.sort((a, b) => {
              if (a.startDate && b.startDate) return new Date(a.startDate) - new Date(b.startDate);
              if (a.startDate) {
                return 1;
              }
              if (b.startDate) {
                return -1;
              } else return -1;
            }),
    };
  });
};
