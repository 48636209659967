import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TripChauffeursView from '@components/trip/TripChauffeursView';

import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';

class TripChauffeursContainer extends PureComponent {
  render() {
    const { ...props } = this.props;
    return <TripChauffeursView {...props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.trip,
    ...ownProps,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTrip: (trip, withLoading = true) => dispatch(tripActions.updateTrip(trip, withLoading)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TripChauffeursContainer));
