import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateTripContainer from '@containers/trip/crud/CreateTripContainer';

import { search } from '@api/tripApi';

import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';

let searchTimeout = null;

export const transformTripToValue = (trip) => ({
  label: trip.name,
  value: trip.id,
  trip: trip,
});

class TripInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trip: this.props.trip ? transformTripToValue(this.props.trip) : null,
      trips: [],
    };
  }

  componentDidMount() {
    const { trip, getTrip, tripId } = this.props;

    if (!trip && tripId) {
      getTrip(tripId).then((response) => {
        if (response.success) {
          this.setState({ trip: transformTripToValue(response.trip) });
        }
      });
    }
    search({}, 0, 40).then((response) => {
      this.setState({
        trips: response.trips,
      });
    });
  }

  onChange = (newTrip) => {
    this.setState({
      trip: newTrip,
    });
    const { onChange } = this.props;
    onChange && onChange(newTrip ? newTrip.trip : null);
  };

  render() {
    const { t, isFetchingTrips, addToStack, placeholder } = this.props;
    const { trip, trips } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          defaultOptions={trips.map((trip) => transformTripToValue(trip))}
          placeholder={placeholder ? placeholder : `${t('form.label.search')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingTrips}
          // isDisabled={isFetchingTrips}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              search({ query: inputValue }, 0, 40).then((response) => {
                callback(response.trips.map((trip) => transformTripToValue(trip)));
              });
            }, 400);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('trip.new'),
              component: (
                <CreateTripContainer
                  targetName={inputValue}
                  callback={(newTrip) => {
                    this.onChange(transformTripToValue(newTrip));
                  }}
                />
              ),
            });
          }}
          value={trip}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrip: (id) => dispatch(tripActions.getTrip(id)),
    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TripInput));
