import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreatePricingContainer from '@containers/pricing/crud/CreatePricingContainer';

import { search as searchPricings } from '@api/pricingApi';

import * as navigationActions from '@actions/navigationActions';
import * as pricingActions from '@actions/pricingActions';

let searchTimeout = null;

export const transformPricingToValue = (pricing) => ({
  value: pricing.id,
  label: pricing.name,
  id: pricing.id,
  pricing: pricing,
});

class PricingInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? transformPricingToValue(this.props.value) : null,
      searchParameters: this.props.searchParameters ? this.props.searchParameters : {},
      pricings: [],
    };
  }

  static defaultProps = {
    isDisabled: false,
  };

  componentDidMount() {
    const { searchParameters } = this.state;

    searchPricings({ ...searchParameters }, 0, 40).then((response) => {
      this.setState({
        pricings: response.pricings.map((pricing) => transformPricingToValue(pricing)),
      });
    });
  }

  onChange = (newPricing) => {
    this.setState({
      value: newPricing,
    });
    const { onChange } = this.props;
    onChange?.(newPricing ? newPricing.pricing : null);
  };

  render() {
    const { t, placeholder, isFetchingPricings, addToStack, isDisabled } = this.props;
    const { value, searchParameters, pricings } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={placeholder ? placeholder : `${t('form.label.selectPricing')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingPricings}
          isDisabled={isDisabled || isFetchingPricings}
          defaultOptions={pricings}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchPricings({ query: inputValue, ...searchParameters }, 0, 40).then((response) => {
                callback(response.pricings.map((pricing) => transformPricingToValue(pricing)));
              });
            }, 400);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('pricing.new'),
              component: (
                <CreatePricingContainer
                  targetName={inputValue}
                  callback={(newPricing) => {
                    this.onChange(transformPricingToValue(newPricing));
                  }}
                />
              ),
            });
          }}
          value={value}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(pricingActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(PricingInput));
