import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/alert/${id}`);
};

export const ignore = (alert) => {
  return api.put(`/alert/${alert.id}/ignore`);
};

export const solve = (alert) => {
  return api.put(`/alert/${alert.id}/solve`);
};

// Fetch and Search
export const all = () => {
  return api.get(`/alerts`);
};

export const allByEntity = (entityType, entityId) => {
  return api.get(`/alerts/${entityType}/${entityId}`);
};

export const allByEntities = (entities) => {
  return api.post(`/alerts`, { entities: entities });
};
