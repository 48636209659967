import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateLocationContainer from '@containers/location/crud/CreateLocationContainer';

import { search as searchLocations } from '@api/locationApi';

import * as locationActions from '@actions/locationActions';
import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

export const transformLocationToValue = (location) => ({
  value: location.id,
  label: `${location.name} - ${
    location?.administrativeReference
      ? `${location.administrativeReference.street || ''} ${
          location.administrativeReference.houseNumber || ''
        }${location.administrativeReference.houseNumberAddition || ''}, ${
          location.administrativeReference.city || ''
        }, ${location.administrativeReference.country || ''}`
      : ''
  }`,
  location: location,
});

class LocationsInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: this.props.locations
        ? this.props.locations.map((location) => transformLocationToValue(location))
        : [],
    };
  }

  onChange = (newLocations) => {
    this.setState({
      location: newLocations,
    });

    const { onChange } = this.props;
    onChange?.(newLocations ? newLocations.map((newLocation) => newLocation.location) || [] : []);
  };

  render() {
    const { t, isFetchingLocations, addToStack } = this.props;
    const { locations } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={true}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.location')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingLocations}
          isDisabled={isFetchingLocations}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchLocations({ query: inputValue }, 0, 40).then((response) => {
                callback(response.locations.map((location) => transformLocationToValue(location)));
              });
            }, 400);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('location.new'),
              component: (
                <CreateLocationContainer
                  targetName={inputValue}
                  callback={(newLocation) => {
                    this.onChange([...locations, transformLocationToValue(newLocation)]);
                  }}
                />
              ),
            });
          }}
          value={locations}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(locationActions.changeValue(name, value)),
    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(LocationsInput));
