import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateCapacityContractContainer from '@containers/capacityContract/crud/CreateCapacityContractContainer';

import { search as searchCapacityContracts } from '@api/capacityContractApi';

import * as capacityContractActions from '@actions/capacityContractActions';
import * as navigationActions from '@actions/navigationActions';

const transformCapacityContractToValue = (capacityContract) => ({
  id: capacityContract.id,
  value: capacityContract.id,
  label: `${capacityContract.name} - ${capacityContract.business.name}`,
  capacityContract: { ...capacityContract },
});

class CapacityContractInput extends Component {
  state = {
    value: this.props.value ? transformCapacityContractToValue(this.props.value) : null,
    capacityContracts: []
  };

  componentDidMount() {
    const { value, getCapacityContract, capacityContractId } = this.props;

    if (!value && capacityContractId) {
      getCapacityContract(capacityContractId).then((response) => {
        if (response.success) {
          this.setState({
            value: transformCapacityContractToValue(response.capacityContract),
          });
        }
      });
    } else {
      searchCapacityContracts({ query: '' }, 0, 40).then((response) => {
        this.setState({
          capacityContracts: response.capacityContracts.map((capacityContract) =>
            transformCapacityContractToValue(capacityContract)
          ),
        });
      });
    }
  }

  onChange = (newCapacityContract) => {
    this.setState({
      value: newCapacityContract,
    });
    const { onChange } = this.props;
    onChange?.(newCapacityContract ? newCapacityContract.capacityContract : null);
  };

  render() {
    const { t, addToStack } = this.props;
    const { value, capacityContracts } = this.state;
    return (
      <div className="input-group no-margin-top">
        <div className="input-group no-margin-top">
          <AsyncCreatableSelect
            isClearable
            noOptionsMessage={() => t('capacityContracts.notFound')}
            placeholder={t('form.label.selectCapacityContract')}
            loadingMessage={() => t('loading')}
            defaultOptions={capacityContracts}
            loadOptions={(inputValue, callback) => {
              if (this.searchTimeout) clearTimeout(this.searchTimeout);
              this.searchTimeout = setTimeout(() => {
                searchCapacityContracts({ query: inputValue }, 0, 40).then((response) => {
                  callback(
                    response.capacityContracts.map((capacityContract) =>
                      transformCapacityContractToValue(capacityContract)
                    )
                  );
                });
              }, 400);
            }}
            value={value}
            onChange={(e) => this.onChange(e)}
            onCreateOption={(inputValue) => {
              addToStack({
                name: t('capacityContract.new'),
                component: (
                  <CreateCapacityContractContainer
                    targetName={inputValue}
                    callback={(data) => {
                      const newCapacityContract = {
                        id: data.capacityContract.id,
                        value: data.capacityContract.id,
                        label: `${data.capacityContract.name}`,
                        capacityContract: { ...data.capacityContract },
                      };
                      this.setState({
                        capacityContract: newCapacityContract,
                      });

                      this.onChange(newCapacityContract);
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.capacityContract,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(capacityContractActions.changeCreateCapacityContract(name, value)),
    getCapacityContract: (id) => dispatch(capacityContractActions.getCapacityContract(id)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CapacityContractInput));
