import { renderRoutes } from '@routes';
import { lazy } from 'react';

const TeamsContainer = lazy(() => import('@containers/team/TeamsContainer'));

const routes = [
  {
    paths: ['', '/:id', '/:id/update', '/create'],
    component: TeamsContainer,
    type: 'portal',
  },
];

const TeamRoutes = (props) => renderRoutes(props, routes);
export default TeamRoutes;
