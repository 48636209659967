import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import moment from 'moment';

import Attributes from '@components/attribute/Attributes';
import GoodsCard from '@components/goods/GoodsCard';
import ConsignmentDetailContainer from '@containers/consignment/ConsignmentDetailContainer';

import { getToBePlannedActions } from '@utils/actionUtils';
import { administrativeReferenceNamePostalCodeCityString } from '@utils/locationUtils';

import ConsignmentRow from './ConsignmentRow';

class ConsignmentCard extends Component {
  render() {
    const { t, index, activeFields, settings, consignment, addToStack, startDrag } = this.props;

    if (settings?.userInterface?.sidebarType === 'list') {
      return (
        <div
          className={`planBoard__item ${startDrag ? ' cursor-move' : ''}`}
          draggable={true}
          id={consignment.id}
          key={consignment.id}
          onDragOver={(event) => (startDrag ? event.preventDefault() : null)}
          onDragEnd={(e) => (startDrag ? e.target.classList.remove('fade-in') : null)}
          onDragStart={(event) => startDrag?.(event, { consignment })}
          onClick={(e) => {
            addToStack?.({
              name: consignment.name,
              className: 'lightgray',
              component: <ConsignmentDetailContainer consignmentId={consignment.id} />,
            });
          }}
        >
          <ConsignmentRow
            consignment={consignment}
            activeFields={activeFields}
            index={index}
            settings={[
              {
                name: 'showFirstLabelOnly',
                value: true,
              },
              {
                name: 'showLabels',
                value: false,
              },
            ]}
          />
        </div>
      );
    }
    const sortedActions = settings?.planning?.splitConsignmentOnAutomaticTripCreation
      ? getToBePlannedActions([consignment])
          ?.sort((a, b) => a.entity.sequenceNr - b.entity.sequenceNr)
          .slice(0, 2)
      : getToBePlannedActions([consignment])?.sort(
          (a, b) => a.entity.sequenceNr - b.entity.sequenceNr
        );
    const location = (payload) =>
      sortedActions[payload]?.entity?.location?.entity?.administrativeReference || {
        city: '',
        country: '',
      };
    const dateTime = (index, type, key) =>
      sortedActions[index]?.entity?.constraints?.find(
        (constraint) => constraint.entity.type === type
      )?.entity?.value[key];

    const type = (index) =>
      sortedActions[index]?.entity?.actions?.map((action) => action?.entity?.type).join(',');
    return (
      <div
        className={`consignment__search-card${startDrag ? ' cursor-move' : ''}`}
        draggable={startDrag ? true : false}
        id={consignment.id}
        key={consignment.id}
        onDragOver={(event) => (startDrag ? event.preventDefault() : null)}
        onDragEnd={(e) => (startDrag ? e.target.classList.remove('fade-in') : null)}
        onDragStart={(event) => startDrag?.(event, { consignment })}
        onClick={(e) => {
          addToStack?.({
            name: consignment.name,
            className: 'lightgray',
            component: <ConsignmentDetailContainer consignmentId={consignment.id} />,
          });
        }}
      >
        <div className="content">
          <div className="name">
            <div>
              {consignment.name} - <span>{consignment.consignmentNr}</span>
            </div>
            <div className="status">
              {consignment.combined && (
                <div className="combine">
                  <ReactSVG src="/icons/combine.svg" />
                </div>
              )}
              {consignment.status && (
                <div className={`tag tag-${consignment.status} substatus`}>
                  {consignment.status}
                </div>
              )}
              {consignment.type && <div className="tag tag-default type">{consignment.type}</div>}
              <Attributes attributes={consignment.attributes} />
            </div>
          </div>

          <div className="action-group">
            <div className="action">
              <div>
                <div className="date">
                  <div className="type">{type(0)}</div>
                  {dateTime(0, 'startDateTimeConstraint', 'startDateTime')
                    ? moment(dateTime(0, 'startDateTimeConstraint', 'startDateTime')).format(
                        'DD/MM/YY HH:mm'
                      )
                    : ''}{' '}
                  -{' '}
                  {dateTime(0, 'endDateTimeConstraint', 'endDateTime')
                    ? moment(dateTime(0, 'endDateTimeConstraint', 'endDateTime')).format(
                        'DD/MM/YY HH:mm'
                      )
                    : ''}
                </div>
                <div className="location">
                  {administrativeReferenceNamePostalCodeCityString(
                    location(0),
                    true,
                    sortedActions[0]?.entity?.location?.entity
                  )}
                </div>
              </div>
            </div>
            {sortedActions.length > 1 && (
              <div className="action">
                <div>
                  <div className="date">
                    <div className="type">{type(sortedActions.length - 1)}</div>
                    {dateTime(sortedActions.length - 1, 'startDateTimeConstraint', 'startDateTime')
                      ? moment(
                          dateTime(
                            sortedActions.length - 1,
                            'startDateTimeConstraint',
                            'startDateTime'
                          )
                        ).format('DD/MM/YY HH:mm')
                      : ''}{' '}
                    -{' '}
                    {dateTime(sortedActions.length - 1, 'endDateTimeConstraint', 'endDateTime')
                      ? moment(
                          dateTime(sortedActions.length - 1, 'endDateTimeConstraint', 'endDateTime')
                        ).format('DD/MM/YY HH:mm')
                      : ''}
                  </div>

                  <div className="location">
                    {administrativeReferenceNamePostalCodeCityString(
                      location(sortedActions.length - 1),
                      true,
                      sortedActions[sortedActions.length - 1]?.entity?.location?.entity
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {consignment.goods
            .map((association) => association.entity)
            .filter((entity) => entity.type === 'items')
            .map((entity) => (
              <div className="goods">
                <GoodsCard key={entity.id} goods={entity} />
              </div>
            ))}
          {consignment.goods
            .map((association) => association.entity)
            .filter((entity) => entity.type === 'transportEquipment')
            .map((entity) => (
              <div className="goods">
                <GoodsCard key={entity.id} goods={entity} />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(ConsignmentCard);
