import api from '../api';

// CRUD
export const get = () => {
  return api.get(`/platform`);
};
export const getById = (id) => {
  return api.get(`/platform/${id}`);
};
export const create = (platform) => {
  return api.post(`/platform`, platform);
};
export const getBillingInvoices = (id, offset = 0, amount = 20) => {
  return api.get(`/platforms/billing/${id}/${offset}/${amount}`);
};
export const getBilling = (id) => {
  return api.get(`/platform/${id}/billing`);
};
export const createPaymentIntent = (intent) => {
  return api.put(`/platform/billing/setup`, intent);
};
export const getBillingUsageEvents = (id, offset = 0, amount = 20) => {
  return api.get(`/platforms/billing/${id}/usageEvents/${offset}/${amount}`);
};
export const getBillingUsageEventsByDate = (id, start, end) => {
  return api.get(`/platforms/billing/${id}/usageEvents:byDateRange/${start}/${end}`);
};
export const getBillingEstimate = (id) => {
  return api.get(`/platform/billing/${id}/estimate`);
};
export const update = (platform) => {
  return api.put(`/platform`, platform);
};
export const updateSettings = (
  platform
) => {
  return api.put(`/platform/settings`, {
    ...platform
  });
};

export const remove = (platform) => {
  return api.delete(`/platform/${platform.id}`);
};

// Fetching and finding
export const all = (from = 0, amount = 20) => {
  return api.get(`/platforms/${from}/${amount}`);
};

export const search = (searchParameters, from = 0, amount = 20) => {
  return api.post(`/platforms/${from}/${amount}`, searchParameters);
};

// Rest
export const languages = () => {
  return api.get(`/languages`);
};
export const industries = () => {
  return api.get(`/industries`);
};
export const categories = () => {
  return api.get(`/currencies`);
};
export const currencies = () => {
  return api.get(`/currencies`);
};
export const permissions = () => {
  return api.get(`/permissions`);
};
export const contact = (email, subject, message, recaptcha) => {
  return api.post(`/platform/contact`, {
    email,
    subject,
    message,
    recaptcha,
  });
};
