import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import TripContainer from '../../containers/trip/TripContainer';

class AlertBadge extends Component {
  _performAction = (alert) => {
    const { addToStack } = this.props;

    switch (alert.action) {
      case 'open_trip_view':
        addToStack({
          name: alert.primaryRelatedEntityName,
          component: <TripContainer tripId={alert.primaryRelatedEntityId} />,
        });
        break;
    }
  };

  render() {
    const { t, alert, ignore, solve } = this.props;

    return (
      <div className={`alert-badge ${alert.level}`} key={`alert-${alert.id}`}>
        <div className="message">
          <p>
            <b>{t('alert.' + alert.name)}</b> {t('alert.' + alert.message)}
          </p>
        </div>
        <div className="alert-badge__actions">
          <button
            onClick={(e) => {
              ignore?.(alert);
            }}
          >
            {t('form.ignore')}
          </button>
          <button
            onClick={(e) => {
              solve?.(alert);
            }}
          >
            {t('form.solved')}
          </button>
          {alert.action && (
            <button
              onClick={(e) => {
                this._performAction(alert);
              }}
            >
              {t('form.fix')}
            </button>
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation('translation')(AlertBadge);
