import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { transformLocationToValue } from '@components/location/crud/LocationInput';
import CreateBusinessContainer from '@containers/business/crud/CreateBusinessContainer';
import CreateContactPersonContainer from '@containers/business/crud/CreateContactPersonContainer';

const convertBusinessToValue = (t, business, customerFor) => ({
  id: business.id,
  value: business.id,
  label: `${business.name}${
    customerFor
      ? ` ${t('form.label.customerFor_lowercased', {
          business: customerFor,
        })}`
      : ''
  }${
    business.pricingConfigurationCount > 0
      ? ` (${t('business.hasPricingConfiguration', { count: business.pricingConfigurationCount })})`
      : ''
  }`,
  business: { ...business },
  locations: business.locations,
});

class CustomerInput extends Component {
  static defaultOptions = {
    simpleForms: false,
  };

  constructor(props) {
    super(props);

    const { customer } = props;
    this.searchTimeout = null;
    this.state = {
      businesses: [],
      customer:
        customer && customer.business
          ? {
              ...customer,
              business: {
                business: customer.business.label ? customer.business.business : customer.business,
                locations: customer.business?.business?.locations,
                label: customer.business
                  ? customer.business.label
                    ? customer.business.label
                    : customer.business.name
                  : null,
                value: customer.business.id,
                id: customer.business.id,
              },
            }
          : {
              business: null,
              customer: null,
            },
    };
  }

  onChange = (newCustomer) => {
    const { onChange } = this.props;
    onChange && onChange(newCustomer);
  };

  sortByLabel = (values) => values.sort((a, b) => a.label.localeCompare(b.label));

  componentDidMount() {
    const { searchBusinesses } = this.props;

    searchBusinesses({}, 0, 40).then((response) => {
      this.setState({
        businesses: response.businesses,
      });
    });
  }

  render() {
    const { t, searchBusinesses, placeholder, addToStack, customerFor, simpleForms } = this.props;
    const { customer, businesses } = this.state;

    const contactPersons = customer.business?.business?.contactPersons
      ?.map((association) => association.entity)
      .map((person) => ({
        value: person.id,
        label: `${person.firstName} ${person.lastName}`,
      }));

    return (
      <>
        <div
          className="input-group no-margin-top flex-container"
          key={`${customer.business && customer.business.id}-${
            customer.business && customer.business.nonce
          }`}
        >
          <div className="input-group no-margin-top two">
            <AsyncCreatableSelect
              isClearable
              noOptionsMessage={() => t('businesses.notFound')}
              placeholder={placeholder ? placeholder : t('form.label.selectCustomer')}
              loadingMessage={() => t('loading')}
              defaultOptions={this.sortByLabel(
                businesses.map((business) => convertBusinessToValue(t, business, customerFor))
              )}
              loadOptions={(inputValue, callback) => {
                if (inputValue && inputValue != '') {
                  if (this.searchTimeout) clearTimeout(this.searchTimeout);
                  this.searchTimeout = setTimeout(() => {
                    searchBusinesses({ query: inputValue }, 0, 40).then((response) => {
                      callback(
                        response.businesses.map((business) =>
                          convertBusinessToValue(t, business, customerFor)
                        )
                      );
                    });
                  }, 400);
                } else {
                  callback(businesses.map((business) => convertBusinessToValue(business)));
                }
              }}
              value={customer.business ? customer.business : null}
              onChange={(e) => {
                const newCustomer = { ...customer };
                newCustomer.business = e ? { ...e } : null;
                newCustomer.location = e?.locations?.[0]?.entity;

                this.onChange(newCustomer);
                this.setState({
                  customer: newCustomer,
                });
              }}
              onCreateOption={(inputValue) => {
                addToStack({
                  name: t('business.new'),
                  component: (
                    <CreateBusinessContainer
                      targetName={inputValue}
                      callback={(data) => {
                        const newCustomer = { ...customer };
                        newCustomer.business = {
                          id: data.business.id,
                          value: data.business.id,
                          label: data.business.name,
                          business: data.business,
                          locations: data.business.locations,
                        };

                        newCustomer.location = data?.business?.locations?.[0]?.entity;

                        this.onChange(newCustomer);
                        this.setState({
                          customer: newCustomer,
                        });
                      }}
                    />
                  ),
                });
              }}
            />
          </div>
          {customer.business && customer.location && (
            <div className="input-group no-margin-top one">
              <Select
                key={customer.business?.id + customer.location?.id}
                isDisabled={customer?.business?.locations?.length <= 1}
                noOptionsMessage={() => t('locations.notFound')}
                placeholder={t('form.label.location')}
                loadingMessage={() => t('loading')}
                options={customer.business?.locations?.map((location) =>
                  transformLocationToValue(location.entity)
                )}
                value={customer.business?.locations
                  ?.map((location) => transformLocationToValue(location.entity))
                  ?.filter((locationOption) => locationOption?.value === customer.location?.id)}
                onChange={(event) => {
                  const newCustomer = { ...customer };
                  newCustomer.location = event.location;

                  this.setState({
                    customer: newCustomer,
                  });

                  this.onChange(newCustomer);
                }}
              />
            </div>
          )}
          {customer.business && (
            <div className="input-group no-margin-top one">
              <AsyncCreatableSelect
                isClearable
                noOptionsMessage={() => t('contactPerson.notFound')}
                placeholder={t('form.label.contactPersons')}
                loadingMessage={() => t('loading')}
                defaultOptions={contactPersons}
                value={contactPersons.filter(
                  (person) => person?.value === customer.contactPerson?.id
                )}
                onChange={(event) => {
                  const newCustomer = { ...customer };
                  newCustomer.contactPerson = customer.business?.business?.contactPersons
                    ?.map((association) => association.entity)
                    .find((person) => person.id === event?.value);

                  this.setState({
                    customer: newCustomer,
                  });

                  this.onChange(newCustomer);
                }}
                onCreateOption={() => {
                  addToStack({
                    name: t('contactPerson.new'),
                    component: (
                      <CreateContactPersonContainer
                        targetBusiness={customer.business}
                        callback={(contactPerson, newBusiness) => {
                          const newCustomer = { ...customer };
                          newCustomer.contactPerson = contactPerson;
                          newCustomer.business = { ...customer.business, business: newBusiness };

                          this.setState({
                            customer: newCustomer,
                          });

                          this.onChange(newCustomer);
                        }}
                      />
                    ),
                  });
                }}
              />
            </div>
          )}
        </div>

        {customer.business && customer.business.business && !simpleForms && (
          <div className="input-group">
            <CustomerInput
              {...this.props}
              placeholder={t('form.label.customerFor', {
                business: customer.business.business.name,
              })}
              customerFor={customer.business.business.name}
              customer={customer.customer}
              onChange={(business) => {
                const newCustomer = { ...customer };
                newCustomer.customer = business;

                this.setState({
                  customer: newCustomer,
                });

                this.onChange(newCustomer);
              }}
            />
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(CustomerInput);
