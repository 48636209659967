import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';

import * as generalApi from '@api/generalApi';

class GenericTypesInput extends Component {
  constructor(props) {
    super(props);
    this.state = { typeOptions: [], subTypeOptions: [], typeChildren: {} };
  }

  // Function to convert the data to the format needed for react-select
  convertDataToOptions = (data) => {
    const { entityType, entitySubType } = this.props;
    const typeMap = {};

    data.forEach((item) => {
      const { targetEntityType, label, id, childOf, value, defaultValues } = item;

      if (targetEntityType === entityType) {
        if (!typeMap[id]) {
          typeMap[id] = {
            label,
            options: [],
            value: value,
            defaultValues: defaultValues,
            hiddenFields: item?.hiddenFields || [],
            hasParent: item.parent ? true : false,
          };
        }
      } else if (targetEntityType === entitySubType && childOf) {
        const parentId = childOf.id;
        if (!typeMap[parentId]) {
          typeMap[parentId] = {
            options: [],
            label: childOf.label,
            transportEquipmentType: childOf,
            hiddenFields: item?.hiddenFields || [],
            hasParent: item.parent ? true : false,
          };
        }
        typeMap[parentId].options.push({
          label,
          value: value,
          defaultValues: defaultValues,
          transportEquipmentType: childOf,

          hiddenFields: item?.hiddenFields || [],
          hasParent: item.parent ? true : false,
        });
      }
    });

    return Object.values(typeMap).map((group) =>
      group.options.length > 0
        ? group
        : {
            label: group.label,
            value: group.value,
            defaultValues: group.defaultValues,
            hiddenFields: group?.hiddenFields,
            hasParent: group?.hasParent,
          }
    );
  };

  // Function to find the selected option based on subTypeValue
  findSelectedOption = () => {
    const { subTypeValue, value } = this.props;
    const selectedOptions = [];

    this.state.typeOptions.forEach((group) => {
      if (group.options) {
        group.options.forEach((option) => {
          if (option.value === (subTypeValue ? subTypeValue : value)) {
            selectedOptions.push(option);
          }
        });
      } else if (group.value === (subTypeValue ? subTypeValue : value)) {
        selectedOptions.push(group);
      }
    });

    return selectedOptions;
  };

  componentDidMount() {
    const { types } = this.props;

    this.setState({
      typeOptions: this.convertDataToOptions(types),
    });
  }

  render() {
    const { t, onChange, placeholder } = this.props;
    const { typeOptions } = this.state;

    const filterOption = (option, inputValue) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    return (
      <Select
        isSearchable
        options={typeOptions}
        placeholder={placeholder}
        filterOption={filterOption}
        value={this.findSelectedOption()}
        noOptionsMessage={() => t('noOptions')}
        onChange={(newType) => {
          generalApi
            .mappingToJson({
              mapping: {
                ...newType.defaultValues,
                ...newType?.transportEquipmentType?.defaultValues,
              },
            })
            .then((response) => {
              if (newType?.transportEquipmentType) {
                onChange?.(newType.transportEquipmentType, newType, response.value);
              } else {
                onChange?.(newType, null, response.value);
              }
            });
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    types: state.platform.types,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(withTranslation('translation')(GenericTypesInput));
