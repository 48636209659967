export const CACHE_DATA = 'transportial';

export const DRIVERS = 'DRIVERS';
export const DRIVERS_ON_TRIP = 'DRIVERS_ON_TRIP';
export const EQUIPMENTS = 'EQUIPMENTS';
export const CONSIGNMENTS = 'CONSIGNMENTS';
export const VEHICLES = 'VEHICLES';
export const TRIP = 'TRIP';
export const CHAUFFEUR = 'CHAUFFEUR';
export const TRANSPORT_EQUIPMENT = 'TRANSPORT_EQUIPMENT';
export const CAPACITY_CONTRACTS = 'CAPACITY_CONTRACTS';

export const DAY_VIEW = 'DAY_VIEW';
export const TWO_DAY_VIEW = 'TWO_DAY_VIEW';
export const WEEK_VIEW = 'WEEK_VIEW';
export const ROUTE_CONTENT = 'ROUTE_CONTENT';
export const DRIVER_CONTENT = 'DRIVER_CONTENT';
export const VEHICLE_CONTENT = 'VEHICLE_CONTENT';
export const EQUIPMENT_CONTENT = 'EQUIPMENT_CONTENT';
export const ACTION_CONTENT = 'ACTION_CONTENT';
export const CONSIGNMENT_CONTENT = 'CONSIGNMENT_CONTENT';
