import * as alertApi from '@api/alertApi';

import { alertConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: alertConstants.ALERT_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching alert
 */
export const startGetAlert = () => ({
  type: alertConstants.GET_ALERT_STARTED,
});

export const getAlertSuccess = (result) => ({
  type: alertConstants.GET_ALERT_SUCCESS,
  ...result,
});

export const getAlertFailure = (error) => ({
  type: alertConstants.GET_ALERT_FAILURE,
  ...error,
});

export const getAlert = (id) => {
  return (dispatch) => {
    dispatch(startGetAlert());

    const promise = alertApi.get(id);

    promise
      .then((result) => {
        dispatch(getAlertSuccess(result));
      })
      .catch((error) => {
        dispatch(getAlertFailure(error));
      });

    return promise;
  };
};

/**
 * Updating alert
 */
export const startUpdateAlert = () => ({
  type: alertConstants.UPDATING_ALERT_STARTED,
});

export const updateAlertSuccess = (result) => ({
  type: alertConstants.UPDATING_ALERT_SUCCESS,
  ...result,
});

export const updateAlertFailure = (error) => ({
  type: alertConstants.UPDATING_ALERT_FAILURE,
  ...error,
});

export const solve = (alert) => {
  return (dispatch) => {
    dispatch(startUpdateAlert());

    const promise = alertApi.solve(alert);

    promise
      .then((result) => {
        dispatch(updateAlertSuccess(result));
      })
      .catch((error) => {
        dispatch(updateAlertFailure(error));
      });

    return promise;
  };
};

export const ignore = (alert) => {
  return (dispatch) => {
    dispatch(startUpdateAlert());

    const promise = alertApi.ignore(alert);

    promise
      .then((result) => {
        dispatch(updateAlertSuccess(result));
      })
      .catch((error) => {
        dispatch(updateAlertFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching alerts
 */
export const startGetAlerts = (context) => ({
  type: alertConstants.GET_ALERTS_STARTED,
  context: context,
});

export const getAlertsSuccess = (result, context) => ({
  type: alertConstants.GET_ALERTS_SUCCESS,
  context: context,
  ...result,
});

export const getAlertsFailure = (error, context) => ({
  type: alertConstants.GET_ALERTS_FAILURE,
  context: context,
  ...error,
});

export const getAlerts = () => {
  return (dispatch) => {
    dispatch(startGetAlerts(from));

    const promise = alertApi.all();

    promise
      .then((result) => {
        dispatch(getAlertsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getAlertsFailure(error));
      });

    return promise;
  };
};

export const allByEntity = (context, entityType, entityId) => {
  return (dispatch) => {
    dispatch(startGetAlerts(context));

    const promise = alertApi.allByEntity(entityType, entityId);

    promise
      .then((result) => {
        dispatch(getAlertsSuccess(result, context));
      })
      .catch((error) => {
        dispatch(getAlertsFailure(error, context));
      });

    return promise;
  };
};

export const allByEntities = (context, entities) => {
  return (dispatch) => {
    dispatch(startGetAlerts(context));

    const promise = alertApi.allByEntities(entities);

    promise
      .then((result) => {
        dispatch(getAlertsSuccess(result, context));
      })
      .catch((error) => {
        dispatch(getAlertsFailure(error, context));
      });

    return promise;
  };
};
