import api from '../api';

export const getFleetMoveActionsByDate = (fleet, startDate, endDate) => {
  return api.get(
    `/insights/fleet/${fleet?.id}/moveActions/${startDate.format(
      'YYYY-MM-DD'
    )}/${endDate.format('YYYY-MM-DD')}`,
    { timeout: 600000 }
  );
};
export const getFleetTripsByDate = (fleet, startDate, endDate) => {
  return api.get(
    `/insights/fleet/${fleet?.id}/trips/${startDate.format(
      'YYYY-MM-DD'
    )}/${endDate.format('YYYY-MM-DD')}`,
    { timeout: 600000 }
  );
};

export const getVehicleTripsByDate = (vehicle, startDate, endDate) => {
  return api.get(
    `/insights/vehicle/${vehicle?.id}/trips/${startDate.format(
      'YYYY-MM-DD'
    )}/${endDate.format('YYYY-MM-DD')}`,
    { timeout: 600000 }
  );
};

export const getVehiclesTripsByDate = (vehicles, startDate, endDate) => {
  return api.post(
    `/insights/vehicles/trips`,
    { vehicles, startDate, endDate },
    { timeout: 600000 }
  );
};

export const getVehiclesSensorValueByDate = (vehicles, type, date) => {
  return api.post(
    `/insights/vehicles/sensorValueAtDate`,
    { vehicles, type, date },
    { timeout: 600000 }
  );
};

export const getVehiclesCO2ByDateRange = (vehicles, startDate, endDate) => {
  return api.post(
    `/insights/vehicles/CO2:byDateRange`,
    { vehicles, startDate, endDate },
    { timeout: 600000 }
  );
};

// Customer insights
export const getPerCustomerTransportOrdersByDateRange = (
  status,
  startDate,
  endDate
) => {
  return api.post(
    `/insights/customer/transportOrders:byDateRange`,
    { status, startDate, endDate },
    { timeout: 60000 }
  );
};

// Chauffeur stats
export const getChauffeursActionsByDateRange = (
  chauffeurs,
  startDate,
  endDate
) => {
  return api.post(
    `/insights/chauffeurs/actions:byDateRange`,
    { chauffeurs, startDate, endDate },
    { timeout: 600000 }
  );
};

export const getChauffeursBorderCrossingsByDateRange = (
  chauffeurs,
  startDate,
  endDate
) => {
  return api.post(
    `/insights/chauffeurs/borderCrossings:byDateRange`,
    { chauffeurs, startDate, endDate },
    { timeout: 60000 }
  );
};

export const getChauffeursOnTruckByDateRange = (
  chauffeurs,
  startDate,
  endDate
) => {
  return api.post(
    `/insights/chauffeurs/onTruck:byDateRange`,
    { chauffeurs, startDate, endDate },
    { timeout: 600000 }
  );
};

// welcomeStats
export const getWelcomeStats = () => {
  return api.get(`/insights/user/welcome-stats`);
};

export const getUsersReportByDate = (startDate, endDate) => {
  return api.post(
    `/insights/users/report`,
    { startDate, endDate },
    { timeout: 600000 }
  );
};
