export default class Pricing {
  id;
  nonce;

  businesses;
  modules;

  enabled;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.businesses = [];
    this.modules = [];

    this.enabled = true;
  }
}
