
export const settingsGroupConstants = {
    SETTINGSGROUP_CHANGE_VALUE: "SETTINGSGROUP_CHANGE_VALUE",

    GET_SETTINGSGROUP_STARTED: "GET_SETTINGSGROUP_STARTED",
    GET_SETTINGSGROUP_SUCCESS: "GET_SETTINGSGROUP_SUCCESS",
    GET_SETTINGSGROUP_FAILURE: "GET_SETTINGSGROUP_FAILURE",

    GET_SETTINGSGROUPS_STARTED: "GET_SETTINGSGROUPS_STARTED",
    GET_SETTINGSGROUPS_SUCCESS: "GET_SETTINGSGROUPS_SUCCESS",
    GET_SETTINGSGROUPS_FAILURE: "GET_SETTINGSGROUPS_FAILURE",

    SEARCH_SETTINGSGROUPS_STARTED: "SEARCH_SETTINGSGROUPS_STARTED",
    SEARCH_SETTINGSGROUPS_SUCCESS: "SEARCH_SETTINGSGROUPS_SUCCESS",
    SEARCH_SETTINGSGROUPS_FAILURE: "SEARCH_SETTINGSGROUPS_FAILURE",

    CREATING_SETTINGSGROUP_STARTED: "CREATING_SETTINGSGROUP_STARTED",
    CREATING_SETTINGSGROUP_SUCCESS: "CREATING_SETTINGSGROUP_SUCCESS",
    CREATING_SETTINGSGROUP_FAILURE: "CREATING_SETTINGSGROUP_FAILURE",

    UPDATING_SETTINGSGROUP_STARTED: "UPDATING_SETTINGSGROUP_STARTED",
    UPDATING_SETTINGSGROUP_SUCCESS: "UPDATING_SETTINGSGROUP_SUCCESS",
    UPDATING_SETTINGSGROUP_FAILURE: "UPDATING_SETTINGSGROUP_FAILURE",

    DELETE_SETTINGSGROUP_STARTED: "DELETE_SETTINGSGROUP_STARTED",
    DELETE_SETTINGSGROUP_SUCCESS: "DELETE_SETTINGSGROUP_SUCCESS",
    DELETE_SETTINGSGROUP_FAILURE: "DELETE_SETTINGSGROUP_FAILURE",
};
