import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateIntegrationContainer from '@containers/integration/crud/CreateIntegrationContainer';

import { search as searchIntegrations } from '@api/integrationApi';

import * as integrationActions from '@actions/integrationActions';
import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

export const transformIntegrationToValue = (integration) => ({
  value: integration.id,
  label: integration.name,
  id: integration.id,
  integration: integration,
});

class IntegrationInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? transformIntegrationToValue(this.props.value) : null,
      searchParameters: this.props.searchParameters ? this.props.searchParameters : {},
      integrations: [],
    };
  }

  static defaultProps = {
    isDisabled: false,
  };

  componentDidMount() {
    const { searchParameters } = this.state;

    searchIntegrations({ ...searchParameters }, 0, 40).then((response) => {
      this.setState({
        integrations: response.integrations.map((integration) =>
          transformIntegrationToValue(integration)
        ),
      });
    });
  }

  onChange = (newIntegration) => {
    this.setState({
      value: newIntegration,
    });
    const { onChange } = this.props;
    onChange?.(newIntegration ? newIntegration.integration : null);
  };

  render() {
    const { t, placeholder, isFetchingIntegrations, addToStack, isDisabled } = this.props;
    const { value, searchParameters, integrations } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={placeholder ? placeholder : `${t('form.label.selectIntegration')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingIntegrations}
          isDisabled={isDisabled || isFetchingIntegrations}
          defaultOptions={integrations}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchIntegrations({ query: inputValue, ...searchParameters }, 0, 40).then(
                (response) => {
                  callback(
                    response.integrations.map((integration) =>
                      transformIntegrationToValue(integration)
                    )
                  );
                }
              );
            }, 400);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('integration.new'),
              component: (
                <CreateIntegrationContainer
                  targetName={inputValue}
                  callback={(newIntegration) => {
                    this.onChange(transformIntegrationToValue(newIntegration));
                  }}
                />
              ),
            });
          }}
          value={value}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(integrationActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(IntegrationInput));
