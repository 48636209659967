import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Messages from '@components/chat/Messages';

import * as chatActions from '@actions/chatActions';
import * as consignmentActions from '@actions/consignmentActions';
import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';

import { flatten } from '@utils/arrayUtils';

class MessagesContainer extends PureComponent {
  getChatUserByUserId = (id) => {
    const { chat } = this.props;
    return chat.users.find((user) => user?.user?.id === id);
  };

  render() {
    const { readMessages, getMessages, chat, messages, user } = this.props;

    return (
      <Messages
        {...this.props}
        getMessages={(from, amount) => getMessages(chat.id, from, amount)}
        readMessages={() => {
          if (messages && messages.length > 0) {
            const unreadMessages =
              flatten([...messages])
                ?.filter(
                  (message) =>
                    message.read?.filter(
                      (r) => r.user === this.getChatUserByUserId(user.id)?.id?.length || 0
                    ) < 1 && !message.isOffline
                )
                ?.map((message) => message.id) || [];
            if (unreadMessages.length > 0) {
              readMessages(unreadMessages);
            }
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    chat: state.chat.chat,
    messagesIsFetching: state.chat.messagesIsFetching,
    messagesIsFetchingFrom: state.chat.messagesIsFetchingFrom,
    messages: state.chat.messages,
    messagesTotalResults: state.chat.messagesTotalResults,
    messagesLoaded: state.chat.messagesLoaded,
    messagesWithError: state.chat.messagesWithError,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',

    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMessages: (chat, from, amount) => dispatch(chatActions.getMessages(chat, from, amount)),
    readMessages: (messages) => dispatch(chatActions.readMessages(messages)),

    updateTripAddDocuments: (trip, documents) =>
      dispatch(tripActions.updateTripAddDocuments(trip, documents)),
    updateConsignmentAddDocuments: (consignment, documents) =>
      dispatch(consignmentActions.updateConsignmentAddDocuments(consignment, documents)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(MessagesContainer));
