import React, { Component, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import ReactSVG from 'react-svg';

import EntityTodosContainer from '@containers/todo/EntityTodosContainer';
import UpdateVehicleContainer from '@containers/vehicle/crud/UpdateVehicleContainer';

import Collapsible from '@uicomponents/Collapsible';
import Loader from '@uicomponents/Loader';

import NotesView from '../note/NotesView';
import InformationComponent from '../ui/components/InformationComponent';

const ComplaintsContainer = lazy(() => import('@containers/complaint/ComplaintsContainer'));
const TiresContainer = lazy(() => import('@containers/tire/TiresContainer'));

class VehicleView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'vehicle',
    };
  }

  render() {
    const { tab } = this.state;
    const { t, vehicle, isFetchingVehicle, onChange, addToStack } = this.props;

    if (!vehicle || isFetchingVehicle) {
      return <Loader />;
    }
    const fieldsToRender = [
      { name: 'vehicleType', type: 'type', entityType: 'vehicle' },
      { name: 'fuelType', type: 'text', entityType: 'vehicle' },
      { name: 'emissionsClass', type: 'text', entityType: 'vehicle' },
      { name: 'description', type: 'text', entityType: 'vehicle' },
      { name: 'vin', type: 'text', entityType: 'vehicle' },
      { name: 'licensePlate', type: 'text', entityType: 'vehicle' },
      { name: 'maxLinks', type: 'text', entityType: 'vehicle' },
      { name: 'maxWeight', type: 'maxWeight', entityType: 'vehicle' },
      { name: 'emptyWeight', type: 'weight', entityType: 'vehicle' },
      { name: 'loadMeters', type: 'loadMeters', entityType: 'vehicle' },
      { name: 'dimensions', type: 'dimensions', entityType: 'vehicle' },
      { name: 'loadDimensions', type: 'loadDimensions', entityType: 'vehicle' },
      { name: 'loadCapacities', type: 'loadCapacities', entityType: 'vehicle' },
    ];
    return (
      <div className="vehicle-detail">
        <div className="flex-container justify-between no-wrap">
          <div>
            <div className="flex-container justify-between">
              <h1 className="no-margin">{vehicle.name}</h1>
            </div>
            <h3 className="no-margin-top">#{vehicle.id}</h3>
          </div>
          <div className="self-center flex-container action-icons">
            <div
              className="action-icon"
              onClick={(e) => {
                e.preventDefault();
                addToStack({
                  name: t('vehicle.update.header'),
                  component: (
                    <UpdateVehicleContainer
                      targetVehicle={{ ...vehicle }}
                      callback={(result) => {
                        onChange('vehicle', result.vehicle || {});
                      }}
                    />
                  ),
                });
              }}
            >
              <ReactSVG src="/icons/pencil.svg" />
            </div>
          </div>
        </div>

        <ScrollContainer
          verticle={false}
          hideScrollbars={false}
          className="align-items-center d-flex gap-10 tab-links-container my-1"
        >
          <div
            className={`tab-links${tab === 'vehicle' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'vehicle' })}
          >
            {t('vehicles.header')}
          </div>
          <div
            className={`tab-links${tab === 'complaints' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'complaints' })}
          >
            {t('complaints.header')}
          </div>
          <div
            className={`tab-links${tab === 'tires' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'tires' })}
          >
            {t('tires.header')}
          </div>
          {this.props.platform?.features?.filter((feature) => feature.name === 'todo').length >
            0 && (
            <div
              className={`tab-links${tab === 'todos' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'todos' })}
            >
              {t('todos.header')}
            </div>
          )}
        </ScrollContainer>

        <section className={`vehicle-detail__box activity${tab === 'complaints' ? ' active' : ''}`}>
          {tab === 'complaints' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <ComplaintsContainer entityType="vehicle" entity={vehicle} />
            </Suspense>
          ) : null}
        </section>

        <section className={`vehicle-detail__box activity${tab === 'tires' ? ' active' : ''}`}>
          {tab === 'tires' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <TiresContainer entityType="vehicle" entity={vehicle} />
            </Suspense>
          ) : null}
        </section>

        <section className={`vehicle-detail__box activity${tab === 'vehicle' ? ' active' : ''}`}>
          <InformationComponent entity={vehicle} fields={fieldsToRender} />

          <Collapsible
            name={`${t('notes.header')}${
              vehicle.notes && vehicle.notes.length > 0 ? ` (${vehicle.notes.length})` : ''
            }`}
            className="collapsible-element"
            defaultIsOpen={false}
          >
            <NotesView notes={vehicle.notes} />
          </Collapsible>
        </section>

        <section className={`chauffeur-view__box activity${tab === 'todos' ? ' active' : ''}`}>
          {tab === 'todos' ? (
            <EntityTodosContainer
              {...this.props}
              relatedEntity={vehicle}
              relatedEntityType="vehicle"
            />
          ) : null}
        </section>
      </div>
    );
  }
}
export default withTranslation('translation')(VehicleView);
