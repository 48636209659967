import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateAdministrationContainer from '@containers/administration/crud/CreateAdministrationContainer';

import { search as searchAdministrations } from '@api/administrationApi';

import * as administrationActions from '@actions/administrationActions';
import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

export const transformAdministrationToValue = (administration) => ({
  value: administration.id,
  label: administration.name,
  id: administration.id,
  administration: administration,
});

class AdministrationInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? transformAdministrationToValue(this.props.value) : null,
      administrations: [],
    };
  }

  componentDidMount() {
    searchAdministrations({ query: '' }, 0, 40).then((response) => {
      this.setState({
        administrations: response.administrations.map((administration) =>
          transformAdministrationToValue(administration)
        ),
      });
    });
  }

  onChange = (newAdministration) => {
    this.setState({
      value: newAdministration,
    });
    const { onChange } = this.props;
    onChange?.(newAdministration ? newAdministration.administration : null);
  };

  render() {
    const { t, isFetchingAdministrations, addToStack } = this.props;
    const { value, administrations } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.administration')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingAdministrations}
          isDisabled={isFetchingAdministrations}
          defaultOptions={administrations}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchAdministrations({ query: inputValue }, 0, 40).then((response) => {
                callback(
                  response.administrations.map((administration) =>
                    transformAdministrationToValue(administration)
                  )
                );
              });
            }, 400);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('administration.new'),
              component: (
                <CreateAdministrationContainer
                  targetName={inputValue}
                  callback={(newAdministration) => {
                    this.onChange(transformAdministrationToValue(newAdministration));
                  }}
                />
              ),
            });
          }}
          value={value}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(administrationActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(AdministrationInput));
