import React, { Component } from 'react';

import moment from 'moment';

export default class CommunicationRequest extends Component {
  render() {
    const { communicationRequest, hidden } = this.props;

    if (hidden) return null;

    return (
      <div
        className={`communicationRequest ${hidden ? 'communicationRequest--hidden' : ''}`}
        key={`communicationRequest-${communicationRequest.nonce}-${communicationRequest.id}`}
      >
        <div className="communicationRequest__name">
          {communicationRequest.name}{' '}
          <span className={communicationRequest.status}>{communicationRequest.status}</span>
        </div>
        <div className="communicationRequest__description">{communicationRequest.description}</div>
        {communicationRequest.communicationRequests?.length > 0 && (
          <div className="communicationRequest__children">
            {[...communicationRequest.communicationRequests]
              .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
              .map((subCommunicationRequest, index) => (
                <CommunicationRequest
                  communicationRequest={subCommunicationRequest}
                  hidden={hidden}
                />
              ))}
          </div>
        )}
      </div>
    );
  }
}
