import React, { Component } from 'react';

import { defaultDateTimeFormat } from '@utils/dateUtils';

export default class EventCard extends Component {
  render() {
    const { event, hidden } = this.props;

    if (hidden) return null;

    return (
      <div className="event-card">
        <h4 className="event-card__name with-flair">
          {event.type} <span className={event.lifeCycle}>{event.lifeCycle}</span>
        </h4>
        {event.dateTime && (
          <div className="event-card__dates">{defaultDateTimeFormat(event.dateTime)}</div>
        )}
        {!event.dateTime && (
          <div className="event-card__dates">
            {defaultDateTimeFormat(event.createdAt)} - {defaultDateTimeFormat(event.updatedAt)}
          </div>
        )}
      </div>
    );
  }
}
