import { renderRoutes } from '@routes';
import { lazy } from 'react';

const SettingsGroupsContainer = lazy(() =>
  import('@containers/settingsGroup/SettingsGroupsContainer')
);

const routes = [
  {
    paths: ['', '/:id', '/:id/update', '/create'],
    component: SettingsGroupsContainer,
    type: 'portal',
  },
];

const SettingsGroupRoutes = (props) => renderRoutes(props, routes);
export default SettingsGroupRoutes;
