import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ReactSVG from 'react-svg';

import { registerLocales } from '@/i18n';
import { i18n, store } from '@/index';
import BusinessesInput from '@components/business/BusinessesInput';
import TeamsInput from '@components/team/TeamsInput';
import VehicleGroupSelector from '@components/vehicleGroup/VehicleGroupSelector';

import Collapsible from '@uicomponents/Collapsible';
import OptionsMenu from '@uicomponents/OptionsMenu';
import FormInput from '@uiinputs/FormInput';
import FilterView from '@uiviews/FilterView';

import * as navigationActions from '@actions/navigationActions';

import { groupBy, swapArrayLocs } from '@utils/arrayUtils';
import { momentFormatWithoutLiterals } from '@utils/dateUtils';
import { activeFilterCount } from '@utils/filterUtils';

const DragHandle = SortableHandle(() => (
  <div className="draggable m-0 p-0" style={{ marginRight: '10px', height: 17, width: 17 }}>
    <ReactSVG src="/icons/drag.svg" />
  </div>
));

const SortableItem = SortableElement(
  ({
    value,
    isNextLevel,
    firstCollectionElement,
    key,
    onToggle,
    className,
    isChecked,
    sortable,
  }) => (
    <div
      className={'sortable-item ' + className}
      key={key}
      tabIndex={0}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
        position: 'relative',
        userSelect: 'none',
      }}
    >
      <div
        className="item-content p-4-top p-4-bottom"
        style={{ display: 'flex', width: '100%', flexWrap: 'no-wrap', wordBreak: '' }}
      >
        {sortable && <DragHandle />}
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();

            onToggle?.(value);
          }}
          id={`checkbox-${value.name}`}
          style={{ marginRight: '10px' }}
        />
        <label htmlFor={`checkbox-${value.name}`} style={{ fontSize: '14px' }}>
          {value.header || value?.getLabel?.(0) || i18n.t(`${value.name}`)}
        </label>
      </div>
    </div>
  )
);

const SortableList = SortableContainer(({ items, baseLevel, onToggle, sortable }) => {
  var lastItemLevel = null;
  return (
    <ul className="p-0 m-0">
      {items
        .filter((item) => item.name !== 'planBoard.info')
        .map((item, index) => {
          const element = (
            <SortableItem
              key={`item-${item.name}-${item.selected}`}
              collection={item.level}
              className={item.level && baseLevel !== item.level ? 'p-20-left m-0' : 'p-0 m-0'}
              index={index}
              value={item}
              onToggle={onToggle}
              isChecked={item.selected}
              sortable={sortable}
              isNextLevel={item.level && baseLevel !== item.level}
              firstCollectionElement={lastItemLevel !== null && lastItemLevel !== item.level}
            />
          );

          lastItemLevel = item.level;
          return element;
        })}
    </ul>
  );
});

class ActionIcon extends Component {
  static defaultProps = {
    type: null,
    enabled: true,
    namespace: '',
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { options, onChange } = this.props;
    this.props?.indexAdjustment
      ? onChange?.([...swapArrayLocs(options, oldIndex + 1, newIndex + 1)])
      : onChange?.([...swapArrayLocs(options, oldIndex, newIndex)]);
  };

  onToggle = (field) => {
    const { options, onChange } = this.props;
    onChange?.(
      options.map((e) => (e.name === field.name ? { ...e, selected: !field?.selected } : e))
    );
  };

  componentDidMount() {
    registerLocales();
  }

  render() {
    const {
      t,
      icon,
      value,
      type,
      url,
      namespace,
      enabled,
      dropMenu,
      filters,
      onChange,
      onClick,
      options,
      sortable,
      children,
      baseLevel,
      className,
      childClassName,
      optionItems,
    } = this.props;

    let innerAction = null;

    if (!enabled) return;

    switch (type) {
      case 'query':
        innerAction = (
          <div
            className={`action-input desktop-only form no-padding${
              childClassName ? ` ${childClassName}` : ''
            }`}
          >
            <div className="input-group no-margin plan-board-search">
              <FormInput
                type="text"
                name={namespace}
                wrapperClass="no-margin-top"
                value={filters?.find((f) => f.name === 'query')?.value || ''}
                placeholder={t('search.placeholder')}
                onChange={onChange}
              />
            </div>
          </div>
        );
        break;
      case 'vehicleGroupSelector':
        innerAction = (
          <div className={`action-input desktop-only form no-padding`}>
            <div className="input-group no-margin">
              <VehicleGroupSelector
                value={filters?.find((f) => f.name === 'vehicles').value}
                onChange={onChange}
              />
            </div>
          </div>
        );
        break;
      case 'teamsInput':
        innerAction = (
          <div className={`action-input desktop-only form no-padding`}>
            <div className="input-group no-margin">
              <TeamsInput
                key={filters
                  .find((f) => f.name === 'teams')
                  ?.value?.map((entity) => entity?.id)
                  .join('-')}
                value={filters.find((f) => f.name === 'teams')?.value || ''}
                onChange={onChange}
              />
            </div>
          </div>
        );
        break;
      case 'businessInput':
        innerAction = (
          <div className={`action-input desktop-only form no-padding`}>
            <div className="input-group no-margin">
              <BusinessesInput
                key={filters
                  .find((f) => f.name === 'customers')
                  ?.value?.map((entity) => entity?.id)
                  .join('-')}
                value={filters.find((f) => f.name === 'customers')?.value || ''}
                onChange={onChange}
              />
            </div>
          </div>
        );
        break;
      case 'dateRangeSelector':
        innerAction = (
          <div className={`action-input desktop-only form no-padding`}>
            <div className="input-group no-margin">
              <DatePicker
                placeholderText={t('form.label.dateTime')}
                selected={
                  filters.find((f) => f.name === 'date-range')?.value?.[0]
                    ? new Date(filters.find((f) => f.name === 'date-range')?.value?.[0])
                    : null || null
                }
                startDate={
                  filters.find((f) => f.name === 'date-range')?.value?.[0]
                    ? new Date(filters.find((f) => f.name === 'date-range')?.value?.[0])
                    : null || null
                }
                endDate={
                  filters.find((f) => f.name === 'date-range')?.value?.[1]
                    ? new Date(filters.find((f) => f.name === 'date-range')?.value?.[1])
                    : null || null
                }
                showWeekNumbers={true}
                dateFormat={momentFormatWithoutLiterals(
                  store.getState().settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY'
                )}
                selectsRange
                isClearable
                onChange={onChange}
              />
            </div>
          </div>
        );
        break;
      case 'filters':
        innerAction = (
          <div
            className={`action-icon${
              activeFilterCount(filters) > 0 ? ' active' : ''
            } plan-board-filter`}
            data-content={activeFilterCount(filters)}
            onClick={(e) => {
              e.preventDefault();

              store.dispatch(
                navigationActions.addToStack({
                  name: t('filters'),
                  component: <FilterView filters={filters} onChange={onChange} />,
                })
              );
            }}
          >
            <ReactSVG src="/icons/filter.svg" />
          </div>
        );

        break;
      case 'sort':
        innerAction = (
          <>
            <div
              className="action-icon plan-board-sort"
              onClick={(e) => {
                e.preventDefault();

                onClick?.(e);
              }}
            >
              <ReactSVG src="/icons/sort.svg" />
            </div>
            <div className={`action-menu${dropMenu ? ' show' : ''}`}>
              <div className="scrollable menu-container">
                {options.map((option) => (
                  <div
                    className="action-item flex-container no-wrap"
                    key={`${namespace}-action-item-${option.name}${
                      value === option.name ? '-selected' : ''
                    }`}
                  >
                    <input
                      className="self-center"
                      type="radio"
                      value="selected"
                      id={`option-sort-item-${namespace}-${option.name}`}
                      checked={value === option.name}
                      onChange={() => {
                        //just here to satisfy React
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onChange?.(option);
                      }}
                    />
                    <label
                      className="name normal"
                      htmlFor={`option-sort-item-${namespace}-${option.name}`}
                    >
                      {option.direction === 'asc' ? '↑ ' : '↓ '}
                      {option.header || option.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
        break;
      case 'fields':
        const groupedOptions = groupBy([...(options || [])], 'level');
        innerAction = (
          <>
            <div
              className="action-icon plan-board-fields"
              onClick={(e) => {
                e.preventDefault();
                onClick?.(e);
              }}
            >
              <ReactSVG src="/icons/fields.svg" />
            </div>

            <div className={`action-menu${dropMenu ? ' show' : ''}`}>
              <div className="scrollable__y menu-container p-4">
                {Object.keys(groupedOptions)?.map((groupedOptionKey, index) => {
                  var element = (
                    <>
                      <SortableList
                        items={groupedOptions?.[groupedOptionKey]}
                        helperClass="sortable-helper"
                        baseLevel={baseLevel}
                        onToggle={this.onToggle}
                        onSortEnd={({ oldIndex, newIndex }) => {
                          const prevArray = Object.keys(groupedOptions)?.slice(0, index);

                          const baseIndex =
                            prevArray.length > 0
                              ? prevArray?.reduce((acc, optionKey) => {
                                  return acc + groupedOptions?.[optionKey]?.length;
                                }, 0)
                              : 0;

                          this.onSortEnd({
                            oldIndex: (index === 0 ? oldIndex : oldIndex - index) + baseIndex,
                            newIndex: (index === 0 ? newIndex : newIndex - index) + baseIndex,
                          });
                        }}
                        hideSortableGhost={false}
                        useDragHandle={true}
                        sortable={sortable}
                      />
                    </>
                  );

                  if (index === 0) {
                    return element;
                  } else {
                    return (
                      <Collapsible name={t(groupedOptionKey)} defaultIsOpen={false}>
                        {element}
                      </Collapsible>
                    );
                  }
                })}
              </div>
            </div>
          </>
        );
        break;
      case 'settings':
        innerAction = (
          <>
            <div
              className="action-icon plan-board-settings"
              onClick={(e) => {
                e.preventDefault();

                onClick?.(e);
              }}
            >
              <ReactSVG src="/icons/settings.svg" />
            </div>

            <div className={`action-menu${dropMenu ? ' show' : ''}`}>
              <div className="scrollable menu-container">
                {options.map((option) => (
                  <div
                    className="action-item flex-container"
                    key={`${name}-action-item-${option.name}${option.value ? '-selected' : ''}`}
                  >
                    <input
                      className="self-center"
                      type="checkbox"
                      value="selected"
                      id={`option-action-item-${option.name}`}
                      checked={option.value}
                      onChange={() => {
                        //just here to satisfy React
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        onChange?.({ ...option, value: !option.value });
                      }}
                    />
                    <label className="name normal" htmlFor={`option-action-item-${option.name}`}>
                      {t(`planBoard.${option.name}`)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
        break;
      case 'download':
        innerAction = (
          <div className="action-icon plan-board-export" onClick={onClick}>
            <ReactSVG src="/icons/download.svg" />
          </div>
        );
        break;
      case 'maximize':
        innerAction = (
          <div
            className="action-icon"
            onClick={(e) => {
              window
                .open(
                  url,
                  'popUp' + name,
                  'height=500,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                )
                .focus();
            }}
          >
            <ReactSVG src="/icons/maximize.svg" />
          </div>
        );
        break;
      case 'custom':
        innerAction = (
          <div
            className={`action-icon${childClassName ? ` ${childClassName}` : ''}`}
            onClick={(e) => {
              e.preventDefault();

              onClick?.(e);
            }}
          >
            <ReactSVG src={icon} />
          </div>
        );
        break;
      case 'pdfDownload':
        innerAction = (
          <div
            className={`action-icon${childClassName ? ` ${childClassName}` : ''}`}
            onClick={(e) => {
              onClick?.(e);
            }}
          >
            {children}
            <ReactSVG src={icon} />
          </div>
        );
        break;
      case 'options':
        innerAction = (
          <OptionsMenu className="options-wrapper d-flex align-items-center" items={optionItems} />
        );
        break;
    }

    return <div className={`action${className ? ` ${className}` : ''}`}>{innerAction}</div>;
  }
}
export default withTranslation('translation')(ActionIcon);
