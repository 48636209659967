import { renderRoutes } from '@routes';
import { lazy } from 'react';

const Chats = lazy(() => import('@containers/chat/ChatsContainer'));

const routes = [
  {
    paths: ['/', '/new', '/multi/new', '/:id/edit', '/:id'],
    component: Chats,
    type: 'portal',
  },
];

const MessageRoutes = (props) => renderRoutes(props, routes);
export default MessageRoutes;
