import { socketConstants } from '@constants';

export const newChatMessage = (message) => ({
  type: socketConstants.NEW_CHAT_MESSAGE,
  ...message,
});

export const newNotification = (message) => ({
  type: socketConstants.NEW_NOTIFICATION,
  ...message,
});

export const newTrip = (message) => ({
  type: socketConstants.NEW_TRIP,
  ...message,
});

export const newConsignment = (message) => ({
  type: socketConstants.NEW_CONSIGNMENT,
  ...message,
});

export const newMailMessages = (message) => ({
  type: socketConstants.NEW_MAIL_MESSAGES,
  ...message,
});

export const newExtendedVehicles = (message) => ({
  type: socketConstants.NEW_EXTENDED_VEHICLES,
  ...message,
});

export const newExtendedTransportOrders = (message) => ({
  type: socketConstants.NEW_EXTENDED_TRANSPORT_ORDERS,
  ...message,
});
export const newAlert = (message) => ({
  type: socketConstants.NEW_ALERT,
  ...message,
});