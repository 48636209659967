import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';

import ActionsInput from '@components/action/crud/ActionsInput';
import AttributesInput from '@components/attribute/AttributesInput';
import ConstraintsInput from '@components/constraint/crud/ConstraintsInput';
import GoodsInput from '@components/goods/crud/GoodsInput';
import ItemsInput from '@components/goods/crud/ItemsInput';
import TransportEquipmentsInput from '@components/goods/crud/TransportEquipmentsInput';
import LocationInput from '@components/location/crud/LocationInput';
import PricingElementsInput from '@components/pricing/crud/PricingElementsInput';

import Attachments from '@uicomponents/Attachments';
import Collapsible from '@uicomponents/Collapsible';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';
import GenericTypesInput from '@uiinputs/GenericTypesInput';

import Association from '@models/general/Association';

import {
  consignmentGetAllChildActions,
  updateConsignmentActions,
  updateConsignmentActionsWithSequence,
} from '@utils/actionUtils';
import { flatten } from '@utils/arrayUtils';
import { activateInputs, deactivateInputs } from '@utils/formUtils';

class ConsignmentForm extends Component {
  static defaultProps = {
    flat: false,
  };
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      consignment: this.props.consignment,
      childrenActions: consignmentGetAllChildActions({ ...this.props.consignment }),
      originalPhysicalSender: false,
      originalLegalSender: false,
      originalPhysicalAddressee: false,
      originalLegalAddressee: false,
    };
  }

  componentDidMount() {
    activateInputs();
  }
  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange = (newConsignment) => {
    const { onChange, simpleForms } = this.props;
    this.setState({
      consignment: newConsignment,
    });

    if (simpleForms) {
      onChange?.(newConsignment);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onChange, popStack } = this.props;
    const { consignment } = this.state;

    onChange?.(consignment);
    popStack?.();
  };

  render() {
    const { t, platform, simpleForms, setHasChanged, flat } = this.props;
    const {
      consignment,
      childrenActions,
      originalPhysicalSender,
      originalLegalSender,
      originalPhysicalAddressee,
      originalLegalAddressee,
    } = this.state;

    let goodActionCount = 0;
    consignment?.goods?.forEach((good) => {
      goodActionCount += good?.entity?.actions?.length || 0;
      if (good?.entity?.containedGoods) {
        good?.entity?.containedGoods?.forEach((containedGood) => {
          goodActionCount += containedGood?.entity?.actions?.length || 0;
        });
      }
    });

    return (
      <div className={`consignment-form${flat ? ' flex-container' : ''}`}>
        {!simpleForms && (
          <div className={`${flat ? ' one' : ''}`}>
            <FormInput
              type="text"
              parentName="consignmentform"
              label="form.label.name"
              id="consignment-name"
              required={true}
              setHasChanged={setHasChanged}
              value={consignment?.name}
              onChange={(event) => {
                const newConsignment = { ...consignment };
                newConsignment.name = event.target.value;

                this.onChange(newConsignment);
              }}
            />
            {this.validator.message(t('form.label.name'), consignment.name, 'required')}
          </div>
        )}
        <FormInput
          type="component"
          parentName="consignmentform"
          label="form.label.selectConsignmentType"
          wrapperClass={`${flat ? ' one' : ''}`}
          setHasChanged={setHasChanged}
          Component={
            <GenericTypesInput
              value={consignment.type}
              entityType="consignmentType"
              className="consignment-type"
              placeholder={`${t('form.label.selectConsignmentType')}`}
              onChange={(type, subType, defaultValues) => {
                const newConsignment = { ...consignment, ...JSON.parse(defaultValues) };
                newConsignment.type = type?.value;

                this.onChange(newConsignment);

                this.setState({ hiddenTypeFields: type?.hiddenFields || [] });
              }}
            />
          }
        />
        <FormInput
          type="textarea"
          parentName="consignmentform"
          label="form.label.description"
          className="small"
          wrapperClass={`${flat ? ' one' : ''}`}
          setHasChanged={setHasChanged}
          id="consignment-description"
          value={consignment?.description}
          onChange={(event) => {
            const newConsignment = { ...consignment };
            newConsignment.description = event.target.value;

            this.onChange(newConsignment);
          }}
        />
        <FormInput
          type="component"
          parentName="consignmentform"
          label={'form.label.attributes'}
          wrapperClass={`${flat ? ' one' : ''}`}
          Component={
            <AttributesInput
              key={consignment.attributes ? consignment.attributes : null}
              value={consignment.attributes ? consignment.attributes : null}
              onChange={(newAttributes) => {
                const newConsignment = { ...consignment };
                newConsignment.attributes = newAttributes ? newAttributes : null;

                this.onChange(newConsignment);
              }}
            />
          }
        />
        {!flat && (
          <>
            <div className="input-group">
              <h3 className="no-margin-top no-margin-bottom">{t('form.label.documents')}</h3>
              <Attachments
                {...this.props}
                key={consignment?.documents}
                files={consignment?.documents?.map((document) => {
                  return document.entity.file;
                })}
                onChange={(files) => {
                  const newConsignment = { ...consignment };
                  newConsignment.documents = files.map((file) => {
                    const newFile = { ...file };
                    return new Association('inline', {
                      name: newFile.originalName,
                      mimeType: newFile.mimeType,
                      content: {
                        contentType: 'uri',
                        uri: newFile.url,
                      },
                      file: newFile,
                    });
                  });
                  this.onChange(newConsignment);
                }}
              />
            </div>
            <div className="input-group more">
              <div>{t('form.label.isCombinedTransport')}</div>
              <Switch
                checked={consignment?.combined}
                onChange={(e, newValue) => {
                  e.preventDefault();
                  const newConsignment = { ...consignment };
                  newConsignment.combined = newValue;

                  this.onChange(newConsignment);
                }}
              />
            </div>
          </>
        )}
        {consignment?.combined && (
          <>
            <div className="input-group">
              <Collapsible
                name={t('consignment.originalPhysicalSender')}
                isOpen={originalPhysicalSender}
                onOpenChange={(newValue) => this.setState({ originalPhysicalSender: newValue })}
              >
                <LocationInput
                  key={consignment.originalPhysicalSender}
                  location={consignment.originalPhysicalSender}
                  onChange={(newLocation) => {
                    const newConsignment = { ...consignment };
                    newConsignment.originalPhysicalSender = newLocation;

                    this.onChange(newConsignment);
                  }}
                />
              </Collapsible>
            </div>
            <div className="input-group">
              <Collapsible
                name={t('consignment.originalLegalSender')}
                isOpen={originalLegalSender}
                onOpenChange={(newValue) => this.setState({ originalLegalSender: newValue })}
              >
                <LocationInput
                  key={consignment.originalLegalSender}
                  location={consignment.originalLegalSender}
                  onChange={(newLocation) => {
                    const newConsignment = { ...consignment };
                    newConsignment.originalLegalSender = newLocation;

                    this.onChange(newConsignment);
                  }}
                />
              </Collapsible>
            </div>
            <div className="input-group">
              <Collapsible
                name={t('consignment.originalPhysicalAddressee')}
                isOpen={originalPhysicalAddressee}
                onOpenChange={(newValue) => this.setState({ originalPhysicalAddressee: newValue })}
              >
                <LocationInput
                  key={consignment.originalPhysicalAddressee}
                  location={consignment.originalPhysicalAddressee}
                  onChange={(newLocation) => {
                    const newConsignment = { ...consignment };
                    newConsignment.originalPhysicalAddressee = newLocation;

                    this.onChange(newConsignment);
                  }}
                />
              </Collapsible>
            </div>
            <div className="input-group">
              <Collapsible
                name={t('consignment.originalLegalAddressee')}
                isOpen={originalLegalAddressee}
                onOpenChange={(newValue) => this.setState({ originalLegalAddressee: newValue })}
              >
                <LocationInput
                  key={consignment.originalLegalAddressee}
                  location={consignment.originalLegalAddressee}
                  onChange={(newLocation) => {
                    const newConsignment = { ...consignment };
                    newConsignment.originalLegalAddressee = newLocation;

                    this.onChange(newConsignment);
                  }}
                />
              </Collapsible>
            </div>
          </>
        )}
        <div className="input-group no-margin-top actions-input">
          <h3 className="no-margin-bottom">{t('form.label.actions')}</h3>
          <ActionsInput
            key={[...(consignment?.actions || []), ...flatten(childrenActions)]
              .map((action) => action?.id || action?.nonce)
              .join('-')}
            initialSequenceNr={[...(consignment?.actions || [])].length + goodActionCount}
            actions={[...(consignment?.actions || []), ...flatten(childrenActions)].filter(
              (action) => action
            )}
            createAction={this.state.createAction}
            onChange={(actions) => {
              const newConsignment = updateConsignmentActions(consignment, actions);

              this.setState({
                childrenActions: actions.filter((action) => action.entity.isChild),
              });

              this.onChange(newConsignment);
            }}
          />
        </div>

        {flat ? (
          <div className="input-group no-margin-top items-input">
            <h3>{t('form.label.goods')}</h3>
            <GoodsInput
              {...this.props}
              initialSequenceNr={consignment?.actions?.length + goodActionCount}
              key={consignment?.goods?.map((entity) => entity.id + entity.nonce).join()}
              goods={consignment?.goods}
              defaultEnabled={true}
              onChange={(newGoods, renumberActions) => {
                const newConsignment = { ...consignment };
                newConsignment.goods = newGoods;

                this.setState(
                  {
                    childrenActions: consignmentGetAllChildActions({ ...newConsignment }),
                  },
                  () => {
                    if (renumberActions) {
                      const updatedConsignment = updateConsignmentActionsWithSequence(
                        newConsignment,
                        [...newConsignment.actions, ...flatten(this.state.childrenActions)].filter(
                          (action) => action
                        )
                      );
                      this.onChange(updatedConsignment);
                    } else {
                      this.onChange(newConsignment);
                    }
                  }
                );
              }}
            />
          </div>
        ) : (
          <>
            <div className="input-group no-margin-top equipment-input">
              <h3>{t('form.label.transportEquipment')}</h3>
              <TransportEquipmentsInput
                {...this.props}
                initialSequenceNr={consignment?.actions?.length + goodActionCount}
                key={
                  consignment?.goods
                    ? [...consignment.goods].filter((g) => g.entity.type === 'transportEquipment')
                        .length + goodActionCount
                    : null
                }
                goods={
                  consignment?.goods
                    ? [...consignment.goods.filter((g) => g.entity.type === 'transportEquipment')]
                    : null
                }
                defaultEnabled={true}
                onChange={(newGoods, renumberActions) => {
                  const newConsignment = { ...consignment };
                  newConsignment.goods = [
                    ...(newConsignment?.goods?.filter((g) => g.entity.type === 'items') || []),
                    ...newGoods,
                  ];

                  this.setState(
                    {
                      childrenActions: consignmentGetAllChildActions({ ...newConsignment }),
                    },
                    () => {
                      if (renumberActions) {
                        const updatedConsignment = updateConsignmentActionsWithSequence(
                          newConsignment,
                          [
                            ...newConsignment.actions,
                            ...flatten(this.state.childrenActions),
                          ].filter((action) => action)
                        );
                        this.onChange(updatedConsignment);
                      } else {
                        this.onChange(newConsignment);
                      }
                    }
                  );
                }}
              />
            </div>
            <div className="input-group no-margin-top items-input">
              <h3>{t('form.label.goods')}</h3>
              <ItemsInput
                {...this.props}
                createGoods={this.state.createGoods}
                initialSequenceNr={consignment?.actions?.length + goodActionCount}
                key={
                  consignment?.goods?.length > 0
                    ? [...consignment?.goods]?.filter((g) => g.entity.type === 'items').length +
                      goodActionCount
                    : null
                }
                goods={
                  [...consignment?.goods]?.length > 0
                    ? [...consignment?.goods?.filter((g) => g.entity.type === 'items')]
                    : null
                }
                defaultEnabled={true}
                onChange={(newGoods, renumberActions) => {
                  const newConsignment = { ...consignment };
                  newConsignment.goods = [
                    ...(newConsignment?.goods?.filter(
                      (g) => g.entity.type === 'transportEquipment'
                    ) || []),
                    ...newGoods,
                  ];

                  this.setState(
                    {
                      childrenActions: consignmentGetAllChildActions({ ...newConsignment }),
                    },
                    () => {
                      if (renumberActions) {
                        const updatedConsignment = updateConsignmentActionsWithSequence(
                          newConsignment,
                          [
                            ...newConsignment.actions,
                            ...flatten(this.state.childrenActions),
                          ].filter((action) => action)
                        );
                        this.onChange(updatedConsignment);
                      } else {
                        this.onChange(newConsignment);
                      }
                    }
                  );
                }}
              />
            </div>
            <div className="input-group no-margin-top constraints-input">
              <h3>{t('form.label.constraints')}</h3>
              <ConstraintsInput
                {...this.props}
                createLimitation={this.state.createLimitation}
                constraints={[...(consignment.constraints || [])]}
                onChange={(newConstraints) => {
                  const newConsignment = { ...consignment };
                  newConsignment.constraints = [...newConstraints];

                  this.onChange(newConsignment);
                }}
              />
            </div>
            {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
              <div className="input-group pricing-input">
                <h3>{t('form.label.pricingElements')}</h3>
                <PricingElementsInput
                  key={consignment?.pricingElements}
                  pricingElements={consignment?.pricingElements}
                  onChange={(pricingElements) => {
                    const newConsignment = { ...consignment };
                    newConsignment.pricingElements = [...pricingElements];

                    this.onChange(newConsignment);
                  }}
                />
              </div>
            )}
          </>
        )}

        {!simpleForms && !flat && (
          <>
            <FormInput
              type="textarea"
              parentName="consignmentform"
              label="form.label.remarks"
              className="small"
              setHasChanged={setHasChanged}
              value={consignment?.remarks}
              onChange={(event) => {
                const newConsignment = { ...consignment };
                newConsignment.remarks = event.target.value;

                this.onChange(newConsignment);
              }}
            />
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleSubmit(e);
                }}
                value={t('form.save')}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}
export default withTranslation('translation')(ConsignmentForm);
