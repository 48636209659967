import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import BusinessContainer from '@containers/business/BusinessContainer';

import ContactPersonView from '../general/ContactPersonView';

class CustomerView extends Component {
  render() {
    const { t, customer, addToStack } = this.props;
    if (!customer) return null;
    return (
      <div>
        {customer.business ? (
          <span
            className="customer-view as-link"
            onClick={(e) => {
              addToStack({
                name: customer.business.name,
                className: 'lightgray',
                component: <BusinessContainer business={customer.business} />,
              });
            }}
          >
            {customer.business.name}
          </span>
        ) : null}
        {customer.contactPerson && (
          <>
            {' '}
            <span
              className="customer-view as-link"
              onClick={(e) => {
                addToStack({
                  name: customer.contactPerson,
                  className: 'lightgray',
                  component: <ContactPersonView contactPerson={customer.contactPerson} />,
                });
              }}
            >
              ({customer.contactPerson.firstName} {customer.contactPerson.lastName})
            </span>
          </>
        )}
        {customer.customer && (
          <div>
            <b>{`${t('transportOrder.customer')} ${t('of')} ${customer.business.name}`}: </b>
            <CustomerView
              {...this.props}
              customer={customer.customer}
              addToStack={addToStack}
            />{' '}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(CustomerView);
