import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import Select from 'react-select';
import ReactSVG from 'react-svg';

import moment from 'moment';

import { i18n, store } from '@/index';

import EntityInput from '@uiinputs/EntityInput';
import FormInput from '@uiinputs/FormInput';

import { uuidv4 } from '@utils/commonUtils';
import { defaultDateFormat, momentFormatWithoutLiterals } from '@utils/dateUtils';

export default class FinancialLineInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateRangePicker: {
        line: null,
        isOpen: false,
      },
      isOpenDatePicker: false,
    };
  }

  pricingCategoriesOptions = () => {
    const { variables } = this.props;
    return variables?.pricingCategories?.length
      ? variables.pricingCategories.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      : [];
  };

  amountInputChange(event, action, value = null) {
    let total = 0;
    let subTotal = 0;
    let taxAmount = 0;
    const { target } = event;
    const { changeValue, form, variables, formType } = this.props;

    const arr = {
      ...form,
      tax: action === 'inclusiveOfTax' ? { ...form.tax, inclusiveOfTax: value } : form.tax,
      lines: form.lines.map((item) =>
        (item?.nonce || item.id) !== (value?.nonce || value?.id)
          ? item
          : action === 'price'
          ? {
              ...item,
              value: { ...item.value, amount: parseFloat(target.value) },
            }
          : action === 'quantity'
          ? {
              ...item,
              quantity: parseFloat(target.value),
            }
          : action === 'entityType'
          ? {
              ...item,
              entityType: target.value,
            }
          : action === 'entityId'
          ? {
              ...item,
              entityId: target.value,
            }
          : action === 'tax'
          ? {
              ...item,
              value: {
                ...item.value,
                taxRate: event.taxRate,
              },
              tax: event.taxRate,
            }
          : item
      ),
    };

    const inclusiveOfTax = arr.tax.inclusiveOfTax;

    arr.lines.forEach((item) => {
      if (item.quantity && item?.value?.amount) {
        const taxRate = variables?.taxRates.filter((d) => d.id === item.value?.taxRate?.id)[0] || {
          percentage: 0,
        };

        if (inclusiveOfTax) {
          const rowSubtotal =
            (parseFloat(item.quantity) * parseFloat(item.value.amount)) /
            (((taxRate?.percentage || 0) + 100) / 100);
          const rowTaxamount = rowSubtotal * ((taxRate?.percentage || 0) / 100);
          subTotal += rowSubtotal;
          taxAmount += rowTaxamount;

          total += rowSubtotal + rowTaxamount;
        } else {
          const rowSubtotal = parseFloat(item.quantity) * parseFloat(item.value.amount);
          const rowTaxamount = rowSubtotal * ((taxRate?.percentage || 0) / 100);

          subTotal += rowSubtotal;
          taxAmount += rowTaxamount;

          total += rowSubtotal + rowTaxamount;
        }
      }
    });

    return changeValue(formType, {
      ...arr,
      tax: {
        ...arr.tax,
        amount: parseFloat(taxAmount).toFixed(2),
      },
      total: {
        ...arr.total,
        amount: parseFloat(total).toFixed(2),
      },
      subTotal: { ...arr.subTotal, amount: parseFloat(subTotal).toFixed(2) },
    });
  }

  currenciesOptions = () => {
    const { currencies } = this.props;
    return currencies
      ? currencies.map((item) => ({
          label: item.sign + ' - ' + item.name,
          value: item.id,
        }))
      : [];
  };

  taxRateOptions = () => {
    const { variables } = this.props;
    return variables?.taxRates?.length > 0
      ? variables.taxRates.map((tax) => ({ label: tax.name, value: tax.id }))
      : [];
  };

  pricingCategoriesOptions = () => {
    const { variables } = this.props;
    return variables?.pricingCategories?.length
      ? variables.pricingCategories.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      : [];
  };

  inputChange(event, payload = null) {
    const target = event.target;
    const { changeValue, form, formType } = this.props;
    switch (payload?.action) {
      case 'vat':
        return changeValue(formType, {
          ...form,
          tax: { ...form.tax, inclusiveOfTax: payload.value },
        });

      case 'set-input':
        return changeValue(formType, {
          ...form,
          [target.name]: target.value,
        });

      case 'set-item-input':
        return changeValue(formType, {
          ...form,
          lines: form.lines.map((item) =>
            item.nonce === payload.nonce
              ? {
                  ...item,
                  [target.name]: target.value,
                }
              : item
          ),
        });
    }
  }

  dateRangePicker = (line) => {
    const { dateRangePicker } = this.state;
    const { form, formType, changeValue } = this.props;

    return (
      <div className="model date-picker-modal-overlay">
        <div className="model-content date-picker-modal-content bg-white rounded text-center">
          <div className="d-flex-space-between">
            <div />
            <div
              className="circle-icon-primary circle-md svg-md"
              onClick={() =>
                this.setState({
                  dateRangePicker: {
                    line: null,
                    isOpen: false,
                  },
                })
              }
            >
              <ReactSVG src="/icons/cross.svg" />
            </div>
          </div>
          <DatePicker
            selected={dateRangePicker.start || null}
            startDate={dateRangePicker.start || null}
            endDate={dateRangePicker.end || null}
            showWeekNumbers={true}
            locale={i18n.language}
            selectsRange={true}
            inline
            dateFormat={momentFormatWithoutLiterals(
              store.getState().settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY'
            )}
            onChange={(dates) => {
              const [start, end] = dates;
              if (start !== null && end !== null) {
                changeValue(formType, {
                  ...form,
                  lines: form.lines.map((item) =>
                    (item?.nonce || item.id) === (line?.nonce || line.id)
                      ? {
                          ...item,
                          startDateTime: moment(start),
                          endDateTime: moment(end),
                        }
                      : item
                  ),
                });
                this.setState({
                  dateRangePicker: {
                    line: null,
                    isOpen: false,
                    start,
                    end,
                  },
                });
              } else {
                this.setState({
                  dateRangePicker: {
                    ...dateRangePicker,
                    start,
                    end,
                  },
                });
              }
            }}
          />
        </div>
      </div>
    );
  };

  handleEntityChange = (e, item, form, key) => {
    const { formType, changeValue } = this.props;

    const updateItem = (currItem, currForm, newValue) => {
      const newForm = { ...currForm };

      const updatedLines = [...newForm.lines].filter((line) => {
        return currItem.nonce !== line.nonce || line.id !== currItem.id;
      });

      updatedLines.push({
        ...currItem,
        [key]: newValue,
      });

      newForm.lines = [...updatedLines].sort((a, b) => a.sequenceNr - b.sequenceNr);
      changeValue(formType, newForm);
    };

    if (!item?.[key] && e.value) {
      const response = this.amountInputChange({ target: { value: e.value } }, key, item);

      const updatedItem = response?.value?.lines?.find((line) => {
        return item?.nonce === line.nonce && line.id === item.id;
      });

      updateItem(updatedItem, { ...response.value }, e.value);
    } else {
      updateItem(item, form, e.value);
    }
  };

  render() {
    const { t, form, variables, changeValue, formType, attributeSelection } = this.props;
    const { dateRangePicker } = this.state;

    return (
      <>
        {dateRangePicker.isOpen &&
          dateRangePicker.line &&
          this.dateRangePicker(dateRangePicker.line)}
        <div className="quote-form">
          <div className="d-grid gap-10 quote-2fr-1fr text-left border-bottom mb-2">
            <div className="quote-form-labels d-grid quote-3fr">
              <p>{t('quote.quantity')}</p>
              <p>{t('quote.price')}</p>
              <p>{t('quote.total')}</p>
            </div>
            <div className="quote-form-labels">
              <p>{t('quote.vat.category')}</p>
            </div>
          </div>

          {form?.lines?.map((item, index) => {
            const taxRate = variables?.taxRates.filter((d) => d.id === item.value?.taxRate?.id)[0] || {
              percentage: 0,
            };

            return (
              <div key={item.id + item.nonce}>
                <div className="d-flex justify-content-end gap-5 mb-2">
                  {form.lines.length !== 1 && (
                    <div
                      className="circle-icon-primary circle-sm svg-md"
                      onClick={(e) =>
                        changeValue(formType, {
                          ...form,
                          lines: form.lines.filter((line) => line.nonce !== item.nonce),
                        })
                      }
                    >
                      <ReactSVG src="/icons/minus.svg" />
                    </div>
                  )}
                  {form.lines.length - 1 === index && (
                    <div
                      className="circle-icon-primary circle-sm svg-md"
                      onClick={(e) =>
                        changeValue(formType, {
                          ...form,
                          lines: [
                            ...form.lines,
                            {
                              ...this.itemObject,
                              nonce: uuidv4(),
                              sequenceNr: index + 1,
                            },
                          ],
                        })
                      }
                    >
                      <ReactSVG src="/icons/plus.svg" />
                    </div>
                  )}
                </div>
                <div className="d-grid gap-10 quote-2fr-1fr text-left">
                  <div className="quote-form-group">
                    <div className="d-grid gap-10 quote-3fr mb-15">
                      <FormInput
                        name="quantity"
                        type="number"
                        wrapperClass="no-margin-top"
                        label="form.label.quantity"
                        value={item?.quantity || ''}
                        onChange={(e) => {
                          this.amountInputChange(e, 'quantity', item);
                        }}
                      />

                      <FormInput
                        name="price"
                        type="number"
                        wrapperClass="no-margin-top"
                        label="form.label.price"
                        value={item?.value?.amount || ''}
                        onChange={(e) => {
                          this.amountInputChange(e, 'price', item);
                        }}
                      />

                      <FormInput
                        name="total"
                        wrapperClass="no-margin-top"
                        label="form.label.total"
                        value={
                          item?.quantity && item?.value?.amount
                            ? form?.tax?.inclusiveOfTax
                              ? parseFloat(item?.quantity) * parseFloat(item?.value?.amount)
                              : parseFloat(item?.quantity) *
                                parseFloat(item?.value?.amount) *
                                (parseFloat((taxRate?.percentage || 0) + 100) / 100)
                            : 0
                        }
                        isDisabled={true}
                        type="number"
                      />
                    </div>
                    <div className="quote-description">
                      <FormInput
                        type="textarea"
                        wrapperClass="no-margin-top"
                        className="tiny quote-description-field"
                        name="description"
                        value={item?.description || ''}
                        onChange={(e) =>
                          this.inputChange(e, {
                            action: 'set-item-input',
                            nonce: item?.nonce || item?.id,
                          })
                        }
                        placeholder={t('form.label.description')}
                      />
                      <div className="quote-description-bottom">
                        {t('form.label.period')}:{' '}
                        <span
                          className="text-underline quote-period"
                          onClick={() =>
                            this.setState({
                              dateRangePicker: {
                                isOpen: true,
                                line: item,
                              },
                              isOpenDatePicker: false,
                            })
                          }
                        >
                          {item?.startDateTime && item?.endDateTime
                            ? defaultDateFormat(item?.startDateTime, this.props?.dateFormat) +
                              ` ${t('to')} ` +
                              defaultDateFormat(item?.endDateTime, this.props?.dateFormat)
                            : t('not selected')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="quote-form-labels input-group no-margin-top">
                    <Select
                      options={
                        variables?.taxRates
                          ? variables.taxRates.map((tax) => ({
                              label: tax.name,
                              value: tax.id,
                              taxRate: tax,
                            }))
                          : null
                      }
                      value={
                        this.taxRateOptions().filter(
                          (d) => d.value === item?.value?.taxRate?.id
                        )?.[0]
                      }
                      placeholder={t('quote.select.taxRate')}
                      onChange={(event) => this.amountInputChange(event, 'tax', item)}
                    />
                    <Select
                      className="my-10"
                      options={
                        variables?.pricingCategories
                          ? variables.pricingCategories.map((category) => ({
                              label: category.name,
                              value: category.id,
                              pricingCategory: category,
                            }))
                          : null
                      }
                      value={
                        this.pricingCategoriesOptions() && item?.pricingCategory?.id
                          ? this.pricingCategoriesOptions().filter(
                              (d) => d.value === item?.pricingCategory.id
                            )
                          : []
                      }
                      placeholder={t('quote.select.pricingCategory')}
                      onChange={(event) => {
                        function updatePricingCategory(currItem, currForm) {
                          const newForm = { ...currForm };
                          const newLines = [...newForm.lines];

                          const updatedLines = newLines.filter((line) => {
                            return currItem?.nonce !== line.nonce || line?.id !== currItem.id;
                          });

                          updatedLines.push({
                            ...currItem,
                            pricingCategory: event.pricingCategory,
                          });

                          newForm.lines = updatedLines.sort((a, b) => a.sequenceNr - b.sequenceNr);
                          changeValue(formType, newForm);
                        }

                        if (!item?.value?.amount && event.pricingCategory?.defaultValue) {
                          const response = this.amountInputChange(
                            { target: { value: event.pricingCategory?.defaultValue } },
                            'price',
                            item
                          );

                          const updatedItem = response?.value?.lines?.find(
                            (line) => item?.nonce === line.nonce && line?.id === item.id
                          );

                          updatePricingCategory(updatedItem, { ...response.value });
                        } else {
                          updatePricingCategory(item, form);
                        }
                      }}
                    />
                    {attributeSelection && (
                      <EntityInput
                        {...this.props}
                        entities={[
                          'vehicle',
                          'transportOrder',
                          'trip',
                          'consignment',
                          'transportEquipment',
                          'chauffeur',
                          'capacityContract',
                        ]}
                        entityId={item?.entityId}
                        entityType={item?.entityType}
                        onEntityTypeSelection={(e) =>
                          this.handleEntityChange(e, item, form, 'entityType')
                        }
                        onEntitySelection={(e) =>
                          this.handleEntityChange({ value: e }, item, form, 'entityId')
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          <div className="d-grid quote-2fr-1fr ">
            <div className="d-flex gap-10 justify-content-between align-items-center">
              <div className="d-flex gap-10 align-items-center"></div>
              <div className="quote-total mt-30 text-right">
                <h3 className="m-0 font-default text-darkgray">
                  {t('quote.subtotal')}:{' '}
                  {`${form?.currency?.sign || '€'} ${parseFloat(form?.subTotal.amount).toFixed(2)}`}
                </h3>
                <h3 className="my-10 font-default text-darkgray">
                  {t('quote.vat')}:{' '}
                  {`${form?.currency?.sign || '€'} ${parseFloat(form?.tax.amount).toFixed(2)}`}
                </h3>
                <h2 className="m-0 font-default">
                  {t('quote.total')}:{' '}
                  {`${form?.currency?.sign || '€'} ${parseFloat(form?.total.amount).toFixed(2)}`}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
