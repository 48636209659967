import React, { Component, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import ReactSVG from 'react-svg';

import ChauffeurActivitiesTimelineContainer from '@containers/chauffeur/ChauffeurActivitiesTimelineContainer';
import UpdateChauffeurContainer from '@containers/chauffeur/crud/UpdateChauffeurContainer';
import EntityTodosContainer from '@containers/todo/EntityTodosContainer';

import Collapsible from '@uicomponents/Collapsible';
import Loader from '@uicomponents/Loader';

import ContactDetailsView from '../general/ContactDetailsView';
import NotesView from '../note/NotesView';
import InformationComponent from '../ui/components/InformationComponent';
import DrivingTimes from './DrivingTimes';

const ComplaintsContainer = lazy(() => import('@containers/complaint/ComplaintsContainer'));
const CertificatesContainer = lazy(() => import('@containers/certificate/CertificatesContainer'));
const BansContainer = lazy(() => import('@containers/ban/BansContainer'));
const CardsContainer = lazy(() => import('@containers/card/CardsContainer'));

class ChauffeurView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'chauffeur',
    };
  }

  render() {
    const { tab } = this.state;
    const { t, chauffeur, isFetchingChauffeur, onChange, addToStack } = this.props;

    if (!chauffeur || isFetchingChauffeur) {
      return <Loader />;
    }

    const fieldsToRender = [
      { name: 'type', type: 'type', entityType: 'chauffeur' },
      { name: 'dateOfBirth', type: 'date', entityType: 'chauffeur' },
      { name: 'birthPlace', type: 'text', entityType: 'chauffeur' },
      { name: 'nationality', type: 'text', entityType: 'chauffeur' },
    ];
    return (
      <div className="chauffeur-view">
        <div className="flex-container justify-between no-wrap">
          <div>
            <div className="flex-container justify-between">
              <h1 className="no-margin-bottom no-margin-top with-flair">
                {chauffeur.firstName} {chauffeur.lastName}{' '}
                <span>{t(`chauffeurType.${chauffeur.type}`)}</span>
              </h1>
            </div>
            <p className="no-margin-top">#{chauffeur.id}</p>
          </div>
          <div className="self-center flex-container action-icons">
            <div
              className="action-icon"
              onClick={(e) => {
                e.preventDefault();
                addToStack({
                  name: t('chauffeur.update.header'),
                  component: (
                    <UpdateChauffeurContainer
                      chauffeur={{ ...chauffeur }}
                      callback={(result) => {
                        onChange('chauffeur', result.chauffeur || {});
                      }}
                    />
                  ),
                });
              }}
            >
              <ReactSVG src="/icons/pencil.svg" />
            </div>
          </div>
        </div>

        <ScrollContainer
          verticle={false}
          hideScrollbars={false}
          className="align-items-center d-flex gap-10 tab-links-container my-1"
        >
          <div
            className={`tab-links${tab === 'chauffeur' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'chauffeur' })}
          >
            {t('chauffeurs.header')}
          </div>
          <div
            className={`tab-links${tab === 'bans' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'bans' })}
          >
            {t('bans.header')}
          </div>
          <div
            className={`tab-links${tab === 'complaints' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'complaints' })}
          >
            {t('complaints.header')}
          </div>
          <div
            className={`tab-links${tab === 'cards' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'cards' })}
          >
            {t('cards.header')}
          </div>
          <div
            className={`tab-links${tab === 'certificates' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'certificates' })}
          >
            {t('certificates.header')}
          </div>
          {this.props.platform?.features?.filter((feature) => feature.name === 'todo').length >
            0 && (
            <div
              className={`tab-links${tab === 'todos' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'todos' })}
            >
              {t('todos.header')}
            </div>
          )}
        </ScrollContainer>

        <section className={`chauffeur-view__box activity${tab === 'chauffeur' ? ' active' : ''}`}>
          {tab === 'chauffeur' ? (
            <>
              <InformationComponent entity={chauffeur} fields={fieldsToRender} />

              <section>
                <DrivingTimes chauffeur={chauffeur} />
              </section>
              <section className="margin-top">
                <ChauffeurActivitiesTimelineContainer chauffeur={chauffeur} />
              </section>
              <section className="margin-top">
                <Collapsible
                  name={`${t('notes.header')}${
                    chauffeur.notes?.length > 0 ? ` (${chauffeur.notes?.length})` : ''
                  }`}
                  className="collapsible-element"
                  defaultIsOpen={false}
                >
                  <NotesView notes={chauffeur.notes} />
                </Collapsible>
                <Collapsible
                  name={`${t('contactDetails.header')}${
                    chauffeur.contactDetails.length > 0
                      ? ` (${chauffeur.contactDetails.length})`
                      : ''
                  }`}
                  className="collapsible-element"
                  defaultIsOpen={false}
                >
                  <ContactDetailsView contactDetails={chauffeur.contactDetails} />
                </Collapsible>
              </section>
            </>
          ) : null}
        </section>

        <section className={`chauffeur-view__box activity${tab === 'bans' ? ' active' : ''}`}>
          {tab === 'bans' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <BansContainer entityType="chauffeur" entity={chauffeur} />
            </Suspense>
          ) : null}
        </section>

        <section className={`chauffeur-view__box activity${tab === 'complaints' ? ' active' : ''}`}>
          {tab === 'complaints' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <ComplaintsContainer entityType="chauffeur" entity={chauffeur} />
            </Suspense>
          ) : null}
        </section>

        <section className={`chauffeur-view__box activity${tab === 'cards' ? ' active' : ''}`}>
          {tab === 'cards' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <CardsContainer cardChauffeur={chauffeur} />
            </Suspense>
          ) : null}
        </section>

        <section
          className={`chauffeur-view__box activity${tab === 'certificates' ? ' active' : ''}`}
        >
          {tab === 'certificates' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <CertificatesContainer certificateChauffeur={chauffeur} />
            </Suspense>
          ) : null}
        </section>

        <section className={`chauffeur-view__box activity${tab === 'todos' ? ' active' : ''}`}>
          {tab === 'todos' ? (
            <EntityTodosContainer
              {...this.props}
              relatedEntityType="chauffeur"
              relatedEntity={chauffeur}
            />
          ) : null}
        </section>
      </div>
    );
  }
}
export default withTranslation('translation')(ChauffeurView);
