import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import { defaultDateTimeFormat } from '@utils/dateUtils';

class TransportEquipmentLongCard extends Component {
  render() {
    const { t, transportEquipment, onClick, onRemove } = this.props;

    return (
      <div
        className="vehicle-transportEquipments__transportEquipment"
        key={`vehicle.transportEquipment-${transportEquipment.id}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          onClick?.(transportEquipment);
        }}
      >
        <div className="flex-container justify-between">
          <div className="vehicle-transportEquipments__name">
            {transportEquipment.name}
            {transportEquipment.equipmentType && (
              <span className="vehicle-transportEquipments__type">
                {t(`equipmentType.${transportEquipment.equipmentType}`)}
              </span>
            )}
          </div>
          <div className="">
            <button
              type="button"
              onClick={() => {
                onRemove?.(transportEquipment);
              }}
            >
              {t('form.detach')}
            </button>
          </div>
        </div>
        <div className="vehicle-transportEquipments__information">
          <div className="vehicle-transportEquipments__information info-field">
            <span>{t('transportEquipment.licensePlate')}: </span> {transportEquipment.licensePlate}
          </div>
          {transportEquipment.vin && (
            <div className="vehicle-transportEquipments__information info-field">
              <span>{t('transportEquipment.vin')}: </span> {transportEquipment.vin}
            </div>
          )}
          {transportEquipment.equipmentId && (
            <div className="vehicle-transportEquipments__information info-field">
              <span>{t('transportEquipment.equipmentId')}: </span> {transportEquipment.equipmentId}
            </div>
          )}
        </div>
        <div className="vehicle-transportEquipments__date">
          {defaultDateTimeFormat(transportEquipment.createdAt, dateFormat)}
        </div>
      </div>
    );
  }
}
export default withTranslation('translation')(TransportEquipmentLongCard);
