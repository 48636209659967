import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreatePricingVariableIndexContainer from '@containers/pricingVariableIndex/crud/CreatePricingVariableIndexContainer';

import * as navigationActions from '@actions/navigationActions';
import * as pricingVariableIndexActions from '@actions/pricingVariableIndexActions';

let searchTimeout = null;

export const transformPricingVariableIndexToValue = (pricingVariableIndex) => ({
  value: pricingVariableIndex.id,
  label: pricingVariableIndex.name,
  id: pricingVariableIndex.id,
  pricingVariableIndex: pricingVariableIndex,
});

class PricingVariableIndexInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pricingVariableIndex: this.props.pricingVariableIndex
        ? transformPricingVariableIndexToValue(this.props.pricingVariableIndex)
        : null,
    };
  }

  onChange = (newPricingVariableIndex) => {
    this.setState({
      pricingVariableIndex: newPricingVariableIndex,
    });
    const { onChange } = this.props;
    onChange &&
      onChange(
        newPricingVariableIndex
          ? newPricingVariableIndex.pricingVariableIndex
          : null
      );
  };

  render() {
    const {
      t,
      isFetchingPricingVariableIndexs,
      searchPricingVariableIndexes,
      addToStack,
    } = this.props;
    const { pricingVariableIndex } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.pricingVariableIndex')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) =>
            t('form.addCreatable', { value: inputValue })
          }
          isLoading={isFetchingPricingVariableIndexs}
          isDisabled={isFetchingPricingVariableIndexs}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchPricingVariableIndexes({ query: inputValue }, 0, 40).then(
                (response) => {
                  callback(
                    response.pricingVariableIndexes.map(
                      (pricingVariableIndex) =>
                        transformPricingVariableIndexToValue(
                          pricingVariableIndex
                        )
                    )
                  );
                }
              );
            }, 400);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('pricingVariableIndex.new'),
              component: (
                <CreatePricingVariableIndexContainer
                  targetName={inputValue}
                  callback={(newPricingVariableIndex) => {
                    this.onChange(
                      transformPricingVariableIndexToValue(
                        newPricingVariableIndex
                      )
                    );
                  }}
                />
              ),
            });
          }}
          value={pricingVariableIndex}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.pricingVariableIndex,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(pricingVariableIndexActions.changeValue(name, value)),
    searchPricingVariableIndexes: (searchParameters, from, amount) =>
      dispatch(
        pricingVariableIndexActions.searchPricingVariableIndexes(
          searchParameters,
          from,
          amount
        )
      ),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(PricingVariableIndexInput));
