import React, { Component } from 'react';

import CreateMessageAutomationContainer from '@containers/messageAutomation/crud/CreateMessageAutomationContainer';

import { duplicateTemplate, getMessageAutomationTemplates } from '@api/messageAutomationApi';

export default class MessageAutomationSelectionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateView: false,
      groupedTemplates: {},
    };
  }

  componentDidMount() {
    getMessageAutomationTemplates()
      .then((response) => {
        this.setState({ groupedTemplates: this.categoryGrouping(response.messageAutomations) });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  categoryGrouping = (messageAutomationTemplates) => {
    return messageAutomationTemplates.reduce((grouped, messageAutomation) => {
      const { templateCategory } = messageAutomation;

      if (!grouped[templateCategory.toLowerCase()]) {
        grouped[templateCategory.toLowerCase()] = [];
      }

      grouped[templateCategory.toLowerCase()].push(messageAutomation);

      return grouped;
    }, {});
  };

  render() {
    const { groupedTemplates } = this.state;
    const { t, addToStack } = this.props;

    return (
      <div className="d-block" style={{ margin: 'auto' }}>
        <div
          className="messageAutomation-template-thumbnail mb-50"
          onClick={(e) => {
            e.preventDefault();
            addToStack({
              name: '',
              component: <CreateMessageAutomationContainer defaultForm={true} />,
            });
          }}
        >
          <img
            className="rounded-sm"
            src="/images/default-template.png"
            alt="ideal"
            width="120"
            height="150"
          />
          <h5 className="no-margin my-1"> {t('messageAutomation.createNew')}</h5>
        </div>
        {Object.keys(groupedTemplates).map((category) => {
          return (
            <div className="mb-50">
              <h3 className="text-capitalize text-left my-1">{category}</h3>
              <div className="flex-container gap-20 ">
                {groupedTemplates[category.toLowerCase()].map((template) => {
                  return (
                    <div className="messageAutomation-template-thumbnail">
                      <img
                        className="rounded-sm"
                        src="/images/default-template.png"
                        alt="ideal"
                        width="120"
                        height="150"
                        onClick={(e) => {
                          e.preventDefault();

                          duplicateTemplate(template).then((response) => {
                            addToStack({
                              name: response.messageAutomation?.name,
                              component: (
                                <CreateMessageAutomationContainer
                                  defaultForm={true}
                                  messageAutomationTemplate={{ ...response.messageAutomation, template: false }}
                                />
                              ),
                            });
                          });
                        }}
                      />
                      <h5 className="no-margin my-1">{template.name}</h5>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
