import React, { PureComponent } from 'react';

import { isMobile } from 'react-device-detect';
import { Link, NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { socket } from '@/SocketWrapper';
import { i18n } from '@/index';
import ChatsPopupContainer from '@containers/chat/ChatsPopupContainer';
import NotificationsContainer from '@containers/notification/NotificationsContainer';
import CreateTransportOrderContainer from '@containers/transportOrder/crud/CreateTransportOrderContainer';
import CreateTransportOrderFromFileContainer from '@containers/transportOrder/crud/CreateTransportOrderFromFileContainer';

import Banner from '@uicomponents/Banner';

import { hasParentNode } from '@utils/commonUtils';
import { createBasicKeybindings, inputElements } from '@utils/keyBindings';
import { truncate } from '@utils/stringUtils';
import { containsPermissionPart, isExternal } from '@utils/userUtils';

import AsideAccount from './AsideAccount';
import AsideCreateNew from './AsideCreateNew';
import AsideListItem from './AsideListItem';
import HeaderChatsView from './HeaderChatsView';
import HeaderKeyBindingsView from './HeaderKeyBindingsView';
import HeaderNotificationView from './HeaderNotificationView';
import HeaderSearch from './HeaderSearch';

const initialState = {
  notificationBox: false,
  messagesBox: false,
  accountBox: false,

  showKeyBindingsView: false,

  platformDropdownActive: false,
  infoDropdownActive: false,
};

export default class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      displayBanner: localStorage?.getItem('version-update-needed') ? true : false,
      mappedFeatures: this.props.platform?.features?.map((feature) => feature.name) || [],
    };
  }

  handleDocumentClick = (e) => {
    const { readNotifications, tutorial } = this.props;

    const target = e.target;
    const { notificationBox, messagesBox, accountBox, infoDropdownActive } = this.state;
    if (notificationBox || messagesBox || accountBox || infoDropdownActive) {
      if (!hasParentNode(target, 'menu') && !tutorial) {
        this.setState(initialState);
        if (notificationBox) readNotifications();
      }
    }
  };

  handleKeyBindings = (e) => {
    const { history, addToStack, popStack } = this.props;
    const { showKeyBindingsView } = this.state;

    createBasicKeybindings(e, history, addToStack, popStack);

    const activeElement = document.activeElement;
    if (inputElements.includes(activeElement.tagName)) return;

    if (e.which === 191 && e.key === '?') {
      this.setState({
        showKeyBindingsView: !showKeyBindingsView,
      });
    }
  };

  showDropdown = (element) => {
    var event;
    event = document.createEvent('MouseEvents');
    event.initMouseEvent('mousedown', true, true, window);
    element.dispatchEvent(event);
  };

  componentDidMount() {
    const {
      isAuthenticated,
      notifications,
      chats,
      getChats,
      chatsIsFetching,
      getNotifications,
      notificationsIsFetching,
    } = this.props;

    document.addEventListener('click', this.handleDocumentClick);
    document.addEventListener('keyup', this.handleKeyBindings);

    if (isAuthenticated && notifications.length < 1 && !notificationsIsFetching) getNotifications();
    if (isAuthenticated && chats.length < 1 && !chatsIsFetching) getChats();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener('keyup', this.handleKeyBindings);
  }

  render() {
    let beta =
      parseFloat(process.env.REACT_APP_VERSION) >= 1 || process.env.MODE === 'development'
        ? ''
        : 'beta';

    const {
      t,
      platformIsFetching,
      getPlatformById,
      platform,
      match,
      history,
      isAuthenticated,
      user,
      addToStack,
      popStack,
      notificationsUnread,
      unreadMessages,
      getChats,
      getNotifications,
      readNotifications,
      noHeader,
      stickyChat,
      settings,
    } = this.props;

    const {
      notificationBox,
      messagesBox,
      accountBox,
      showKeyBindingsView,
      platformDropdownActive,
      displayBanner,
      infoDropdownActive,
      mappedFeatures,
    } = this.state;

    if (noHeader || !platform || platformIsFetching) return null;

    if (isAuthenticated) {
      if (user.type === 'developer') {
        return (
          <header
            className={`header ${beta} ${accountBox ? 'show' : ''} ${
              displayBanner ? 'banner' : ''
            }`}
          >
            {displayBanner && <Banner {...this.props} />}
            <div className="container flex-container no-wrap justify-between">
              <div className="flex-container one">
                <Link to="/">
                  <img
                    className="logo"
                    src={platform.logo ? platform.logo.url : '/images/Transportial.png'}
                    alt={`${platform.name} logo`}
                  />
                </Link>
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                  <div className="link-menu">
                    <NavLink exact to="/developer">
                      {t('menu.home')}
                    </NavLink>
                    <NavLink exact to="/developer/apps">
                      {t('menu.apps')}
                    </NavLink>
                    <a target="_blank" href="https://docs.transportial.com">
                      {t('menu.documentation')}
                    </a>
                  </div>

                  <div className="header-menu-icons menu">
                    <div
                      className="icon notifications"
                      data-content={notificationsUnread}
                      onClick={() => {
                        if (!notificationBox) getNotifications();
                        if (notificationBox) readNotifications();

                        this.setState({
                          notificationBox: !notificationBox,
                          accountBox: false,
                          messagesBox: false,
                          infoDropdownActive: false,
                        });
                      }}
                    >
                      <span>
                        <ReactSVG src="/icons/notification.svg" />
                      </span>
                      <div className={'box ' + (notificationBox ? 'show' : '')}>
                        <div className="n_header">
                          <span>{t('notifications.header')}</span>
                          <div className="actions">
                            <Link
                              to={'/'}
                              onClick={(e) => {
                                e.preventDefault();
                                addToStack({
                                  name: t('notifications.header'),
                                  component: (
                                    <NotificationsContainer
                                      onNavigateTo={(url) => {
                                        popStack();
                                        history.push(url);
                                      }}
                                    />
                                  ),
                                });
                                this.setState({
                                  ...this.state,
                                  ...initialState,
                                });
                              }}
                            >
                              {t('details.header')}
                            </Link>
                          </div>
                        </div>
                        <HeaderNotificationView
                          {...this.props}
                          goToPage={(e) => {
                            e.preventDefault();
                            addToStack({
                              name: t('notifications.header'),
                              component: (
                                <NotificationsContainer
                                  onNavigateTo={(url) => {
                                    popStack();
                                    history.push(url);
                                  }}
                                />
                              ),
                            });
                            this.setState({ ...this.state, ...initialState });
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="icon"
                      onClick={() => {
                        this.setState({
                          accountBox: !accountBox,
                          notificationBox: false,
                          messagesBox: false,
                          infoDropdownActive: false,
                        });
                      }}
                    >
                      <span>
                        <ReactSVG src="/icons/menu.svg" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <aside className={accountBox ? 'show' : ''}>
                <div className="aside-content-box scrollable">
                  <AsideAccount
                    {...this.props}
                    accountBox={accountBox}
                    updateState={(newState) => this.setState(newState)}
                  />
                  <hr />
                  <AsideListItem
                    identifier="publishers"
                    label={t('menu.publishers')}
                    navigate={'/developer/publishers'}
                  />
                  <hr />
                  <AsideListItem
                    label={t('menu.settings')}
                    navigate={'/settings'}
                    icon={'/icons/settings.svg'}
                  />

                  <AsideListItem
                    label={t('menu.logout')}
                    navigate={'/auth/logout'}
                    icon={'/icons/logout.svg'}
                  />
                </div>
              </aside>
            </div>
          </header>
        );
      }
      return (
        <>
          {stickyChat && !match?.path?.includes('messages') && !isMobile && <ChatsPopupContainer />}
          <header
            className={`header ${beta} ${accountBox ? 'show' : ''} ${
              displayBanner ? 'banner' : ''
            }`}
          >
            <HeaderKeyBindingsView
              user={user}
              isActive={showKeyBindingsView}
              onClose={(e) => {
                this.setState(initialState);
              }}
            />
            {displayBanner && <Banner {...this.props} />}
            <div className="container flex-container no-wrap justify-between">
              <div className="flex-container one logo-search-wrapper">
                {user?.platforms?.length > 1 && !platform.isTransportialBranding ? null : (
                  <Link to="/">
                    <img
                      className="logo"
                      src={platform.logo ? platform.logo.url : '/images/Transportial.png'}
                      alt={`${platform.name} logo`}
                    />
                  </Link>
                )}
                {user?.platforms?.length > 1 && !platform.isTransportialBranding ? (
                  <div
                    className={`dropdown${platformDropdownActive ? ' active' : ''}`}
                    onClick={(e) =>
                      this.setState({
                        platformDropdownActive: !platformDropdownActive,
                      })
                    }
                  >
                    <div className={`item`}>
                      <img
                        src={
                          platform.logo && !platform.isTransportialBranding
                            ? platform.logo.url
                            : '/images/Transportial.svg'
                        }
                        alt="logo"
                      />
                      <div className="name">{truncate(platform.name, 15)}</div>
                      <div className="carrot">
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="items scrollable">
                      {user.platforms.map((platform) => {
                        return (
                          <div
                            className={`item${
                              platform.id === this.props.platform.id ? ' active' : ''
                            } `}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              getPlatformById(platform.id).then((response) => {
                                response?.platform?.translationSettings?.languagesLabels.forEach(
                                  (language) => {
                                    i18n.addResourceBundle(
                                      language.language,
                                      'translation',
                                      JSON.parse(language.labels || {})
                                    );
                                  }
                                );
                              });

                              socket.emit('platformChange', platform.id);
                            }}
                            key={platform.id}
                          >
                            <img
                              src={platform.logo ? platform.logo.url : '/images/Transportial.svg'}
                            />
                            <div className="name">{truncate(platform.name, 15)}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}

                <HeaderSearch {...this.props} />
              </div>
              <div className="d-flex justify-content-between top-menu">
                <div className="create-order-dropdown dropdown">
                  <div
                    className="active-item"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      addToStack({
                        name: t('transportOrder.new'),
                        fullWidth: settings?.userInterface?.formType === 'flat',
                        component: (
                          <CreateTransportOrderContainer
                            flat={settings?.userInterface?.formType === 'flat'}
                            onNavigateTo={(url) => {
                              popStack();
                              history.push(url);
                            }}
                          />
                        ),
                      });
                      this.setState(initialState);
                    }}
                  >
                    <div className="plus-icon">
                      <ReactSVG src="/icons/plus.svg" />
                    </div>
                    <div>{t('transportOrder.new')}</div>
                    <div className="chevron-down">
                      <ReactSVG src="/icons/arrow-down.svg" />
                    </div>
                  </div>
                  <div className="items">
                    <div
                      className="item"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        addToStack({
                          name: t('transportOrder.new'),
                          fulLWidth: settings?.userInterface?.formType === 'flat',
                          component: (
                            <CreateTransportOrderFromFileContainer
                              flat={settings?.userInterface?.formType === 'flat'}
                              onNavigateTo={(url) => {
                                popStack();
                                history.push(url);
                              }}
                            />
                          ),
                        });

                        this.setState(initialState);
                      }}
                    >
                      {t('menu.new.transportOrderFromFile')}
                    </div>
                  </div>
                </div>

                <div className="header-menu-icons menu">
                  <div
                    title={this.props.t('header.title.dashboard')}
                    className="icon home"
                    onClick={(e) => {
                      history.push('/dashboard');
                      popStack();
                    }}
                  >
                    <span>
                      <ReactSVG src="/icons/home.svg" />
                    </span>
                  </div>
                  <div
                    title={this.props.t('header.title.transportOrders')}
                    className="icon transport-orders"
                    onClick={(e) => {
                      history.push('/transportOrders');
                      popStack();
                    }}
                  >
                    <span>
                      <ReactSVG src="/icons/orders.svg" />
                    </span>
                  </div>
                  {!isExternal(user) && (
                    <div
                      title={this.props.t('header.title.messages')}
                      className="icon messages"
                      data-content={unreadMessages}
                      onClick={() => {
                        if (!messagesBox) getChats();

                        this.setState({
                          messagesBox: !messagesBox,
                          accountBox: false,
                          notificationBox: false,
                          infoDropdownActive: false,
                        });
                      }}
                    >
                      <span>
                        <ReactSVG src="/icons/messages.svg" />
                      </span>

                      <div className={'box ' + (messagesBox ? 'show' : '')}>
                        <div className="n_header">
                          <span>{t('chats.header')}</span>
                          <div className="actions">
                            <Link to="/messages/new">{t('chat.new')}</Link>
                            <Link to="/messages">{t('details.header')}</Link>
                          </div>
                        </div>
                        <HeaderChatsView {...this.props} />
                      </div>
                    </div>
                  )}
                  <div
                    title={this.props.t('header.title.tutorials')}
                    className="icon info"
                    onClick={() => {
                      this.setState({
                        infoDropdownActive: !infoDropdownActive,
                        messagesBox: false,
                        accountBox: false,
                        notificationBox: false,
                      });
                    }}
                  >
                    <span>
                      <ReactSVG src="/icons/question.svg" />
                    </span>
                    <div className={`dropdown-active box ${infoDropdownActive ? 'show' : ''}`}>
                      <div className="content-header">
                        <div
                          className="close-button"
                          onClick={(e) => {
                            e.preventDefault();
                            updateState({
                              infoDropdownActive: false,
                            });
                          }}
                        >
                          <ReactSVG src="/icons/cross.svg" />
                        </div>
                        <span className="title">{t('tutorials.header.title')}</span>
                        <span className="subtitle">{t('tutorials.header.subtitle')}</span>
                      </div>
                      <div className="content">
                        <div className="section">
                          <Link
                            className="dropdown-item heading"
                            to="/dashboard"
                            onClick={(e) => {
                              this.props.setTutorial('header-search');
                            }}
                          >
                            {t('tutorials.header.dashboard')}
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/dashboard/plan-board"
                            onClick={(e) => {
                              this.props.setTutorial('header-search');
                            }}
                          >
                            {t('tutorials.header.walkthrough')}
                          </Link>
                          {platform?.features &&
                            platform?.features
                              .map((feature) => feature.name)
                              .includes('tripPlanBoard') && (
                              <Link
                                className="dropdown-item"
                                to="/dashboard/plan-board"
                                onClick={(e) => {
                                  this.props.setTutorial('plan-board');
                                }}
                              >
                                {t('tutorials.header.planBoard')}
                              </Link>
                            )}
                          <Link
                            className="dropdown-item"
                            to="/dashboard/planner"
                            onClick={(e) => {
                              this.props.setTutorial('combination-planner');
                            }}
                          >
                            {t('tutorials.header.planner')}
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/dashboard/watchtower"
                            onClick={(e) => {
                              this.props.setTutorial('watch-tower');
                            }}
                          >
                            {t('tutorials.header.watchtower')}
                          </Link>
                        </div>
                        <hr />
                        <div className="section">
                          <span
                            className="dropdown-item heading"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.setTutorial('highlight-side-menu-create-order');
                            }}
                          >
                            {t('tutorials.header.productFeatures')}
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={(e) => {
                              this.props.setTutorial('highlight-side-menu-create-order');
                            }}
                          >
                            {t('tutorials.header.newOrder')}
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={(e) => {
                              this.props.setTutorial('highlight-create-trip-aside-view');
                            }}
                          >
                            {t('tutorials.header.newTrip')}
                          </span>
                          <span
                            className="dropdown-item"
                            onClick={(e) => {
                              this.props.setTutorial('highlight-side-menu-fleets-view');
                            }}
                          >
                            {t('tutorials.header.fleetManagement')}
                          </span>
                          {user?.role?.level >= 300 &&
                            platform?.features &&
                            platform?.features
                              .map((feature) => feature.name)
                              .includes('financial') && (
                              <span
                                className="dropdown-item"
                                onClick={(e) => {
                                  this.props.setTutorial('highlight-side-menu-pricing-view');
                                }}
                              >
                                {t('tutorials.header.pricing')}
                              </span>
                            )}
                        </div>
                        <hr />
                        <div className="section">
                          <span
                            className="dropdown-item heading disabled"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {t('tutorials.header.integrations')}
                          </span>
                        </div>
                        <hr />
                        <a className="support" href="mailto:info@transportial.com">
                          {t('tutorials.header.help')}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    title={this.props.t('header.title.notifications')}
                    className="icon notifications "
                    data-content={notificationsUnread}
                    onClick={() => {
                      if (!notificationBox) getNotifications();
                      if (notificationBox) readNotifications();

                      this.setState({
                        notificationBox: !notificationBox,
                        accountBox: false,
                        messagesBox: false,
                        infoDropdownActive: false,
                      });
                    }}
                  >
                    <span>
                      <ReactSVG src="/icons/notification.svg" />
                    </span>
                    <div className={'box ' + (notificationBox ? 'show' : '')}>
                      <div className="n_header">
                        <span>{t('notifications.header')}</span>
                        <div className="actions">
                          <Link
                            to={'/'}
                            onClick={(e) => {
                              e.preventDefault();
                              addToStack({
                                name: t('notifications.header'),
                                component: (
                                  <NotificationsContainer
                                    onNavigateTo={(url) => {
                                      popStack();
                                      history.push(url);
                                    }}
                                  />
                                ),
                              });
                              this.setState({
                                ...this.state,
                                ...initialState,
                              });
                            }}
                          >
                            {t('details.header')}
                          </Link>
                        </div>
                      </div>
                      <HeaderNotificationView
                        {...this.props}
                        goToPage={(e) => {
                          e.preventDefault();
                          addToStack({
                            name: t('notifications.header'),
                            component: (
                              <NotificationsContainer
                                onNavigateTo={(url) => {
                                  popStack();
                                  history.push(url);
                                }}
                              />
                            ),
                          });
                          this.setState({ ...this.state, ...initialState });
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="icon side-menu"
                    onClick={() => {
                      this.setState({
                        accountBox: !accountBox,
                        notificationBox: false,
                        messagesBox: false,
                        infoDropdownActive: false,
                      });
                    }}
                  >
                    <span>
                      <ReactSVG src="/icons/menu.svg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <aside className={accountBox ? 'show' : ''}>
              <div className="aside-content-box scrollable">
                <AsideAccount
                  {...this.props}
                  accountBox={accountBox}
                  updateState={(newState) => this.setState(newState)}
                />
                <AsideCreateNew
                  {...this.props}
                  updateState={(newState) => this.setState(newState)}
                  initialState={initialState}
                />
                <hr />
                {!isExternal(user) ? (
                  <>
                    {containsPermissionPart('location') && (
                      <AsideListItem
                        identifier="locations"
                        label={t('menu.locations')}
                        navigate={'/locations'}
                      />
                    )}

                    {containsPermissionPart('chauffeur') && (
                      <AsideListItem label={t('menu.chauffeurs')} navigate={'/chauffeurs'} />
                    )}

                    {containsPermissionPart('vehicle') && (
                      <AsideListItem
                        identifier="vehicles"
                        label={t('menu.vehicles')}
                        navigate={'/vehicles'}
                      />
                    )}

                    {containsPermissionPart('transportEquipment') && (
                      <AsideListItem
                        label={t('menu.transportEquipments')}
                        navigate={'/transportEquipments'}
                      />
                    )}

                    {containsPermissionPart('vehicleGroup') && (
                      <AsideListItem label={t('menu.vehicleGroups')} navigate={'/vehicleGroups'} />
                    )}

                    {containsPermissionPart('fleet') && (
                      <AsideListItem
                        identifier="fleets"
                        label={t('menu.fleets')}
                        navigate={'/fleets'}
                      />
                    )}

                    {containsPermissionPart('sensor') && (
                      <AsideListItem label={t('menu.sensors')} navigate={'/sensors'} />
                    )}

                    {containsPermissionPart('business') && (
                      <AsideListItem label={t('menu.businesses')} navigate={'/businesses'} />
                    )}

                    {user?.role?.level >= 300 &&
                    containsPermissionPart('pricing') &&
                    platform?.features.map((feature) => feature.name).includes('financial') ? (
                      <AsideListItem
                        identifier="pricing"
                        label={t('menu.pricings')}
                        navigate={'/pricings'}
                      />
                    ) : null}

                    {user?.role?.level >= 300 && containsPermissionPart('messageTemplate') && (
                      <AsideListItem
                        label={t('menu.messageTemplates')}
                        navigate={'/messageTemplates'}
                      />
                    )}

                    {user?.role?.level >= 300 && containsPermissionPart('contract') && (
                      <AsideListItem label={t('menu.contracts')} navigate={'/contracts'} />
                    )}

                    {user?.role?.level >= 300 && containsPermissionPart('boardComputers') && (
                      <AsideListItem
                        label={t('menu.boardComputers')}
                        navigate={'/boardComputers'}
                      />
                    )}

                    {user?.role?.level >= 300 && containsPermissionPart('terminal') && (
                      <AsideListItem label={t('menu.terminals')} navigate={'/terminals'} />
                    )}

                    {user?.role?.level >= 800 && containsPermissionPart('integration') && (
                      <AsideListItem label={t('menu.integrations')} navigate={'/integrations'} />
                    )}

                    {user?.role?.level >= 800 && containsPermissionPart('messageAutomation') && (
                      <AsideListItem
                        label={t('menu.messageAutomations')}
                        navigate={'/messageAutomations'}
                      />
                    )}

                    {user?.role?.level >= 800 && containsPermissionPart('scheduleRoutes') && (
                      <AsideListItem
                        label={t('menu.scheduleRoutes')}
                        navigate={'/scheduleRoutes'}
                      />
                    )}

                    {user?.role?.level >= 800 &&
                      containsPermissionPart('capacityContract') &&
                      platform?.features.map((feature) => feature.name).includes('capacity') && (
                        <AsideListItem
                          label={t('menu.capacityContracts')}
                          navigate={'/capacity/contracts'}
                        />
                      )}

                    {user?.role?.level >= 800 && containsPermissionPart('widget') && (
                      <AsideListItem label={t('menu.widgets')} navigate={'/widgets'} />
                    )}

                    {user?.role?.level >= 800 &&
                      containsPermissionPart('administration') &&
                      platform?.features.map((feature) => feature.name).includes('financial') && (
                        <AsideListItem
                          label={t('menu.administrations')}
                          navigate={'/administrations'}
                        />
                      )}

                    {user?.role?.level >= 800 && containsPermissionPart('team') && (
                      <AsideListItem label={t('menu.teams')} navigate={'/teams'} />
                    )}

                    {user?.role?.level >= 800 && containsPermissionPart('user') && (
                      <AsideListItem label={t('menu.users')} navigate={'/users'} />
                    )}
                    {user?.role?.level >= 800 && containsPermissionPart('settingsGroup') && (
                      <AsideListItem
                        label={t('menu.settingsGroups')}
                        navigate={'/settingsGroups'}
                      />
                    )}
                    {user?.role?.level >= 800 && containsPermissionPart('role') && (
                      <AsideListItem label={t('menu.roles')} navigate={'/roles'} />
                    )}

                    {user?.role?.level > 1000 && (
                      <>
                        <hr />
                        <AsideListItem label={t('menu.supportCenter')} navigate={'/support'} />
                        <AsideListItem label={t('menu.plans')} navigate={'/plans'} />
                        <AsideListItem label={t('menu.platforms')} navigate={'/platforms'} />
                        <AsideListItem
                          label={t('menu.messageAutomationTemplates')}
                          navigate={'/messageAutomation-templates'}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <AsideListItem label={t('menu.businesses')} navigate={'/businesses'} />
                  </>
                )}

                {/*----------------- footer items --------- */}
                <hr />
                <AsideListItem
                  label={t('menu.settings')}
                  navigate={'/settings'}
                  icon={'/icons/settings.svg'}
                />

                <AsideListItem
                  label={t('menu.showKeyBindings')}
                  icon={'/icons/keyboard.svg'}
                  onClick={() => {
                    this.setState({
                      showKeyBindingsView: true,
                      notificationBox: false,
                      messagesBox: false,
                      accountBox: false,
                      infoDropdownActive: false,
                    });
                  }}
                />

                <AsideListItem
                  label={t('menu.logout')}
                  navigate={'/auth/logout'}
                  icon={'/icons/logout.svg'}
                />
              </div>
            </aside>

            <div className="bottom-menu">
              <Link to="/" className={'section' + (match?.path === '/dashboard' ? ' active' : '')}>
                <ReactSVG src="/icons/home.svg" />
              </Link>
              <Link
                to="/transportOrders"
                className={'section' + (match?.path === '/transportOrders' ? ' active' : '')}
              >
                <ReactSVG src="/icons/orders.svg" />
              </Link>
              <Link
                to="/messages"
                className={'section' + (match?.path === '/messages' ? ' active' : '')}
                data-content={unreadMessages}
              >
                <ReactSVG src="/icons/messages.svg" />
              </Link>
              <Link
                to="/notifications"
                className={'section' + (match?.path === '/notifications' ? ' active' : '')}
                data-content={notificationsUnread}
              >
                <ReactSVG src="/icons/notification.svg" />
              </Link>
            </div>
          </header>
        </>
      );
    }

    return (
      <header className={`header clear ${beta}`}>
        <div className="container flex-container no-wrap justify-between">
          <div className="one"></div>
          <Link to="/" className="one">
            <img
              alt="logo"
              className="logo middle"
              src={platform.logo ? platform.logo.url : '/images/Transportial.png'}
            />
          </Link>
          <div className="header-menu-icons menu one flex-container justify-end">
            <Link to="/auth/login" className="button">
              {t('form.login')}
            </Link>
          </div>
        </div>
      </header>
    );
  }
}
