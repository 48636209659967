import React, { Component } from 'react';

import { isLight } from '@utils/colorUtils';

export default class Attributes extends Component {
  static defaultProps = {
    attributes: {},
  };
  render() {
    const { attributes } = this.props;

    
    return (
      <div className="tags-inline">
        {attributes?.map((attribute) => (
          <div
            className="tag tag-default"
            style={{
              background: attribute.color,
              color: isLight(attribute.color) ? 'black' : 'white',
            }}
          >
            {attribute.name}
          </div>
        ))}
      </div>
    );
  }
}
