import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import moment from 'moment';

import AdministrationInput from '@components/administration/crud/AdministrationInput';
import FinancialLineInput from '@components/administration/quote/crud/FinancialLineInput';
import ConsignmentsInput from '@components/consignment/crud/ConsignmentsInput';
import CustomerInput from '@components/customer/crud/CustomerInput';
import ContactDetailsInput from '@components/general/crud/ContactDetailsInput';
import NotesInput from '@components/note/crud/NotesInput';
import PricingInput from '@components/pricing/PricingInput';
import PricingElementsInput from '@components/pricing/crud/PricingElementsInput';
import TeamInput from '@components/team/TeamInput';
import DeliveryTermsSelector from '@components/transportOrder/DeliveryTermsSelector';

import Attachments from '@uicomponents/Attachments';
import Loader from '@uicomponents/Loader';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import Association from '@models/general/Association';

import { uuidv4 } from '@utils/commonUtils';
import { activateInputs, deactivateInputs } from '@utils/formUtils';
import { isExternal } from '@utils/userUtils';

export default class TransportOrderForm extends Component {
  static defaultProps = {
    flat: false,
  };
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      selectedPricing: null,
    };
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, quoteForm, formTransportOrder } = this.props;

    const newTransportOrder = { ...formTransportOrder };
    newTransportOrder.quote = formTransportOrder?.quote ? { ...quoteForm } : null;
    onSubmit?.(newTransportOrder);
  }

  onChange = (value, property) => {
    const { onChange, formTransportOrder } = this.props;
    const newTransportOrder = { ...formTransportOrder };
    newTransportOrder[property] = value;

    onChange?.('formTransportOrder', newTransportOrder, event);
  };

  quoteUpdate = (field) => {
    const { changeQuoteValue, quoteForm, formTransportOrder } = this.props;

    const newQuoteForm = { ...quoteForm };

    switch (field) {
      case 'customer':
        newQuoteForm.targetBusiness = formTransportOrder?.customer?.business;

      case 'administration':
        newQuoteForm.dueAt = moment().add(
          formTransportOrder?.administration?.dueDays || 14,
          'days'
        );
        newQuoteForm.administration = formTransportOrder?.administration;
      case 'value':
        newQuoteForm.currency = formTransportOrder?.value?.currency;
    }

    changeQuoteValue('quoteForm', newQuoteForm);
  };

  render() {
    const {
      t,
      flat,
      user,
      onChange,
      onSubmit,
      formTransportOrder,
      currencies,
      isFetchingCurrencies,
      platform,
      simpleForms,
      changeQuoteValue,
      quoteForm,
      setHasChanged,
    } = this.props;

    const { selectedPricing } = this.state;

    if (currencies.length < 1 && isFetchingCurrencies) {
      return <Loader />;
    }

    return (
      <div
        key={`${formTransportOrder.id}-${formTransportOrder.versionNumber}-${formTransportOrder.nonce}`}
      >
        <form className={`form transport-order-form active no-padding-top${flat ? ' small' : ''}`}>
          <div className={flat ? 'flex-container gap-20' : 'input-container'}>
            <div className={flat ? 'one text-inputs ' : 'text-inputs'}>
              {!simpleForms && (
                <>
                  <FormInput
                    type="text"
                    parentName="transportorderform"
                    wrapperClass="no-margin-top"
                    label="form.label.name"
                    required={true}
                    setHasChanged={setHasChanged}
                    value={formTransportOrder.name}
                    onChange={(event) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.name = event.target.value;

                      onChange('formTransportOrder', newTransportOrder, event);
                    }}
                  />
                  {this.validator.message(
                    t('form.label.name'),
                    formTransportOrder.name,
                    'required'
                  )}
                </>
              )}

              <div className="input-group">
                <CustomerInput
                  {...this.props}
                  key={`${formTransportOrder?.customer?.business?.id}`}
                  placeholder={`${t('form.label.selectCustomer')}*`}
                  simpleForms={simpleForms}
                  customer={formTransportOrder.customer ? { ...formTransportOrder.customer } : null}
                  onChange={(customer) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.customer = customer;

                    onChange('formTransportOrder', newTransportOrder);
                    if (formTransportOrder.quote) {
                      this.quoteUpdate('customer');
                    }
                  }}
                />
                {this.validator.message(
                  t('form.label.customer'),
                  formTransportOrder.customer,
                  'required'
                )}
              </div>
              <FormInput
                type="text"
                parentName="transportorderform"
                label="form.label.referenceDescription"
                setHasChanged={setHasChanged}
                value={formTransportOrder.referenceDescription}
                onChange={(event) => {
                  const newTransportOrder = { ...formTransportOrder };
                  newTransportOrder.referenceDescription = event.target.value;

                  onChange('formTransportOrder', newTransportOrder, event);
                }}
              />
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <DeliveryTermsSelector
                    key={formTransportOrder.deliveryTerms}
                    value={formTransportOrder.deliveryTerms}
                    defaultValue={platform.planningSettings?.defaultDeliveryTerms}
                    placeholder={`${t('form.label.selectDeliveryTerms')}*`}
                    onChange={(event) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.deliveryTerms = event.value;

                      onChange('formTransportOrder', newTransportOrder, event);
                    }}
                  />
                </div>
                {this.validator.message(
                  t('form.label.deliveryTerms'),
                  formTransportOrder.deliveryTerms,
                  'required'
                )}
              </div>
              {!isExternal(user) && (
                <>
                  <div className="input-group">
                    <div className="input-group no-margin-top">
                      <TeamInput
                        key={formTransportOrder?.team?.id}
                        value={formTransportOrder.team}
                        placeholder={t('form.label.selectTeam')}
                        onChange={(newTeam) => {
                          const newTransportOrder = { ...formTransportOrder };
                          newTransportOrder.team = { ...newTeam };

                          onChange('formTransportOrder', newTransportOrder);
                        }}
                      />
                    </div>
                  </div>
                  {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
                    <div className="input-group">
                      <AdministrationInput
                        key={formTransportOrder.administration?.id || ''}
                        value={formTransportOrder.administration}
                        onChange={(newAdministration) => {
                          const newTransportOrder = { ...formTransportOrder };
                          newTransportOrder.administration = newAdministration;

                          onChange('formTransportOrder', newTransportOrder, event);
                          if (formTransportOrder.quote) {
                            this.quoteUpdate('administration');
                          }
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={flat ? 'one' : ''}>
              {!simpleForms && (
                <>
                  <FormInput
                    type="textarea"
                    parentName="transportorderform"
                    className="small"
                    label="form.label.description"
                    setHasChanged={setHasChanged}
                    value={formTransportOrder.description}
                    onChange={(event) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.description = event.target.value;

                      onChange('formTransportOrder', newTransportOrder, event);
                    }}
                  />
                </>
              )}

              <div className="input-group upload-files">
                <h3 className="no-margin-top no-margin-bottom">{t('form.label.documents')}</h3>
                <Attachments
                  {...this.props}
                  key={formTransportOrder?.documents}
                  files={formTransportOrder?.documents?.map((document) => {
                    return document.entity.file;
                  })}
                  onChange={(files) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.documents = files.map((file) => {
                      const newFile = { ...file };
                      return new Association('inline', {
                        name: newFile.originalName,
                        mimeType: newFile.mimeType,
                        content: {
                          contentType: 'uri',
                          uri: newFile.url,
                        },
                        file: newFile,
                      });
                    });
                    onChange('formTransportOrder', newTransportOrder);
                  }}
                />
              </div>
              {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
                <div className="input-group pricing-input">
                  <h3>{t('form.label.pricingElements')}</h3>
                  <PricingElementsInput
                    key={formTransportOrder?.pricingElements}
                    pricingElements={formTransportOrder?.pricingElements}
                    onChange={(pricingElements) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.pricingElements = [...pricingElements];

                      onChange('formTransportOrder', newTransportOrder, event);
                    }}
                  />
                </div>
              )}
            </div>

            <div className="input-group consignments-input">
              <h3 className="no-margin-top">{t('form.label.consignments')}</h3>
              <ConsignmentsInput
                {...this.props}
                key={formTransportOrder.consignments}
                consignments={
                  formTransportOrder.consignments ? [...formTransportOrder.consignments] : []
                }
                onChange={(newConsignments) => {
                  const newTransportOrder = { ...formTransportOrder };
                  newTransportOrder.consignments = newConsignments;

                  onChange('formTransportOrder', newTransportOrder);
                }}
              />
            </div>
            {!simpleForms && (
              <>
                <div className="input-group notes">
                  <h3>{t('form.label.notes')}</h3>
                  <NotesInput
                    key={formTransportOrder.notes ? [...formTransportOrder.notes] : []}
                    notes={formTransportOrder.notes ? [...formTransportOrder.notes] : []}
                    onChange={(notes) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.notes = notes;

                      onChange('formTransportOrder', newTransportOrder);
                    }}
                  />
                </div>
                <div className="input-group">
                  <h3>{t('form.label.contactDetails')}</h3>
                  <ContactDetailsInput
                    key={
                      formTransportOrder.contactDetails
                        ? [...formTransportOrder.contactDetails]
                        : []
                    }
                    contactDetails={
                      formTransportOrder.contactDetails
                        ? [...formTransportOrder.contactDetails]
                        : []
                    }
                    onChange={(contactDetails) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.contactDetails = contactDetails;

                      onChange('formTransportOrder', newTransportOrder);
                    }}
                  />
                </div>
              </>
            )}

            <div className="full-width">
              {!isExternal(user) &&
                platform?.features?.map((feature) => feature.name)?.includes('financial') && (
                  <div className="input-group">
                    <div className="input-group no-margin-top flex-container">
                      <div>
                        <div>{t('form.label.spotquote')}</div>
                        <Switch
                          checked={formTransportOrder.quote}
                          disabled={formTransportOrder.quote?.updatedAt}
                          onChange={(e, newState) => {
                            const newTransportOrder = { ...formTransportOrder };
                            newTransportOrder.quote = newState;

                            if (newState) {
                              if (formTransportOrder.quote) {
                                changeQuoteValue('quoteForm', formTransportOrder.quote);
                              } else {
                                const initialQuoteForm = {
                                  name: '',
                                  description: '',
                                  direction: 'out',
                                  dueAt: moment().add(
                                    formTransportOrder?.administration?.dueDays || 14,
                                    'days'
                                  ),
                                  targetBusiness: formTransportOrder?.customer?.business,
                                  administration: formTransportOrder?.administration,
                                  currency: formTransportOrder?.value?.currency,
                                  lines: [
                                    {
                                      ...{
                                        name: '',
                                        value: {
                                          amount: null,
                                          taxRate: this.props?.variables?.taxRates?.[0],
                                        },
                                        sequenceNr: 0,
                                        quantity: 1,
                                        description: '',
                                        nonce: uuidv4(),
                                        pricingCategory: null,
                                        endDateTime: null,
                                        startDateTime: null,
                                      },
                                    },
                                  ],
                                  subTotal: {
                                    amount: 0,
                                  },
                                  total: {
                                    amount: 0,
                                  },
                                  tax: {
                                    amount: 0,
                                    inclusiveOfTax: false,
                                  },
                                };
                                changeQuoteValue('quoteForm', initialQuoteForm);
                              }
                            } else {
                              changeQuoteValue('quoteForm', null);
                            }

                            onChange('formTransportOrder', newTransportOrder);
                          }}
                        />
                      </div>
                      {formTransportOrder.quote && (
                        <div className="one flex-container input-group flex-container">
                          <div className="one">
                            <PricingInput
                              value={selectedPricing}
                              onChange={(newPricing) => {
                                this.setState({
                                  selectedPricing: newPricing,
                                });
                              }}
                            />
                          </div>
                          <button
                            disabled={!selectedPricing}
                            onClick={(e) => {
                              e.preventDefault();

                              generateQuoteLinesFromPricing(selectedPricing).then((response) => {
                                const lines = response.quoteLines;
                                changeQuoteValue('quoteForm', {
                                  ...formTransportOrder.quote,
                                  lines,
                                });
                              });
                            }}
                          >
                            {t('form.generate')}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {formTransportOrder?.quote && (
                <FinancialLineInput
                  key={formTransportOrder?.quote?.id + formTransportOrder?.quote?.nonce}
                  {...this.props}
                  formType="quoteForm"
                  form={quoteForm}
                  changeValue={changeQuoteValue}
                />
              )}
            </div>
            <div className="input-group more right buttons">
              {!formTransportOrder.updatedAt && (
                <button
                  disabled={!this.validator.allValid()}
                  onClick={(e) => {
                    e.preventDefault();
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.status = 'requested';
                    newTransportOrder.quote = formTransportOrder?.quote ? { ...quoteForm } : null;

                    onChange('formTransportOrder', newTransportOrder);
                    onSubmit?.(newTransportOrder);
                  }}
                >
                  {t('form.saveAsRequested')}
                </button>
              )}
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
