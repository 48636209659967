import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';

import ActionsInput from '@components/action/crud/ActionsInput';
import AttributesInput from '@components/attribute/AttributesInput';
import BoardComputersInput from '@components/boardComputer/crud/BoardComputersInput';
import ConstraintsInput from '@components/constraint/crud/ConstraintsInput';
import GoodsInput from '@components/goods/crud/GoodsInput';
import ItemsInput from '@components/goods/crud/ItemsInput';
import PricingModulesInput from '@components/pricing/crud/PricingModulesInput';

import DimensionsInput from '@uiinputs/DimensionsInput';
import FormInput from '@uiinputs/FormInput';
import GenericTypesInput from '@uiinputs/GenericTypesInput';
import ValueWithUnitInput from '@uiinputs/ValueWithUnitInput';

import PricingModule from '@models/pricing/PricingModule';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

class TransportEquipmentForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      hiddenTypeFields: [],
      goods: this.props.goods,
      sequenceNr: (this.props.initialSequenceNr || 0) - (this.props.goods?.actions?.length || 0),
    };
  }

  static defaultProps = {
    notInTransportOrderContext: false,
  };

  componentDidMount() {
    activateInputs();
    setTimeout(() => {
      this.setState({ stepsEnabled: true });
    }, 500);

    const { types } = this.props;
    const { goods } = this.state;

    this.setState({
      hiddenTypeFields:
        types?.find((type) => type.value === goods.equipmentSubType)?.hiddenFields || [],
    });
  }

  componentDidUpdate(prevProps) {
    activateInputs();

    if (prevProps.initialSequenceNr !== this.props.initialSequenceNr) {
      this.setState({
        sequenceNr: this.props.initialSequenceNr - (this.props.goods?.actions?.length - 0),
      });
    }
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange = (newGoods) => {
    const { onChange, flat, isOpenForm } = this.props;

    this.setState({
      goods: newGoods,
    });

    if (flat || isOpenForm) {
      onChange?.(newGoods, true);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onChange, onSubmit, popStack, setHasChanged } = this.props;
    const { goods } = this.state;

    onChange?.(goods, true);
    onSubmit?.(goods);

    !goods?.isOpen && popStack?.();

    setHasChanged(false);
  };

  render() {
    const { t, flat, isOpenForm, setHasChanged, platform, notInTransportOrderContext } = this.props;
    const { goods, sequenceNr, hiddenTypeFields } = this.state;

    let containedGoodsActionCount = 0;

    goods?.containedGoods?.forEach((good) => {
      containedGoodsActionCount += good?.entity?.actions?.length || good?.actions?.length || 0;
    });

    return (
      <>
        <div className="input-group equipment-type">
          <GenericTypesInput
            value={goods.equipmentType}
            entityType="transportEquipmentType"
            subTypeValue={goods.equipmentSubType}
            entitySubType="transportEquipmentSubType"
            placeholder={`${t('form.label.selectTransportEquipmentType')}*`}
            onChange={(type, subType, defaultValues) => {
              const newGoods = { ...goods, ...JSON.parse(defaultValues) };
              newGoods.equipmentType = type.value;
              newGoods.equipmentSubType = subType?.value;

              this.setState({ hiddenTypeFields: subType?.hiddenFields || [] });

              this.onChange(newGoods);
            }}
          />
          {this.validator.message(t('form.label.equipmentType'), goods.equipmentType, 'required')}
        </div>

        <FormInput
          type="text"
          label="form.label.name"
          parentName="transportequipmentform"
          hiddenTypeFields={hiddenTypeFields}
          setHasChanged={setHasChanged}
          value={goods.name}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.name = event.target.value;

            this.onChange(newGoods);
          }}
        />

        <FormInput
          type="text"
          setHasChanged={setHasChanged}
          parentName="transportequipmentform"
          label="form.label.licensePlate"
          value={goods.licensePlate}
          hiddenTypeFields={hiddenTypeFields}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.licensePlate = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <FormInput
          type="text"
          parentName="transportequipmentform"
          setHasChanged={setHasChanged}
          label="form.label.equipmentId"
          hiddenTypeFields={hiddenTypeFields}
          value={goods.equipmentId}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.equipmentId = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <FormInput
          type="text"
          parentName="transportequipmentform"
          setHasChanged={setHasChanged}
          label="form.label.seal"
          hiddenTypeFields={hiddenTypeFields}
          value={goods.seal}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.seal = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <FormInput
          type="number"
          parentName="transportequipmentform"
          setHasChanged={setHasChanged}
          label="form.label.quantity"
          hiddenTypeFields={hiddenTypeFields}
          value={goods.quantity}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.quantity = event.target.value;

            this.onChange(newGoods);
          }}
        />

        <FormInput
          type="textarea"
          parentName="transportequipmentform"
          setHasChanged={setHasChanged}
          label="form.label.description"
          className="small"
          hiddenTypeFields={hiddenTypeFields}
          value={goods.description}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.description = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <FormInput
          type="component"
          parentName="transportequipmentform"
          setHasChanged={setHasChanged}
          hiddenTypeFields={hiddenTypeFields}
          wrapperClass={'no-margin-top'}
          label="form.label.weight"
          Component={
            <ValueWithUnitInput
              t={t}
              units={['g', 'kg', 'lbs', 't']}
              defaultUnit={'kg'}
              label="form.label.weight"
              hiddenTypeFields={hiddenTypeFields}
              value={goods.weight}
              onChange={(value) => {
                const newGoods = { ...goods };
                newGoods.weight = value;

                this.onChange(newGoods);
              }}
            />
          }
        />
        <FormInput
          key={goods.grossWeight}
          type="component"
          setHasChanged={setHasChanged}
          hiddenTypeFields={hiddenTypeFields}
          wrapperClass={'no-margin-top'}
          label="form.label.grossWeight"
          Component={
            <ValueWithUnitInput
              t={t}
              units={['g', 'kg', 'lbs', 't']}
              defaultUnit={'kg'}
              label="form.label.grossWeight"
              hiddenTypeFields={hiddenTypeFields}
              value={goods.grossWeight}
              onChange={(value) => {
                const newGoods = { ...goods };
                newGoods.grossWeight = value;

                this.onChange(newGoods);
              }}
            />
          }
        />
        <FormInput
          type="component"
          key={goods.maxWeight}
          setHasChanged={setHasChanged}
          hiddenTypeFields={hiddenTypeFields}
          wrapperClass={'no-margin-top'}
          label="form.label.maxWeight"
          Component={
            <ValueWithUnitInput
              t={t}
              units={['g', 'kg', 'lbs', 't']}
              defaultUnit={'kg'}
              label="form.label.maxWeight"
              hiddenTypeFields={hiddenTypeFields}
              value={goods.maxWeight}
              onChange={(value) => {
                const newGoods = { ...goods };
                newGoods.maxWeight = value;

                this.onChange(newGoods);
              }}
            />
          }
        />
        <FormInput
          type="component"
          parentName="transportequipmentform"
          setHasChanged={setHasChanged}
          hiddenTypeFields={hiddenTypeFields}
          wrapperClass={'no-margin-top'}
          label="form.label.maxStackableWeight"
          key={goods.maxStackableWeight}
          Component={
            <ValueWithUnitInput
              t={t}
              units={['g', 'kg', 'lbs', 't']}
              defaultUnit={'kg'}
              label="form.label.maxStackableWeight"
              value={goods.maxStackableWeight}
              hiddenTypeFields={hiddenTypeFields}
              onChange={(value) => {
                const newGoods = { ...goods };
                newGoods.maxStackableWeight = value;

                this.onChange(newGoods);
              }}
            />
          }
        />
        <FormInput
          type="component"
          parentName="transportequipmentform"
          setHasChanged={setHasChanged}
          hiddenTypeFields={hiddenTypeFields}
          wrapperClass={'no-margin-top'}
          label="form.label.loadMeters"
          key={goods.loadMeters}
          Component={
            <ValueWithUnitInput
              t={t}
              units={['ldm']}
              defaultUnit={'ldm'}
              label="form.label.loadMeters"
              value={goods.loadMeters}
              hiddenTypeFields={hiddenTypeFields}
              onChange={(value) => {
                const newGoods = { ...goods };
                newGoods.loadMeters = value;
                this.onChange(newGoods);
              }}
            />
          }
        />
        <FormInput
          type="component"
          parentName="transportequipmentform"
          setHasChanged={setHasChanged}
          hiddenTypeFields={hiddenTypeFields}
          wrapperClass={'no-margin-top'}
          label="form.label.loadLoadMeters"
          key={goods.loadLoadMeters}
          Component={
            <ValueWithUnitInput
              t={t}
              units={['ldm']}
              defaultUnit={'ldm'}
              label="form.label.loadLoadMeters"
              value={goods.loadLoadMeters}
              hiddenTypeFields={hiddenTypeFields}
              onChange={(value) => {
                const newGoods = { ...goods };
                newGoods.loadLoadMeters = value;
                this.onChange(newGoods);
              }}
            />
          }
        />
        <FormInput
          type="component"
          setHasChanged={setHasChanged}
          hiddenTypeFields={hiddenTypeFields}
          wrapperClass={'no-margin-top'}
          label="form.label.dimensions"
          key={goods.length + goods.width + goods.height}
          Component={
            <DimensionsInput
              defaultUnit="cm"
              unit={goods.length ? goods.length.unit : null}
              length={goods.length}
              height={goods.height}
              hiddenTypeFields={hiddenTypeFields}
              width={goods.width}
              onChange={(e) => {
                const newGoods = { ...goods };
                newGoods.length = { value: e.length, unit: e.unit };
                newGoods.height = { value: e.height, unit: e.unit };
                newGoods.width = { value: e.width, unit: e.unit };

                this.onChange(newGoods);
              }}
            />
          }
        />
        <div className="dimensions">
          <FormInput
            type="component"
            parentName="transportequipmentform"
            setHasChanged={setHasChanged}
            hiddenTypeFields={hiddenTypeFields}
            wrapperClass={'no-margin-top'}
            label="form.label.loadDimensions"
            key={goods.loadLength + goods.loadWidth + goods.loadHeight}
            Component={
              <DimensionsInput
                defaultUnit="m"
                unit={goods.loadLength > 0 ? goods.loadLength.unit : null}
                lengthLabel="form.label.loadLength"
                length={goods.loadLength}
                heightLabel="form.label.loadHeight"
                height={goods.loadHeight}
                widthLabel="form.label.loadWidth"
                width={goods.loadWidth}
                hiddenTypeFields={hiddenTypeFields}
                onChange={(e) => {
                  const newGoods = { ...goods };
                  newGoods.loadLength = { value: e.length, unit: e.unit };
                  newGoods.loadHeight = { value: e.height, unit: e.unit };
                  newGoods.loadWidth = { value: e.width, unit: e.unit };

                  this.onChange(newGoods);
                }}
              />
            }
          />
        </div>
        <FormInput
          type="component"
          parentName="transportequipmentform"
          label={'form.label.attributes'}
          Component={
            <AttributesInput
              key={goods.attributes ? goods.attributes : null}
              value={goods.attributes ? goods.attributes : null}
              onChange={(newAttributes) => {
                const newGoods = { ...goods };
                newGoods.attributes = newAttributes ? newAttributes : null;

                this.onChange(newGoods);
              }}
            />
          }
        />

        {!notInTransportOrderContext && (
          <>
            {flat ? (
              <div className="input-group no-margin-top items-input">
                <h3>{t('form.label.goods')}</h3>
                <GoodsInput
                  {...this.props}
                  goods={goods?.containedGoods ? [...goods.containedGoods] : null}
                  initialSequenceNr={
                    (sequenceNr || 0) + containedGoodsActionCount + (goods?.actions?.length || 0)
                  }
                  defaultEnabled={true}
                  onChange={(newContainedGoods) => {
                    const newGoods = { ...goods };
                    newGoods.containedGoods = [...newContainedGoods];

                    this.onChange(newGoods);
                  }}
                />
              </div>
            ) : (
              <div className="input-group no-margin-top">
                <h3>{t('form.label.goods')}</h3>
                <ItemsInput
                  {...this.props}
                  goods={
                    goods?.containedGoods
                      ? [...goods.containedGoods].filter((g) => g.entity.type === 'items')
                      : null
                  }
                  initialSequenceNr={
                    (sequenceNr || 0) + containedGoodsActionCount + (goods?.actions?.length || 0)
                  }
                  defaultEnabled={true}
                  onChange={(newContainedGoods) => {
                    const newGoods = { ...goods };
                    newGoods.containedGoods = [
                      ...[...(newGoods.containedGoods || [])].filter(
                        (g) => g.entity.type === 'transportEquipment'
                      ),
                      ...newContainedGoods,
                    ];

                    this.onChange(newGoods);
                  }}
                />
              </div>
            )}
            <div className="input-group no-margin-top">
              <h3>{t('form.label.actions')}</h3>
              <ActionsInput
                {...this.props}
                actions={goods?.actions || []}
                context={'transportEquipment'}
                initialSequenceNr={
                  sequenceNr + containedGoodsActionCount + (goods?.actions?.length || 0)
                }
                onChange={(actions) => {
                  const newGoods = { ...goods };
                  newGoods.actions = actions;

                  this.onChange(newGoods);
                }}
              />
            </div>
          </>
        )}

        <div className="input-group no-margin-top">
          <h3>{t('form.label.constraints')}</h3>
          <ConstraintsInput
            {...this.props}
            constraints={goods?.constraints || []}
            onChange={(constraints) => {
              const newGoods = { ...goods };
              newGoods.constraints = constraints;

              this.onChange(newGoods);
            }}
          />
        </div>
        {notInTransportOrderContext && (
          <>
            {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
              <div className="input-group">
                <h3>{t('form.label.costModules')}</h3>
                <PricingModulesInput
                  {...this.props}
                  key={goods.pricingModules}
                  entityType={goods.targetEntityType}
                  modules={goods.pricingModules ? [...goods.pricingModules] : [new PricingModule()]}
                  onChange={(newModules) => {
                    const newGoods = { ...goods };
                    newGoods.pricingModules = newModules;

                    this.onChange(newGoods);
                  }}
                />
              </div>
            )}
            <div className="input-group">
              <h3 className="no-margin-top">{t('form.label.selectBoardComputer')}</h3>
              <BoardComputersInput
                key={goods.boardComputers ? goods.boardComputers : null}
                boardComputers={goods.boardComputers ? goods.boardComputers : null}
                onChange={(newBoardComputers) => {
                  const newGoods = { ...goods };
                  newGoods.boardComputers = newBoardComputers ? newBoardComputers : null;

                  this.onChange(newGoods);
                }}
              />
            </div>
          </>
        )}
        {!flat && !isOpenForm && (
          <div className="input-group more right">
            <input
              type="submit"
              disabled={!this.validator.allValid()}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.handleSubmit(e);
              }}
              value={t('form.save')}
            />
          </div>
        )}
      </>
    );
  }
}
export default withTranslation('translation')(TransportEquipmentForm);
