export const socketConstants = {
  NEW_CHAT_MESSAGE: 'newMessage',
  NEW_MAIL_MESSAGES: 'newMailMessages',
  NEW_NOTIFICATION: 'newNotification',
  NEW_EXTENDED_VEHICLES: 'newExtendedVehicle',
  NEW_EXTENDED_TRANSPORT_ORDERS: 'newExtendedTransportOrders',
  NEW_TRIP: 'newTrip',
  NEW_TRANSPORT_EQUIPMENT: 'newTransportEquipment',
  NEW_CONSIGNMENT: 'newConsignment',
  NEW_ALERT: 'newAlert',
};
