import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateVehicleContainer from '@containers/vehicle/crud/CreateVehicleContainer';

import { search } from '@api/vehicleApi';

import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

import { prefixZeroFormat } from '@utils/numberUtils';

let searchTimeout = null;

export const transformVehicleToValue = (vehicle) => ({
  label: vehicle?.fleets
    ? `${vehicle.fleets
        .map(
          (fleetVehicle) =>
            `${fleetVehicle.fleet.number}${prefixZeroFormat(fleetVehicle.order + 1)}`
        )
        .join(', ')}${vehicle.fleets?.length > 0 ? ' - ' : ''} ${vehicle.name}`
    : vehicle.name,
  vehicle: vehicle,
  value: vehicle.id,
});

class VehicleInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? transformVehicleToValue(this.props.value) : null,
      vehicles: [],
    };
  }

  componentDidMount() {
    const { value, getVehicle, vehicleId } = this.props;

    if (!value && vehicleId) {
      getVehicle(vehicleId).then((response) => {
        if (response.success) {
          this.setState({ value: transformVehicleToValue(response.vehicle) });
        }
      });
    } else {
      search({ query: '' }, 0, 40).then((response) => {
        this.setState({
          vehicles: value ? [...response.vehicles, value] : [...response.vehicles],
        });
      });
    }
  }

  onChange = (newVehicle) => {
    this.setState({
      value: newVehicle,
    });
    const { onChange } = this.props;
    onChange?.(newVehicle ? newVehicle.vehicle : null);
  };

  render() {
    const { t, isFetchingVehicles, addToStack, placeholder, components, styles, ...props } =
      this.props;
    const { value, vehicles } = this.state;

    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          defaultOptions={vehicles?.map((vehicle) => transformVehicleToValue(vehicle))}
          placeholder={placeholder ? placeholder : `${t('form.label.searchVehicle')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingVehicles}
          isDisabled={isFetchingVehicles}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              search({ query: inputValue }, 0, 40).then((response) => {
                callback(response.vehicles.map((vehicle) => transformVehicleToValue(vehicle)));
              });
            }, 400);
          }}
          components={
            components
              ? components
              : {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
          }
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('vehicle.new'),
              component: (
                <CreateVehicleContainer
                  targetName={inputValue}
                  callback={(newVehicle) => {
                    this.onChange(transformVehicleToValue(newVehicle));
                  }}
                />
              ),
            });
          }}
          value={value}
          styles={styles}
          {...props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(vehicleActions.changeValue(name, value)),
    getVehicle: (id) => dispatch(vehicleActions.getVehicle(id)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(VehicleInput));
