import React, { Component } from 'react';

import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import AdministrationInput from '@components/administration/crud/AdministrationInput';
import BusinessesInput from '@components/business/BusinessesInput';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import Association from '@models/general/Association';
import TaxRate from '@models/general/TaxRate';
import PricingModule from '@models/pricing/PricingModule';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import PricingModulesInput from './PricingModulesInput';
import PricingTaxRatesInput from './PricingTaxRatesInput';

export default class PricingForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {};
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, formPricing, setHasChanged } = this.props;
    onSubmit && onSubmit(formPricing);
    setHasChanged(false);
  }

  render() {
    const {
      t,
      onChange,
      formPricing,
      pricingTriggerTypes,
      pricingEntityTypes,
      currencies,
      setHasChanged,
    } = this.props;

    const labeledCurrencies = currencies.map((currency) => ({
      value: currency.short,
      label: currency.name,
      sign: currency.sign,
      currency: currency,
    }));

    const selectedCurrency = formPricing?.currency
      ? labeledCurrencies.find((lc) => lc.value === formPricing.currency.short)
      : currencies.length > 0
      ? labeledCurrencies[0]
      : null;

    const pricingTriggerTypeOptions = pricingTriggerTypes
      .filter((type) => (formPricing.entityType === 'vehicle' ? type.timeBased : true))
      .map((type) => ({
        label: type,
        value: type,
      }));

    const pricingEntityTypeOptions = pricingEntityTypes
      .filter((type) => type.type !== 'accumulatedData')
      .map((type) => ({
        label: t(`entityType.${type.type}`),
        value: type.type,
      }));

    return (
      <div className="step-form pricing-form" key={formPricing.id}>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div className="name">
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.name"
                required={true}
                value={formPricing.name}
                onChange={(event) => {
                  const newPricing = { ...formPricing };
                  newPricing.name = event.target.value;

                  onChange('formPricing', newPricing, event);
                }}
              />
              {this.validator.message(t('form.label.name'), formPricing.name, 'required')}
            </div>
            <div className="input-group context">
              <div className="input-group no-margin-top">
                <Select
                  placeholder={t('form.label.selectPricingEntityType')}
                  noOptionsMessage={() => t('noOptions')}
                  value={pricingEntityTypeOptions.find(
                    (option) => option.value === formPricing.targetEntityType
                  )}
                  options={pricingEntityTypeOptions}
                  onChange={(event) => {
                    const newPricing = { ...formPricing };
                    newPricing.targetEntityType = event.value;
                    newPricing.modules = [...newPricing.modules].map((module) => ({
                      ...module,
                      targetEntityType: null,
                    }));

                    onChange('formPricing', newPricing);
                  }}
                />
              </div>
            </div>
            <div className="input-group trigger">
              <div className="input-grou no-margin-top">
                <Select
                  placeholder={t('form.label.selectPricingTriggerType')}
                  noOptionsMessage={() => t('noOptions')}
                  value={pricingTriggerTypeOptions.find(
                    (option) => option.value === formPricing.triggerType
                  )}
                  options={pricingTriggerTypeOptions}
                  onChange={(event) => {
                    const newPricing = { ...formPricing };
                    newPricing.triggerType = event.value;

                    onChange('formPricing', newPricing);
                  }}
                />
              </div>
            </div>
            <div className="input-group business">
              <BusinessesInput
                key={formPricing.businesses.map((association) => association.entity.id).join()}
                value={formPricing.businesses.map((association) => association.entity)}
                onChange={(newBusinesses) => {
                  const newPricing = { ...formPricing };
                  newPricing.businesses = newBusinesses.map(
                    (business) => new Association('inline', business)
                  );
                  onChange('formPricing', newPricing);
                }}
              />
            </div>

            <div className="input-group currency">
              <Select
                options={labeledCurrencies}
                value={selectedCurrency}
                onChange={(e) => {
                  const newPricing = { ...formPricing };
                  newPricing.currency = e.currency;

                  onChange('formPricing', newPricing);
                }}
              />
            </div>
            <div className="input-group no-margin-top flex-container">
              <div className="input-group one">
                <div>{t('form.label.direct')}</div>
                <Switch
                  checked={formPricing.direct}
                  onChange={(e, newValue) => {
                    e.preventDefault();
                    const newPricing = { ...formPricing };
                    newPricing.direct = newValue;

                    onChange('formPricing', newPricing);
                  }}
                />
              </div>
              <div className="input-group one">
                <div>{t('form.label.combinePerCustomer')}</div>
                <Switch
                  checked={formPricing.combinePerCustomer}
                  onChange={(e, newValue) => {
                    e.preventDefault();
                    const newPricing = { ...formPricing };
                    newPricing.combinePerCustomer = newValue;

                    onChange('formPricing', newPricing);
                  }}
                />
              </div>
            </div>
            {formPricing.direct && (
              <>
                <div className="input-group">
                  <AdministrationInput
                    key={formPricing.administration?.id || ''}
                    value={formPricing.administration}
                    onChange={(newAdministration) => {
                      const newPricing = { ...formPricing };
                      newPricing.administration = newAdministration;

                      onChange('formPricing', newPricing);
                    }}
                  />
                </div>
                <div className="input-group">
                  <div>{t('form.label.selfBill')}</div>
                  <Switch
                    checked={formPricing.selfBill}
                    onChange={(e, newValue) => {
                      e.preventDefault();
                      const newPricing = { ...formPricing };
                      newPricing.selfBill = newValue;

                      onChange('formPricing', newPricing);
                    }}
                  />
                </div>
              </>
            )}
            <div className="input-group pricing-module">
              <h3>{t('form.label.modules')}</h3>
              <PricingModulesInput
                {...this.props}
                key={formPricing.modules}
                entityType={formPricing.targetEntityType}
                modules={
                  formPricing.modules?.length > 0 ? [...formPricing.modules] : [new PricingModule()]
                }
                onChange={(newModlues) => {
                  const newPricing = { ...formPricing };
                  newPricing.modules = newModlues;

                  onChange('formPricing', newPricing);
                }}
              />
            </div>
            <div className="input-group pricing-module">
              <h3>{t('form.label.taxRates')}</h3>
              <PricingTaxRatesInput
                {...this.props}
                key={formPricing.taxRates}
                taxRates={
                  formPricing.taxRates?.length > 0 ? [...formPricing.taxRates] : [new TaxRate()]
                }
                taxRateOptions={this.props.taxRates}
                entityType={formPricing.targetEntityType}
                onChange={(newTaxRates) => {
                  const newPricing = { ...formPricing };
                  newPricing.taxRates = newTaxRates;

                  onChange('formPricing', newPricing);
                }}
              />
            </div>
            <div className="input-group">
              <div>{t('form.label.offOn')}</div>
              <Switch
                checked={formPricing.enabled}
                onChange={(e, newValue) => {
                  e.preventDefault();
                  const newPricing = { ...formPricing };
                  newPricing.enabled = newValue;

                  onChange('formPricing', newPricing);
                }}
              />
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => e.stopPropagation()}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
