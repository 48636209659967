import * as platformApi from '@api/platformApi';

import { platformConstants } from '../constants';

/** Set Platform */
export const setPlatform = (platform) => ({
  type: platformConstants.SET_PLATFORM,
  platform,
});

export const changeValue = (name, value) => ({
  type: platformConstants.PLATFORM_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching platform
 */
export const startGetPlatform = (id) => ({
  type: platformConstants.GET_PLATFORM_STARTED,
  id,
});

export const getPlatformSuccess = (result) => ({
  type: platformConstants.GET_PLATFORM_SUCCESS,
  ...result,
});

export const getPlatformFailure = (error) => ({
  type: platformConstants.GET_PLATFORM_FAILURE,
  ...error,
});

export const getPlatform = () => {
  return (dispatch) => {
    dispatch(startGetPlatform());

    const promise = platformApi.get();

    promise
      .then((result) => {
        dispatch(getPlatformSuccess(result));
      })
      .catch((error) => {
        dispatch(getPlatformFailure(error));
      });

    return promise;
  };
};

export const getPlatformById = (id) => {
  return (dispatch) => {
    dispatch(startGetPlatform(id));

    const promise = platformApi.getById(id);

    promise
      .then((result) => {
        dispatch(getPlatformSuccess(result));
      })
      .catch((error) => {
        dispatch(getPlatformFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching platforms
 */
export const startGetPlatforms = (from) => ({
  type: platformConstants.GET_PLATFORMS_STARTED,
  from,
});

export const getPlatformsSuccess = (result, from) => ({
  type: platformConstants.GET_PLATFORMS_SUCCESS,
  ...result,
  from,
});

export const getPlatformsFailure = (error) => ({
  type: platformConstants.GET_PLATFORMS_FAILURE,
  ...error,
});

export const getPlatforms = (from, amount) => {
  return (dispatch) => {
    dispatch(startGetPlatforms(from));

    const promise = platformApi.all(from, amount);

    promise
      .then((result) => {
        dispatch(getPlatformsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getPlatformsFailure(error));
      });

    return promise;
  };
};

export const searchPlatforms = (searchParameters, from, amount) => {
  return (dispatch) => {
    dispatch(startGetPlatforms());

    const promise = platformApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getPlatformsSuccess(result));
      })
      .catch((error) => {
        dispatch(getPlatformsFailure(error));
      });

    return promise;
  };
};

/*
 * Get platform billing
 */

export const startGetBilling = () => ({
  type: platformConstants.GET_BILLING_STARTED,
});

export const getBillingSuccess = (result) => ({
  type: platformConstants.GET_BILLING_SUCCESS,
  ...result,
});

export const getBillingFailure = (error) => ({
  type: platformConstants.GET_BILLING_FAILURE,
  ...error,
});

export const getBilling = (billingId) => {
  return (dispatch) => {
    dispatch(startGetBilling());

    const promise = platformApi.getBilling(billingId);

    promise
      .then((result) => {
        dispatch(getBillingSuccess(result));
      })
      .catch((error) => {
        dispatch(getBillingFailure(error));
      });

    return promise;
  };
};

export const startGetBillingUsage = () => ({
  type: platformConstants.GET_BILLING_USAGE_STARTED,
});

export const getBillingUsageSuccess = (result) => ({
  type: platformConstants.GET_BILLING_USAGE_SUCCESS,
  ...result,
});

export const getBillingUsageFailure = (error) => ({
  type: platformConstants.GET_BILLING_USAGE_FAILURE,
  ...error,
});

export const getBillingUsage = (billingId, offset, amount) => {
  return (dispatch) => {
    dispatch(startGetBillingUsage());

    const promise = platformApi.getBillingUsageEvents(billingId, offset, amount);

    promise
      .then((result) => {
        dispatch(getBillingUsageSuccess(result));
      })
      .catch((error) => {
        dispatch(getBillingUsageFailure(error));
      });

    return promise;
  };
};

export const getBillingUsageByDate = (billingId, start, end) => {
  return (dispatch) => {
    dispatch(startGetBillingUsage());

    const promise = platformApi.getBillingUsageEventsByDate(billingId, start, end);

    promise
      .then((result) => {
        dispatch(getBillingUsageSuccess(result));
      })
      .catch((error) => {
        dispatch(getBillingUsageFailure(error));
      });

    return promise;
  };
};

export const startGetBillingEstimate = () => ({
  type: platformConstants.GET_BILLING_ESTIMATE_STARTED,
});

export const getBillingEstimateSuccess = (result) => ({
  type: platformConstants.GET_BILLING_ESTIMATE_SUCCESS,
  ...result,
});

export const getBillingEstimateFailure = (error) => ({
  type: platformConstants.GET_BILLING_ESTIMATE_FAILURE,
  ...error,
});

export const getBillingEstimate = (billingId) => {
  return (dispatch) => {
    dispatch(startGetBillingEstimate());

    const promise = platformApi.getBillingEstimate(billingId);

    promise
      .then((result) => {
        dispatch(getBillingEstimateSuccess(result));
      })
      .catch((error) => {
        dispatch(getBillingEstimateFailure(error));
      });

    return promise;
  };
};

/**
 * Creating platform
 */
export const startCreatePlatform = () => ({
  type: platformConstants.CREATE_PLATFORM_STARTED,
});

export const createPlatformSuccess = (result) => ({
  type: platformConstants.CREATE_PLATFORM_SUCCESS,
  ...result,
});

export const createPlatformFailure = (error) => ({
  type: platformConstants.CREATE_PLATFORM_FAILURE,
  ...error,
});

export const createPlatform = (platform) => {
  return (dispatch) => {
    dispatch(startCreatePlatform());

    const promise = platformApi.create(platform);

    promise
      .then((result) => {
        dispatch(createPlatformSuccess(result));
      })
      .catch((error) => {
        dispatch(createPlatformFailure(error));
      });

    return promise;
  };
};

/**
 * Creating platform intent
 */
export const startCreatePaymentIntent = () => ({
  type: platformConstants.CREATE_PAYMENT_INTENT_STARTED,
});

export const createPaymentIntentSuccess = (result) => ({
  type: platformConstants.CREATE_PAYMENT_INTENT_SUCCESS,
  ...result,
});

export const createPaymentIntentFailure = (error) => ({
  type: platformConstants.CREATE_PAYMENT_INTENT_FAILURE,
  ...error,
});

export const createPlatformPaymentIntent = (intent) => {
  return (dispatch) => {
    dispatch(startCreatePaymentIntent());

    const promise = platformApi.createPaymentIntent(intent);

    promise
      .then((result) => {
        dispatch(createPaymentIntentSuccess(result));
      })
      .catch((error) => {
        dispatch(createPaymentIntentFailure(error));
      });

    return promise;
  };
};

/**
 * Updating platform
 */
export const startUpdatePlatform = () => ({
  type: platformConstants.UPDATE_PLATFORM_STARTED,
});

export const updatePlatformSuccess = (result) => ({
  type: platformConstants.UPDATE_PLATFORM_SUCCESS,
  ...result,
});

export const updatePlatformFailure = (error) => ({
  type: platformConstants.UPDATE_PLATFORM_FAILURE,
  ...error,
});
export const updatePlatform = (platform) => {
  return (dispatch) => {
    dispatch(startUpdatePlatform());

    const promise = platformApi.update(platform);

    promise
      .then((result) => {
        dispatch(updatePlatformSuccess(result));
      })
      .catch((error) => {
        dispatch(updatePlatformFailure(error));
      });

    return promise;
  };
};
export const updatePlatformSettings = (platform) => {
  return (dispatch) => {
    dispatch(startUpdatePlatform());

    const promise = platformApi.updateSettings(platform);

    promise
      .then((result) => {
        dispatch(updatePlatformSuccess(result));
      })
      .catch((error) => {
        dispatch(updatePlatformFailure(error));
      });

    return promise;
  };
};

/**
 * Delete platform
 */
export const startDeletePlatform = () => ({
  type: platformConstants.DELETE_PLATFORM_STARTED,
});

export const deletePlatformSuccess = (result) => ({
  type: platformConstants.DELETE_PLATFORM_SUCCESS,
  ...result,
});

export const deletePlatformFailure = (error) => ({
  type: platformConstants.DELETE_PLATFORM_FAILURE,
  ...error,
});
export const deletePlatform = (platform) => {
  return (dispatch) => {
    dispatch(startDeletePlatform());

    const promise = platformApi.remove(platform);

    promise
      .then((result) => {
        dispatch(deletePlatformSuccess(result));
      })
      .catch((error) => {
        dispatch(deletePlatformFailure(error));
      });

    return promise;
  };
};

/*
 * Get platform billing invoices
 */
export const startGetBillingInvoices = () => ({
  type: platformConstants.GET_BILLING_INVOICES_STARTED,
});
export const getBillingInvoicesSuccess = (result) => ({
  type: platformConstants.GET_BILLING_INVOICES_SUCCESS,
  ...result,
});
export const getBillingInvoicesFailure = (error) => ({
  type: platformConstants.GET_BILLING_INVOICES_FAILURE,
  ...error,
});
export const getBillingInvoices = (billingId, offset = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetBillingInvoices());
    const promise = platformApi.getBillingInvoices(billingId, offset, amount);
    promise
      .then((result) => {
        dispatch(getBillingInvoicesSuccess(result));
      })
      .catch((error) => {
        dispatch(getBillingInvoicesFailure(error));
      });
    return promise;
  };
};

/**
 * Fetching languages
 */
export const startGetLanguages = () => ({
  type: platformConstants.GET_LANGUAGES_STARTED,
});

export const startLanguagesSuccess = (result) => ({
  type: platformConstants.GET_LANGUAGES_SUCCESS,
  ...result,
});

export const startLanguagesFailure = (error) => ({
  type: platformConstants.GET_LANGUAGES_FAILURE,
  ...error,
});

export const getLanguages = () => {
  return (dispatch) => {
    dispatch(startGetLanguages());

    const promise = platformApi.languages();

    promise
      .then((result) => {
        dispatch(startLanguagesSuccess(result));
      })
      .catch((error) => {
        dispatch(startLanguagesFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching permissions
 */
export const startGetPermissions = () => ({
  type: platformConstants.GET_PERMISSIONS_STARTED,
});

export const startPermissionsSuccess = (result) => ({
  type: platformConstants.GET_PERMISSIONS_SUCCESS,
  ...result,
});

export const startPermissionsFailure = (error) => ({
  type: platformConstants.GET_PERMISSIONS_FAILURE,
  ...error,
});

export const getPermissions = () => {
  return (dispatch) => {
    dispatch(startGetPermissions());

    const promise = platformApi.permissions();

    promise
      .then((result) => {
        dispatch(startPermissionsSuccess(result));
      })
      .catch((error) => {
        dispatch(startPermissionsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching industries
 */
export const startGetIndustries = () => ({
  type: platformConstants.GET_INDUSTRIES_STARTED,
});

export const startIndustriesSuccess = (result) => ({
  type: platformConstants.GET_INDUSTRIES_SUCCESS,
  ...result,
});

export const startIndustriesFailure = (error) => ({
  type: platformConstants.GET_INDUSTRIES_FAILURE,
  ...error,
});

export const getIndustries = () => {
  return (dispatch) => {
    dispatch(startGetIndustries());

    const promise = platformApi.industries();

    promise
      .then((result) => {
        dispatch(startIndustriesSuccess(result));
      })
      .catch((error) => {
        dispatch(startIndustriesFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching currencies
 */
export const startGetCurrencies = () => ({
  type: platformConstants.GET_CURRENCIES_STARTED,
});

export const getCurrenciesSuccess = (result) => ({
  type: platformConstants.GET_CURRENCIES_SUCCESS,
  ...result,
});

export const getCurrenciesFailure = (error) => ({
  type: platformConstants.GET_CURRENCIES_FAILURE,
  ...error,
});

export const getCurrencies = () => {
  return (dispatch) => {
    dispatch(startGetCurrencies());

    const promise = platformApi.currencies();

    promise
      .then((result) => {
        dispatch(getCurrenciesSuccess(result));
      })
      .catch((error) => {
        dispatch(getCurrenciesFailure(error));
      });

    return promise;
  };
};

/**
 * Send e-mail
 */
export const startContact = () => ({
  type: platformConstants.CONTACT_STARTED,
});

export const contactSuccess = (result) => ({
  type: platformConstants.CONTACT_SUCCESS,
  ...result,
});

export const contactFailure = (error) => ({
  type: platformConstants.CONTACT_FAILURE,
  ...error,
});

export const contact = (email, subject, message, captcha) => {
  return (dispatch) => {
    dispatch(startContact());

    const promise = platformApi.contact(email, subject, message, captcha);

    promise
      .then((result) => {
        dispatch(contactSuccess(result));
      })
      .catch((error) => {
        dispatch(contactFailure(error));
      });

    return promise;
  };
};

/**
    setWebsocket
 */
export const setWebSocket = (webSocket) => ({
  type: platformConstants.SET_WEBSOCKET_CONNECTION,
  webSocket: webSocket,
});
