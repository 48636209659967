import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';

class LangaugeSelectorInput extends Component {
  render() {
    const { value, languages, onChange } = this.props;

    const languageOptions = languages
      ? languages?.map((language) => {
          return {
            label: language.localName,
            value: language.id,
            code: language.code,
            language: language,
          };
        })
      : [];

    return (
      <Select
        options={languageOptions}
        value={languageOptions.find((languageOption) => languageOption.value === value)}
        onChange={(e) => {
          onChange?.(e);
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    languages: state.platform.languages,
    languagesIsFetching: state.platform.languagesIsFetching,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(withTranslation('translation')(LangaugeSelectorInput));
