import React, { Component, createRef } from 'react';

import Dropzone from 'react-dropzone';
import Select from 'react-select';
import ReactSVG from 'react-svg';

import MessageTemplateInput from '@components/messageTemplate/MessageTemplateInput';

import Loader from '@uicomponents/Loader';
import PopOver from '@uicomponents/PopOver';
import Progress from '@uicomponents/Progress';
import ContentEditable from '@uiinputs/ContentEditable';

import * as fileApi from '@api/fileApi';
import * as messageTemplateApi from '@api/messageTemplateApi';
import Message from '@models/chat/Message';

import { formatBytes } from '@utils/fileUtils';
import { activateInputs, deactivateInputs } from '@utils/formUtils';
import { base64ToBlob, getBase64 } from '@utils/imageUtils';
import { truncate } from '@utils/stringUtils';

export default class CreateMessageForm extends Component {
  constructor(props) {
    super(props);

    const { messageInConcept } = this.props;

    this.state = {
      files: messageInConcept?.files || [],
      message: messageInConcept?.message || '',

      important: messageInConcept?.state === 'important' ? true : false || false,
      messageTemplatePopOver: false,
      messageTemplateIsLoading: false,
      selectedLanguage: null,
      messageTemplate: null,

      shouldUpdateContent: true,
    };

    this.messageRef = createRef();
    this.dropzoneRef = createRef();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { createMessage, onChange, chat, message } = this.props;

    createMessage &&
      createMessage(chat.id, {
        ...message,
        isOffline: true,
        user: this.getCurrentChatUser(chat)?.id || '',
        nonce: Math.random().toString(36).substring(7),
      });

    const newMessage = new Message();
    newMessage.isOffline = true;
    newMessage.user = this.getCurrentChatUser(chat)?.id || '';
    onChange('message', newMessage);

    this.setState({
      files: [],
      message: '',
      important: false,
      shouldUpdateContent: true,
    });
  }

  handleChange = (e) => {
    const { onChange, message } = this.props;
    const { files, important } = this.state;

    const newMessage = { ...message };
    newMessage.files = [...files].map((f) => f?.id)?.filter((f) => f);
    newMessage.message = this.state.message;
    newMessage.state = important ? 'important' : 'normal';

    onChange('message', newMessage);
  };

  retry = (message) => {
    const { chat, createMessage } = this.props;
    createMessage && createMessage(chat.id, message);
  };

  getCurrentChatUser = (chat) => {
    const { user } = this.props;
    return user
      ? chat.users.find((chatUser) => chatUser.user && chatUser.user.id === user.id)
      : null;
  };

  uploadFile = (file) => {
    const { files } = this.state;

    const prevFileIndex =
      files.findIndex((f) => f.nonce === file.nonce) >= 0
        ? files.findIndex((f) => f.nonce === file.nonce)
        : 0;

    getBase64(file).then((base64) => {
      fileApi
        .uploadFile(base64ToBlob(base64), 'files', (e) => {
          let newFiles = [...files];
          let newFile = { ...newFiles[prevFileIndex] };
          newFile.progress = (e.loaded / e.total) * 100;
          newFile.uploading = true;
          if (!newFile.base64) newFile.base64 = base64;

          newFiles[prevFileIndex] = newFile;
          this.setState({
            files: newFiles,
          });
        })
        .then((response) => {
          let newFiles = [...files];
          let newFile = { ...newFiles[prevFileIndex] };
          if (response.success) {
            newFile = { ...response.file, ...newFile };
          } else {
            newFile.error = response.message;
          }
          newFile.uploading = false;
          newFile.uploaded = true;
          newFiles[prevFileIndex] = newFile;

          this.setState(
            {
              files: newFiles,
            },
            () => {
              this.handleChange();
            }
          );
        });
    });
  };

  componentDidMount() {
    activateInputs();
  }

  componentWillUnmount() {
    this.messageRef = null;
    this.dropzoneRef = null;

    deactivateInputs();
  }

  render() {
    const { t, chat, languages, darkMode } = this.props;
    const {
      files,
      important,
      shouldUpdateContent,
      message,
      messageTemplatePopOver,
      selectedLanguage,
      messageTemplate,
      messageTemplateIsLoading,
    } = this.state;

    const languageOptions = (languages || []).map((language) => {
      return { label: language.localName, value: language.id, language };
    });

    if (shouldUpdateContent) {
      this.setState({ shouldUpdateContent: false });
    }

    if (chat) {
      return (
        <>
          <PopOver
            isActive={messageTemplatePopOver}
            className="select-pop-over"
            onClose={(e) => {
              this.setState({
                messageTemplatePopOver: false,
              });
            }}
          >
            <div className="box">
              <h1 className="title">{t('messageTemplate.selecteHeader')}</h1>
              {messageTemplateIsLoading ? (
                <Loader />
              ) : (
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="input-group">
                    <Select
                      placeholder={t('form.label.selectLanguage')}
                      options={languageOptions}
                      value={languageOptions.find(
                        (languageOption) =>
                          languageOption.value === (selectedLanguage ? selectedLanguage.id : null)
                      )}
                      noOptionsMessage={() => t('noOptions')}
                      onChange={(e) => {
                        this.setState({
                          selectedLanguage: e.language,
                          messageTemplate: null,
                        });
                      }}
                    />
                  </div>
                  {selectedLanguage && (
                    <div className="input-group">
                      <MessageTemplateInput
                        key={messageTemplate}
                        language={selectedLanguage}
                        messageTemplate={messageTemplate}
                        onChange={(messageTemplate) => {
                          this.setState({ messageTemplate: messageTemplate });
                        }}
                      />
                    </div>
                  )}
                  <div className="choice">
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();

                        this.setState({
                          messageTemplatePopOver: false,
                        });
                      }}
                    >
                      {t('cancel')}
                    </a>
                    <button
                      href="#"
                      onClick={async (e) => {
                        e.preventDefault();

                        this.setState({ messageTemplateIsLoading: true });

                        try {
                          const response = await messageTemplateApi.generateMessage(
                            messageTemplate.id,
                            chat.id
                          );

                          this.setState({
                            messageTemplatePopOver: false,
                            messageTemplateIsLoading: false,
                            message: response.generatedMessage,

                            shouldUpdateContent: true,
                          });
                        } catch (error) {}
                      }}
                    >
                      {t('apply')}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </PopOver>
          <div className="new-message">
            <div className="upload-files">
              {files.length > 0 && (
                <div className="input-group no-margin-top uploaded-files d-flex gap-20 mt-30">
                  {files.map((file) => (
                    <div
                      className="file"
                      key={`${file.url}-${file.nonce}-${file.uploading}-${file.uploaded}`}
                    >
                      <div
                        className="file-preview"
                        style={{
                          backgroundImage: file.base64 ? `url(${file.base64})` : 'none',
                          backgroundColor: file.error ? '#f8d7da' : '#e0e0e0',
                        }}
                      ></div>
                      <div className="info">
                        <div className="name" title={file.originalName || file.name || file.path}>
                          {truncate(
                            file.error ? file.error : file.originalName || file.name || file.path,
                            10
                          )}
                        </div>
                        <span className="font-xs">{file.size && formatBytes(file.size)}</span>
                        {file.uploaded ? (
                          <div className="state">{t('attachments.uploaded')}</div>
                        ) : (
                          <div className="state">
                            <span>{t('attachments.uploading')}</span>
                            <Progress progress={file.progress || 0} />
                          </div>
                        )}
                      </div>
                      <div className="actions">
                        {file.uploading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                        {(!file.uploading || file.uploaded) && (
                          <ReactSVG
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState(
                                {
                                  files: files.filter((f) => f.id !== file.id),
                                },
                                () => {
                                  this.handleChange();
                                }
                              );
                            }}
                            className="remove-action"
                            src="/icons/cross.svg"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <form>
              <Dropzone
                ref={(node) => {
                  this.dropzoneRef = node;
                }}
                onDrop={(acceptedFiles) => {
                  const newFiles = acceptedFiles.map((file) => {
                    file.id = Math.random().toString(36).substring(7);
                    return file;
                  });
                  this.setState(() => ({ files: files.concat(newFiles) }));

                  newFiles.forEach((file) => {
                    this.uploadFile(file);
                  });
                }}
                accept="image/*, pdf/*, doc/*"
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                  return (
                    <div
                      {...getRootProps({
                        onClick: (event) => event.stopPropagation(),
                      })}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <div
                          className={`input-group drag-and-drop${isDragActive ? ' dragging' : ''}${
                            isDragReject ? ' rejected' : ''
                          }`}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <p>
                            {isDragActive
                              ? isDragReject
                                ? t('dragAndDrop.rejected')
                                : t('dragAndDrop.dragging')
                              : t('dragAndDrop.label')}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <div className="input-group no-margin-top">
                            <ContentEditable
                              key={`${message.id}-${message.nonce}-${important}`}
                              ref={this.messageRef}
                              shouldUpdateContent={shouldUpdateContent}
                              className="message-input scrollable"
                              placeholder={t('chats.message.placeholder')}
                              onChange={(e) => {
                                this.setState(
                                  {
                                    message: e,
                                  },
                                  (e) => {
                                    this.handleChange(e);
                                  }
                                );
                              }}
                              onSubmit={(e) => {
                                this.handleSubmit(e);
                              }}
                              value={message}
                              darkMode={darkMode}
                              toolbarCustomButtons={[
                                <div
                                  className="action"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.dropzoneRef.open();
                                  }}
                                >
                                  <div className="icon">
                                    <ReactSVG src="/icons/attach.svg" />
                                  </div>
                                </div>,
                                <div
                                  className="action"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState({
                                      messageTemplatePopOver: true,
                                    });
                                  }}
                                >
                                  <div className={`icon`}>
                                    <ReactSVG src="/icons/messageTemplate.svg" />
                                  </div>
                                </div>,
                                <div
                                  className="action"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState(
                                      {
                                        important: !important,
                                      },
                                      (e) => {
                                        this.handleChange(e);
                                      }
                                    );
                                  }}
                                >
                                  <div className={`icon${important ? ' active' : ''}`}>
                                    <ReactSVG src="/icons/warning.svg" />
                                  </div>
                                </div>,
                              ]}
                            />
                          </div>
                          <button
                            className="send"
                            disabled={!message || (message || '').trim() === ''}
                            onClick={(e) => this.handleSubmit(e)}
                          >
                            <i className="fas fa-chevron-right"></i>
                          </button>
                        </div>
                      )}
                    </div>
                  );
                }}
              </Dropzone>
            </form>
          </div>
        </>
      );
    }

    return <Loader />;
  }
}
