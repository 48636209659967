import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import ReactSVG from 'react-svg';

import CloseButton from '@uicomponents/CloseButton';

import Association from '@models/general/Association';

import ItemsForm from './ItemsForm';
import TransportEquipmentForm from './TransportEquipmentForm';

class GoodsInput extends Component {
  static defaultProps = {
    flat: false,
  };

  state = {
    goods: this.props.goods || [],
  };

  addNewGoods = () => {
    const { goods } = this.state;
    this.setState({
      goods: [...goods, new Association('inline', {})],
    });
  };

  updateGoods = (newGood, index) => {
    const { onChange } = this.props;

    const { goods } = this.state;
    const newGoods = [...goods];
    const newAssociation = { ...newGoods[index] };
    newAssociation.entity = newGood;
    newGoods[index] = newAssociation;

    this.setState({
      goods: newGoods,
    });

    onChange?.(newGoods);
  };

  render() {
    const { t, onChange, flat } = this.props;
    const { goods } = this.state;

    const goodsTypes = [
      { label: t('goodsType.items'), value: 'items' },
      { label: t('goodsType.transportEquipment'), value: 'transportEquipment' },
    ];

    if (flat) {
      return (
        <>
          {goods
            .map((association) => association.entity)
            .map((good, index) => (
              <div
                className="list-sector good-form-element flat"
                key={`input-good-${index}-${good.id}-${good.nonce}-${goods.type}`}
              >
                <div className="flex-container justify-between no-wrap">
                  <h2>
                    {t('good.header', { count: index + 1 })} {good.name ? ` - ${good.name}` : null}
                  </h2>

                  <div className="list-actions">
                    <div></div>
                    <ReactSVG
                      src="/icons/duplicate.svg"
                      className="close-button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        const newGoods = [...goods];
                        newGoods.splice(
                          index,
                          0,
                          new Association('inline', { ...good, id: null, nonce: null })
                        );

                        this.setState({ goods: newGoods });
                        onChange?.(newGoods);
                      }}
                    />
                    <CloseButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        const newGoods = [...goods];
                        newGoods.splice(index, 1);

                        this.setState({ goods: newGoods });
                        onChange?.(newGoods);
                      }}
                    />
                  </div>
                </div>
                <Select
                  options={goodsTypes}
                  value={goodsTypes.find((type) => type.value === good.type)}
                  onChange={(newType) => {
                    this.updateGoods({ ...good, type: newType.value }, index);
                  }}
                />
                {good.type === 'items' ? (
                  <ItemsForm
                    {...this.props}
                    flat={true}
                    goods={good}
                    onChange={(newGood) => {
                      this.updateGoods(newGood, index);
                    }}
                  />
                ) : (
                  <TransportEquipmentForm
                    {...this.props}
                    flat={true}
                    goods={good}
                    onChange={(newGood) => {
                      this.updateGoods(newGood, index);
                    }}
                  />
                )}
              </div>
            ))}
          <div className="input-group left">
            <button type="button" onClick={(e) => this.addNewGoods(e)}>
              {t('form.label.addGoods')}
            </button>
          </div>
        </>
      );
    }
  }
}

export default withTranslation('translation')(GoodsInput);
