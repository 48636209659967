import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreatePricingPage from '@components/pricing/crud/CreatePricingPage.js';

import * as navigationActions from '@actions/navigationActions';
import * as pricingActions from '@actions/pricingActions';

class CreatePricingContainer extends PureComponent {
  render() {
    const { changeValue, createPricing, callback, ...props } = this.props;
    const { popStack } = this.props;
    return (
      <CreatePricingPage
        {...props}
        onChange={changeValue}
        createPricing={(pricing) =>
          createPricing(pricing).then((response) => {
            popStack?.();
            callback?.(response);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    
    languages: state.platform.languages,
    dataTypes: state.platform.dataTypes,
    currencies: state.platform.currencies,
    pricingEntityTypes: state.platform.pricingEntityTypes,
    pricingTriggerTypes: state.platform.pricingTriggerTypes,
    pricingModuleTypes: state.platform.pricingModuleTypes,
    pricingModuleSubUnits: state.platform.pricingModuleSubUnits,

    taxRates: state.settings.variables.taxRates,
    pricingCategories: state.settings.variables.pricingCategories,

    ...state.pricing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPricing: (pricing) => dispatch(pricingActions.createPricing(pricing)),
    changeValue: (name, value) =>
      dispatch(pricingActions.changeValue(name, value)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreatePricingContainer));
