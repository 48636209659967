import React, { Component } from 'react';

export default class BoardField extends Component {
  static defaultProps = {
    showLabel: true,
  };

  render() {
    const { size, index, showLabel, getLabel, value, getValue, divProps } = this.props;

    if (!getValue?.(value) && !getLabel?.(index)) return null;

    return (
      <div className={`field${size ? ' ' + size : ''}`}>
        {showLabel && getLabel && <label>{getLabel?.(index)}</label>}
        <div {...(divProps?.(value) || {})}>{getValue?.(value)}</div>
      </div>
    );
  }
}
