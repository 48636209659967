import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import { defaultDateTimeFormat } from '@utils/dateUtils';

class ChauffeurLongCard extends Component {
  render() {
    const { t, chauffeur, onClick, onRemove } = this.props;

    return (
      <div
        className="vehicle-chauffeurs__chauffeur clickeable"
        key={`vehicle.chauffeur-${chauffeur.id}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          // on click
          onClick?.(chauffeur);
        }}
      >
        <div className="flex-container justify-between">
          <div className="vehicle-chauffeurs__name">
            {`${chauffeur.firstName} ${chauffeur.lastName}`}
            <span className="vehicle-chauffeurs__type">{t(`chauffeurType.${chauffeur.type}`)}</span>
          </div>
          <div className="">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                // update trip with removal
                onRemove?.(chauffeur);
              }}
            >
              {t('form.detach')}
            </button>
          </div>
        </div>
        <div className="vehicle-chauffeurs__information">
          <div className="vehicle-chauffeurs__information info-field">
            <span>{t('chauffeur.hasADR')}: </span> {chauffeur.adr.hasADR ? t('yes') : t('no')}{' '}
          </div>
          <div className="vehicle-chauffeurs__information info-field">
            <span>{t('chauffeur.hasDriverLicense')}: </span>{' '}
            {chauffeur.driverLicense.hasDriverLicense ? t('yes') : t('no')}{' '}
          </div>
        </div>
        <div className="vehicle-chauffeurs__date">{defaultDateTimeFormat(chauffeur.createdAt)}</div>
      </div>
    );
  }
}
export default withTranslation('translation')(ChauffeurLongCard);
