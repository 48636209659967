import { alertConstants, socketConstants } from '@constants';

const initialState = {
  isFetchingAlert: false,
  formAlert: null,
  alert: null,

  isFetchingAlerts: false,
  isFetchingAlertsFrom: 0,
  alertsTotalResults: 0,
  alertsLoaded: 0,
  alerts: [],
};

export default function alert(state = initialState, action) {
  switch (action.type) {
    case alertConstants.ALERT_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case alertConstants.GET_ALERTS_STARTED:
      return { ...state, isFetchingAlerts: true, isFetchingAlertsFrom: action.from || 0 };
    case alertConstants.GET_ALERTS_FAILURE:
      return { ...state, isFetchingAlerts: false, alerts: [], error: action.message || '' };
    case alertConstants.GET_ALERTS_SUCCESS:
      return {
        ...state,
        isFetchingAlerts: false,
        alerts: action.from > 0 ? [...state.alerts, ...action.alerts] : action.alerts,
        alertsLoaded:
          action.from > 0 ? state.alerts.length + action.alerts.length : action.alerts.length,
        alertsTotalResults: action.totalResults || 0,
      };

    case alertConstants.CREATING_ALERT_STARTED:
      return { ...state, isFetchingAlert: true };
    case alertConstants.CREATING_ALERT_FAILURE:
      return { ...state, isFetchingAlert: false, alert: null, error: action.message || '' };
    case alertConstants.CREATING_ALERT_SUCCESS:
      return {
        ...state,
        isFetchingAlert: false,
        alert: action.alert,
        alerts: [action.alert, ...state.alerts],
      };

    case alertConstants.UPDATING_ALERT_STARTED:
      return { ...state, isFetchingAlert: true };
    case alertConstants.UPDATING_ALERT_FAILURE:
      return { ...state, isFetchingAlert: false, alert: null, error: action.message || '' };
    case alertConstants.UPDATING_ALERT_SUCCESS:
      return {
        ...state,
        isFetchingAlert: false,
        alert: action.alert,
        alerts: [...state.alerts].map((alert) =>
          action.alert.id === alert.id ? action.alert : alert
        ),
      };

    case socketConstants.NEW_ALERT:
      return {
        ...state,
        alerts: action.isWithinViewFilters
          ? state.alerts.map((alert) => alert.id).includes(action.value?.id)
            ? [...state.alerts].map((alert) =>
                alert.id === action.value.id ? { ...action.value } : alert
              )
            : [...state.alerts, action.value]
          : [...state.alerts].filter((alert) => action.value?.id !== alert.id),
      };
    default:
      return state;
  }
}
