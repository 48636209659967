import moment from 'moment';

import { store } from '@/index';

import { prefixZeroFormat } from './numberUtils';

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  let dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 1) {
    dates.push(currDate.clone());
  }

  return dates;
};

export const dateToDay = (date) => {
  return moment(date).format('dddd');
};

export const momentToDuration = (dur) => {
  var days = Math.floor(dur.asDays());
  var hours = Math.floor(dur.asHours()) % 24;
  var mins = Math.floor(dur.asMinutes()) % 60;
  var sec = Math.floor(dur.asSeconds()) % 60;

  if (days > 0) {
    return (
      days +
      'd ' +
      prefixZeroFormat(hours) +
      ':' +
      prefixZeroFormat(mins) +
      ':' +
      prefixZeroFormat(sec)
    );
  } else {
    return prefixZeroFormat(hours) + ':' + prefixZeroFormat(mins) + ':' + prefixZeroFormat(sec);
  }
};

export const momentToDurationTripCard = (dur) => {
  if (dur) {
    var days = Math.floor(dur.asDays());
    var hours = Math.floor(dur.asHours()) % 24;
    var mins = Math.floor(dur.asMinutes()) % 60;

    if (days > 0) {
      return days + 'd ' + prefixZeroFormat(hours) + 'h' + prefixZeroFormat(mins) + 'm';
    } else {
      return prefixZeroFormat(hours) + 'h' + prefixZeroFormat(mins) + 'm';
    }
  }
  return prefixZeroFormat(0) + 'h' + prefixZeroFormat(0) + 'm';
};

export const getAllDaysOfMonth = (currentDate) => {
  let monthIndex = parseInt(moment(currentDate).format('M')) - 1; // 0..11 instead of 1..12
  let date = new Date(parseInt(moment(currentDate).format('Y')), monthIndex, 1);
  let dates = [];
  while (date.getMonth() === monthIndex) {
    dates.push(moment(date).format());
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

export const getAllDaysOfWeek = (currentDate) => {
  let current = currentDate.clone().startOf('isoWeek').toDate();
  let week = [];
  // Starting Monday not Sunday
  current.setDate(current.getDate() - current.getDay() + 1);
  for (let i = 0; i < 7; i++) {
    week.push(moment(new Date(current)).format());
    current.setDate(current.getDate() + 1);
  }
  return week;
};

export const hoursOfDay = (currentDate) => {
  let hours = [];
  for (let hour = 0; hour < 24; hour++) {
    hours.push(moment(`${currentDate}${hour}:00:00`, 'DD/MM/YYYY[T]HH:mm:ss'));
  }
  return hours;
};

export const navigateToActiveDate = () => {
  const section = document.querySelector('.header-lane__content-box.active');
  section &&
    section.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'center',
    });
};

export const getShortMonthName = (monthNumber) => {
  return moment(monthNumber, 'M').format('MMMM').slice(0, 3);
};

export const getFullMonthName = (monthNumber) => {
  return moment(monthNumber, 'M').format('MMMM');
};

export const momentFormatWithoutLiterals = (momentFormatWithoutLiterals) =>
  momentFormatWithoutLiterals
    .replace(/LT/g, 'p')
    .replace(/LTS/g, 'pp')
    .replace(/LLLL|llll/g, 'PPPPp')
    .replace(/LLL|lll/g, 'PPp')
    .replace(/L|l/g, 'P')
    .replace(/Y/g, 'y')
    .replace(/g/g, 'Y')
    .replace(/G/g, 'R')
    .replace(/W/g, 'l')
    .replace(/E/g, 'i')
    .replace(/(D+|d+)o?/g, (inp) => {
      switch (inp) {
        case 'D': // 1 2 ... 30 31
        case 'Do': // 1st 2nd ... 30th 31st
        case 'DD': // 01 02 ... 30 31
          return inp.replace(/D/g, 'd');
        case 'DDD': // 1 2 ... 364 365
          return 'D';
        case 'DDDo': // 1st 2nd ... 364th 365th
          return 'Do';
        case 'DDDD': // 001 002 ... 364 365
          return 'DDD';

        case 'dd': // Su Mo ... Fr Sa
          return 'eeeeee';
        case 'd': // 0 1 ... 5 6
        case 'do': // 0th 1st ... 5th 6th
        case 'ddd': // Sun Mon ... Fri Sat
        case 'dddd': // Sunday Monday ... Friday Saturday
          return inp.replace(/d/g, 'e'); // will actually return value between 1 and 7 for the numerical results
      }
      return inp;
    })
    .replace(/a/g, 'aaaa')
    .replace(/A/g, 'aa')
    .replace(/X/g, 't')
    .replace(/x/g, 'T')
    .replace(/ZZ|zz/g, 'xx')
    .replace(/Z|z/g, 'xxx');

/*
   @description default date format
 */
export const defaultDateFormat = (date, dateFormat) => {
  if (!date) return null;

  return moment(date).format(dateFormat);
};
export const defaultDateTimeFormat = (date, dateFormat) => {
  dateFormat = dateFormat || store.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY';
  if (!date) return null;

  const timeFormat = 'HH:mm';
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  return moment(date).format(dateTimeFormat);
};

export const defaultTimeDateFormat = (date, dateFormat) => {
  dateFormat = dateFormat || store.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY';
  if (!date) return null;

  const timeFormat = 'HH:mm';
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  return moment(date).format(dateTimeFormat);
};
