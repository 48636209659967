import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import TransportEquipmentLongCard from '@components/goods/TransportEquipmentLongCard';
import AttachTransportEquipmentContainer from '@containers/vehicle/crud/AttachTransportEquipmentContainer';

import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';

class TripTransportEquipmentsView extends Component {
  render() {
    const {
      t,
      trip,
      transportEquipments,
      isFetchingTransportEquipments,
      isFetchingTransportEquipmentsFrom,
      addToStack,
      updateTrip,
    } = this.props;

    return (
      <div className="vehicle-transportEquipments ">
        <div className="flex-container justify-between">
          <div />
          <div className="actions">
            <button
              onClick={(e) => {
                e.preventDefault();

                addToStack({
                  name: t('vehicle.transportEquipments.attach'),
                  component: (
                    <AttachTransportEquipmentContainer
                      onChange={(transportEquipment) => {
                        const newTrip = {
                          ...trip,
                          transportEquipments: [...trip.transportEquipments, transportEquipment],
                        };
                        updateTrip(newTrip, false);
                      }}
                    />
                  ),
                });
              }}
            >
              {t('form.label.attachTransportEquipment')}
            </button>
          </div>
        </div>
        <div className="scrollable">
          {isFetchingTransportEquipmentsFrom < 1 && isFetchingTransportEquipments ? (
            <Loader />
          ) : transportEquipments.length > 0 ? (
            <>
              {transportEquipments.map((transportEquipment) => (
                <TransportEquipmentLongCard
                  transportEquipment={transportEquipment}
                  onRemove={(transportEquipment) => {
                    const newTrip = {
                      ...trip,
                      transportEquipments: trip.transportEquipments.filter(
                        (cf) => cf.id !== transportEquipment.id
                      ),
                    };
                    updateTrip(newTrip, false);
                  }}
                />
              ))}
              {isFetchingTransportEquipments && <Loader />}
            </>
          ) : (
            <div className="not-found-action-box">
              <div className="icon">
                <ReactSVG src="/icons/space.svg" />
              </div>
              <NoData>{t('vehicle.transportEquipments.notFound')}</NoData>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(TripTransportEquipmentsView);
