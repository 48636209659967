import React, { Component } from 'react';

import ItemCard from './ItemCard';
import TransportEquipmentCard from './TransportEquipmentCard';

export default class GoodsCard extends Component {
  render() {
    const { goods } = this.props;

    return goods.type === 'transportEquipment' ? (
      <TransportEquipmentCard {...this.props} />
    ) : (
      <ItemCard {...this.props} />
    );
  }
}
