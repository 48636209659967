import React, { Component } from 'react';

import ChauffeurInput from '@components/chauffeur/ChauffeurInput';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

export default class AttachChauffeurPage extends Component {
  state = {
    chauffeur: null,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onChange, popStack } = this.props;
    const { chauffeur } = this.state;

    onChange?.(chauffeur);
    popStack?.();
  };

  render() {
    const { t, isAttachingChauffeur } = this.props;
    const { chauffeur } = this.state;

    if (isAttachingChauffeur) {
      return <Loader />;
    }

    return (
      <AsideView>
        <h1>{t('vehicle.chauffeurs.attach')}</h1>
        <div className="step-form" key={chauffeur?.id}>
          <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="input-container">
              <ChauffeurInput
                key={chauffeur ? chauffeur : null}
                chauffeur={chauffeur ? chauffeur : null}
                onChange={(newChauffeur) => {
                  this.setState({
                    chauffeur: newChauffeur,
                  });
                }}
              />
            </div>
            <div className="input-group more right">
              <input type="submit" onClick={(e) => this.handleSubmit(e)} value={t('form.attach')} />
            </div>
          </form>
        </div>
      </AsideView>
    );
  }
}
