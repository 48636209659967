
export const alertConstants = {
    ALERT_CHANGE_VALUE: "ALERT_CHANGE_VALUE",

    GET_ALERT_STARTED: "GET_ALERT_STARTED",
    GET_ALERT_SUCCESS: "GET_ALERT_SUCCESS",
    GET_ALERT_FAILURE: "GET_ALERT_FAILURE",

    GET_ALERTS_STARTED: "GET_ALERTS_STARTED",
    GET_ALERTS_SUCCESS: "GET_ALERTS_SUCCESS",
    GET_ALERTS_FAILURE: "GET_ALERTS_FAILURE",

    SEARCH_ALERTS_STARTED: "SEARCH_ALERTS_STARTED",
    SEARCH_ALERTS_SUCCESS: "SEARCH_ALERTS_SUCCESS",
    SEARCH_ALERTS_FAILURE: "SEARCH_ALERTS_FAILURE",

    CREATING_ALERT_STARTED: "CREATING_ALERT_STARTED",
    CREATING_ALERT_SUCCESS: "CREATING_ALERT_SUCCESS",
    CREATING_ALERT_FAILURE: "CREATING_ALERT_FAILURE",

    UPDATING_ALERT_STARTED: "UPDATING_ALERT_STARTED",
    UPDATING_ALERT_SUCCESS: "UPDATING_ALERT_SUCCESS",
    UPDATING_ALERT_FAILURE: "UPDATING_ALERT_FAILURE",

    DELETE_ALERT_STARTED: "DELETE_ALERT_STARTED",
    DELETE_ALERT_SUCCESS: "DELETE_ALERT_SUCCESS",
    DELETE_ALERT_FAILURE: "DELETE_ALERT_FAILURE",
};
