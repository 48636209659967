import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';

import * as alertActions from '@actions/alertActions';
import * as navigationActions from '@actions/navigationActions';

import { containsPermissionPart } from '@utils/userUtils';

import AlertBadge from './AlertBadge';

class EntityAlerts extends Component {
  static defaultProps = {
    context: '',
    entityType: '',
    entityId: '',
    entities: [],
  };

  state = {
    alertIndex: 0,
  };

  async componentDidMount() {
    const { context, platform, entities, entityType, entityId, allByEntities, allByEntity } =
      this.props;

    if (
      platform?.features?.map((feature) => feature.name)?.includes('alerts') &&
      containsPermissionPart('alert')
    ) {
      if (entities.length > 0) {
        allByEntities(context, entities);
      }

      if (entityType && entityId) {
        allByEntity(context, entityType, entityId);
      }
    }
  }

  render() {
    const { alertIndex } = this.state;
    const { addToStack, alerts, ignore, solve } = this.props;

    if (alerts.length <= 0) return null;

    return (
      <div className="alert-badges">
        <div className="alert">
          <AlertBadge
            key={alerts[alertIndex]?.id}
            alert={alerts[alertIndex]}
            addToStack={addToStack}
            ignore={ignore}
            solve={solve}
          />
        </div>

        <div className="back-and-fourth">
          <div
            className="back-and-fourth__btn-left  cursor-pointer"
            onClick={() => {
              this.setState({
                alertIndex: alertIndex > 0 ? alertIndex - 1 : alertIndex,
              });
            }}
          >
            <ReactSVG src="/icons/arrow-down.svg" />
          </div>
          <div className="back-and-fourth__info">
            <b>{alertIndex + 1}</b>/{alerts.length}
          </div>
          <div
            className="back-and-fourth__btn-right  cursor-pointer"
            onClick={() => {
              this.setState({
                alertIndex: alertIndex < alerts.length - 1 ? alertIndex + 1 : alertIndex,
              });
            }}
          >
            <ReactSVG src="/icons/arrow-down.svg" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,
    alerts: state.alert.alerts,

    ownProps,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    allByEntity: (context, entityType, entityId) =>
      dispatch(alertActions.allByEntity(context, entityType, entityId)),
    allByEntities: (context, entities) => dispatch(alertActions.allByEntities(context, entities)),

    ignore: (alert) => dispatch(alertActions.ignore(alert)),
    solve: (alert) => dispatch(alertActions.solve(alert)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(EntityAlerts));
