import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Pricing from '@models/pricing/Pricing';

import PricingForm from './PricingForm';

export default class CreatePricingPage extends Component {
  componentDidMount() {
    const { onChange } = this.props;
    onChange('formPricing', new Pricing());
  }

  render() {
    const { t, formPricing, isFetchingPricing, createPricing } = this.props;

    if (!formPricing || isFetchingPricing) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('pricing.new')}</h1>
        <PricingForm
          {...this.props}
          key={formPricing.id}
          onSubmit={(pricing) => createPricing(pricing)}
        />
      </AsideView>
    );
  }
}
