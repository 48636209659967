import React, { Component } from 'react';

import moment from 'moment';

import { getAllGoods } from '@utils/consignmentUtils';
import { defaultTimeDateFormat } from '@utils/dateUtils';
import { administrativeReferenceToString } from '@utils/locationUtils';

const colorImageMap = {
  green: 'green.png',
  black: 'black.png',
  red: 'red.jpg',
  blue: 'blue.png',
};
class CMRPrint extends Component {
  static defaultProps = {
    colors: ['green', 'black', 'blue', 'red'],
  };
  render() {
    const {
      formRef,
      style,
      colors,
      customer,
      firstAction,
      lastAction,
      consignment,
      transportOrder,
    } = this.props;

    const deliveryTerms = transportOrder.deliveryTerms;
    const executingBusiness =
      transportOrder.executingBusiness || transportOrder.administration?.business;

    const allGoods = getAllGoods(consignment);
    return (
      <>
        {colors?.map((color) => (
          <div
            ref={formRef?.()}
            className="printArea"
            id="printArea"
            style={{
              background: `url("/images/cmr/${colorImageMap[color]}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '210mm',
              height: '299.5mm',
              position: 'relative',
              fontSize: '10px',
              ...style,
            }}
          >
            <div
              className="consignor"
              style={{
                position: 'absolute',
                top: '15mm',
                left: '15.88mm',
                width: '88mm',
                height: '19mm',
              }}
            >
              {consignment?.originalLegalSender ? (
                <>
                  <b>{consignment?.originalLegalSender?.name}</b>
                  {administrativeReferenceToString(
                    consignment?.originalLegalSender?.administrativeReference || {},
                    false,
                    false,
                    consignment?.originalLegalSender
                  )}
                </>
              ) : (
                <>
                  <b>{customer?.business?.name}</b>
                  {administrativeReferenceToString(
                    customer?.business?.locations?.[0]?.entity?.administrativeReference || {},
                    false,
                    false,
                    customer?.business?.locations?.[0]?.entity
                  )}
                </>
              )}
            </div>

            <div
              className="transporterCode"
              style={{
                position: 'absolute',
                top: '2mm',
                right: '13.5mm',
                width: '22.5mm',
                height: '7mm',
              }}
            >
              {executingBusiness?.transportLicenseNumber &&
                executingBusiness.transportLicenseNumber}
            </div>

            <div
              className="consignee"
              style={{
                position: 'absolute',
                top: '41mm',
                left: '15.88mm',
                width: '88mm',
                height: '19mm',
              }}
            >
              {consignment?.originalLegalAddressee
                ? administrativeReferenceToString(
                    consignment?.originalLegalAddressee?.administrativeReference || {},
                    true,
                    false,
                    consignment?.originalLegalAddressee
                  )
                : administrativeReferenceToString(
                    lastAction?.location?.entity?.administrativeReference || {},
                    true,
                    false,
                    lastAction?.location?.entity
                  )}
            </div>

            <div
              className="carrier"
              style={{
                position: 'absolute',
                top: '41mm',
                left: '107mm',
                width: '88mm',
                height: '19mm',
              }}
            >
              {executingBusiness?.locations?.length > 0 && (
                <>
                  <b>{executingBusiness.name}</b>
                  {' - '}
                  {administrativeReferenceToString(
                    executingBusiness?.locations[0]?.entity?.administrativeReference || {},
                    false,
                    false,
                    executingBusiness?.locations[0]?.entity
                  )}
                </>
              )}
            </div>

            <div
              className="deliveryPlace"
              style={{
                position: 'absolute',
                top: '66mm',
                left: '15.88mm',
                width: '88mm',
                height: '11mm',
              }}
            >
              {administrativeReferenceToString(
                lastAction?.location?.entity?.administrativeReference || {},
                true,
                false,
                lastAction?.location?.entity
              )}
            </div>

            <div
              className="successiveCarriers"
              style={{
                position: 'absolute',
                top: '66mm',
                left: '107mm',
                width: '88mm',
                height: '11mm',
              }}
            >
              {executingBusiness?.locations?.length > 0 && (
                <>
                  <b>{executingBusiness.name}</b>
                  {' - '}
                  {administrativeReferenceToString(
                    executingBusiness?.locations[0]?.entity?.administrativeReference || {},
                    false,
                    false,
                    executingBusiness?.locations[0]?.entity
                  )}
                </>
              )}
            </div>

            <div
              className="goodsTakeoverPlace"
              style={{
                position: 'absolute',
                top: '83mm',
                left: '15.88mm',
                width: '88mm',
                height: '11mm',
              }}
            >
              {administrativeReferenceToString(
                firstAction?.location?.entity?.administrativeReference || {},
                true,
                false,
                firstAction?.location?.entity
              )}
              {' - '}
              {defaultTimeDateFormat(
                firstAction?.startTime ||
                  firstAction?.constraints?.find(
                    (item) => item.entity.type === 'startDateTimeConstraint'
                  )?.entity.value.startDateTime
              )}
            </div>

            <div
              className="documents"
              style={{
                position: 'absolute',
                top: '99mm',
                left: '15.88mm',
                width: '88mm',
                height: '11mm',
              }}
            >
              {[...consignment.documents, ...transportOrder.documents]
                .map((association) => association.entity)
                .map((document, index) => (
                  <span key={`document-${document.id}`}>
                    #{index + 1} {document?.file?.originalName}&nbsp;
                  </span>
                ))}
            </div>

            <div
              className="carrierReservations"
              style={{
                position: 'absolute',
                top: '83mm',
                left: '107mm',
                width: '88mm',
                height: '28mm',
              }}
            >
              {lastAction?.remark}
            </div>

            <div
              className="goodsDetails"
              style={{
                position: 'absolute',
                top: '118mm',
                left: '15.88mm',
                width: '180mm',
                height: '68mm',
              }}
            >
              {allGoods.map((good, index) => (
                <div key={`good-${good.id}`} style={{ display: 'flex' }}>
                  <span style={{ textAlign: 'left', width: '30mm', paddingLeft: '1mm' }}>{`${
                    good?.description || ''
                  } ${good?.hsCode || ''}`}</span>
                  <span style={{ textAlign: 'left', width: '24mm', paddingLeft: '1mm' }}>
                    {good?.quantity}
                  </span>
                  <span style={{ textAlign: 'left', width: '29mm', paddingLeft: '1mm' }}>
                    {good?.packagingMaterial || `${good.equipmentType} ${good.equipmentSubType}`}
                  </span>
                  <span style={{ textAlign: 'left', width: '30mm', paddingLeft: '1mm' }}>
                    {good?.productType}
                  </span>
                  <span style={{ textAlign: 'left', width: '22mm', paddingLeft: '1mm' }}>
                    {good?.hsCode}
                  </span>
                  <span style={{ textAlign: 'left', width: '23mm', paddingLeft: '1mm' }}>
                    {good?.weight?.value || good?.grossWeight?.value}
                  </span>
                  <span style={{ textAlign: 'left', width: '22mm', paddingLeft: '1mm' }}>
                    {(good?.length?.value || 0) *
                      (good?.height?.value || 0) *
                      (good?.width?.value || 0)}
                    <small>m3</small>
                  </span>
                </div>
              ))}
            </div>

            <div
              className="senderInstructions"
              style={{
                position: 'absolute',
                top: '193mm',
                left: '15.88mm',
                width: '88mm',
                height: '38mm',
              }}
            >
              {consignment.description || transportOrder.description}
            </div>

            <div
              className="specialAgreements"
              style={{
                position: 'absolute',
                top: '193mm',
                left: '107mm',
                width: '88mm',
                height: '21mm',
              }}
            >
              {consignment?.remark}
            </div>

            <div
              className="paymentDirections"
              style={{
                position: 'absolute',
                top: '237mm',
                left: '15.88mm',
                width: '88mm',
                height: '6.5mm',
              }}
            >
              {deliveryTerms}
            </div>

            <div
              className="establishedIn"
              style={{
                position: 'absolute',
                top: '250mm',
                left: '15.88mm',
                width: '88mm',
                height: '4.6mm',
              }}
            >
              {defaultTimeDateFormat(moment.now())}
            </div>

            <div
              className="reimbursement"
              style={{
                position: 'absolute',
                top: '245mm',
                left: '125.2mm',
                width: '29.5mm',
                height: '4.7mm',
              }}
            ></div>

            <div
              className="loadArrival"
              style={{
                position: 'absolute',
                top: '220mm',
                left: '130mm',
                width: '21.5mm',
                height: '10.5mm',
              }}
            >
              {defaultTimeDateFormat(
                firstAction?.startTime ||
                  firstAction?.constraints?.find(
                    (item) => item.entity.type === 'startDateTimeConstraint'
                  )?.entity.value.startDateTime
              )}
            </div>
            <div
              className="loadDeparture"
              style={{
                position: 'absolute',
                top: '220mm',
                left: '152.5mm',
                width: '21.5mm',
                height: '10.5mm',
              }}
            >
              {defaultTimeDateFormat(
                firstAction?.endTime ||
                  firstAction?.constraints?.find(
                    (item) => item.entity.type === 'endDateTimeConstraint'
                  )?.entity.value.endDateTime
              )}
            </div>
            <div
              className="loadTotalTime"
              style={{
                position: 'absolute',
                top: '220mm',
                left: '174.5mm',
                width: '21.5mm',
                height: '10.5mm',
              }}
            >
              {firstAction?.duration}
            </div>

            <div
              className="unloadArrival"
              style={{
                position: 'absolute',
                top: '232mm',
                left: '130mm',
                width: '21.5mm',
                height: '10.5mm',
              }}
            >
              {defaultTimeDateFormat(
                lastAction?.startTime ||
                  lastAction?.constraints?.find(
                    (item) => item.entity.type === 'startDateTimeConstraint'
                  )?.entity.value.startDateTime
              )}
            </div>
            <div
              className="unloadDeparture"
              style={{
                position: 'absolute',
                top: '232mm',
                left: '152.5mm',
                width: '21.5mm',
                height: '10.5mm',
              }}
            >
              {defaultTimeDateFormat(
                lastAction?.endTime ||
                  lastAction?.constraints?.find(
                    (item) => item.entity.type === 'endDateTimeConstraint'
                  )?.entity.value.endDateTime
              )}
            </div>
            <div
              className="unloadTotalTime"
              style={{
                position: 'absolute',
                top: '232mm',
                left: '174.5mm',
                width: '21.5mm',
                height: '10.5mm',
              }}
            >
              {lastAction?.duration}
            </div>
          </div>
        ))}
      </>
    );
  }
}

export default CMRPrint;
