import React, { Component } from 'react';

import ActionIcon from './ActionIcon';

export default class ActionIcons extends Component {
  static defaultProps = {
    namespace: '',
  };
  render() {
    const { name, className, namespace, icons } = this.props;

    return (
      <div
        className={`actions action-icons self-center align-items-center${
          className ? ` ${className}` : ''
        }`}
      >
        {icons
          .filter((icon) => icon)
          .map((icon) => (
            <ActionIcon
              key={`${name}-${icon?.name}-${icon?.type}-${icon?.icon}`}
              name={name}
              namespace={namespace}
              {...icon}
            />
          ))}
      </div>
    );
  }
}
