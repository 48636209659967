import React from 'react';

import { i18n, store } from '@/index';
import CreateSupportTicketContainer from '@containers/support/crud/CreateSupportTicketContainer';

import Alert from '@models/Alert';

import * as navigationActions from '@actions/navigationActions';

export function handleError(error) {
  const response = error.response || error;
  if (!error && !response) return null;

  if (response.request && (response.request.status === 401 || response.request.status === 403)) {
    // Go to logout if an authentication error occured when not logged in
    if (response.data.code === 403 && !window.location.href.includes('logout')) {
      if (store.getState().auth.isAuthenticated) {
        localStorage.setItem('authRedirectPath', window.location);
        window.location.href = '/auth/logout';
      }
    } else {
      store.dispatch(
        navigationActions.createAlert(
          new Alert(
            i18n.t('notification.noAuthorization.error.title'),
            i18n.t('notification.noAuthorization.error.description'),
            'error'
          )
        )
      );
    }
    return response.data;
  }

  if (response.request && response.request.status === 409) {
    store.dispatch(
      navigationActions.createAlert(
        new Alert(
          i18n.t('notification.conflict.error.title'),
          i18n.t('notification.conflict.error.description'),
          'error'
        )
      )
    );
  }

  if (response.request && response.request.status === 429) {
    store.dispatch(
      navigationActions.createAlert(
        new Alert(
          i18n.t('notification.tooManyRequests.error.title'),
          i18n.t('notification.tooManyRequests.error.description'),
          'error'
        )
      )
    );
  }
  if (response.request && response.request.status === 500) {
    store.dispatch(
      navigationActions.createAlert(
        new Alert(
          i18n.t('notification.error.title'),
          i18n.t('notification.error.description'),
          'error'
        )
      )
    );

    store.dispatch(
      navigationActions.addToStack({
        name: i18n.t('support.ticket.new.header'),
        component: (
          <CreateSupportTicketContainer
            onNavigateTo={(url) => {
              store.dispatch(navigationActions.popStack());
              history.push(url);
            }}
          />
        ),
      })
    );
  }
  return response.data;
}

export function handleResponse(response) {
  return response.data;
}
