import React, { Component } from 'react';

import TransportEquipmentInput from '@components/goods/TransportEquipmentInput';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

export default class AttachTransportEquipmentPage extends Component {
  state = {
    transportEquipment: null,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onChange, popStack } = this.props;
    const { transportEquipment } = this.state;

    onChange?.(transportEquipment);
    popStack?.();
  };
  render() {
    const { t, isAttachingTransportEquipment } = this.props;
    const { transportEquipment } = this.state;

    if (isAttachingTransportEquipment) {
      return <Loader />;
    }

    return (
      <AsideView>
        <h1>{t('vehicle.transportEquipments.attach')}</h1>
        <div className="step-form" key={transportEquipment?.id}>
          <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="input-container">
              <TransportEquipmentInput
                key={transportEquipment ? transportEquipment : null}
                transportEquipment={transportEquipment ? transportEquipment : null}
                onChange={(newTransportEquipment) => {
                  this.setState({
                    transportEquipment: newTransportEquipment,
                  });
                }}
              />
            </div>
            <div className="input-group more right">
              <input type="submit" onClick={(e) => this.handleSubmit(e)} value={t('form.attach')} />
            </div>
          </form>
        </div>
      </AsideView>
    );
  }
}
