import React, { Component } from 'react';

import { i18n, store } from '@/index';

import Switch from '@uicomponents/Switch';

import { flatten } from '@utils/arrayUtils';
import { activateInputs, deactivateInputs } from '@utils/formUtils';

class FormInput extends Component {
  static defaultProps = {
    type: 'text',
    parentName: '',
  };

  constructor(props) {
    super(props);
    this.state = { hiddenFields: [] };
  }

  componentDidMount() {
    activateInputs();

    const { hiddenTypeFields, parentName } = this.props;
    const storeState = store.getState();
    const userHiddenFields = storeState.settings?.settings?.userInterface?.hiddenFields || [];
    const platformHiddenFields = storeState.platform?.platform?.uiSettings?.hiddenFields || [];
    const settingsGroupsHiddenFields = flatten(
      storeState.settings?.settingsGroups?.map((sG) => sG?.hiddenFields || []) || []
    );

    const hiddenFields = [
      ...(hiddenTypeFields || []),
      ...userHiddenFields,
      ...platformHiddenFields,
      ...settingsGroupsHiddenFields,
    ]
      .map((fieldFilter) => {
        if (fieldFilter.includes(':')) {
          const filterParts = fieldFilter.split(':');

          if (filterParts[0]?.toLowerCase() == parentName?.toLowerCase()) {
            return filterParts?.pop();
          } else return null;
        } else return fieldFilter;
      })
      .filter((f) => f);

    this.setState({
      hiddenFields,
    });
  }

  componentDidUpdate(prevProps) {
    activateInputs();

    const { hiddenTypeFields, parentName } = this.props;

    // Compare the actual content of hiddenTypeFields instead of just the length
    if (JSON.stringify(prevProps.hiddenTypeFields) !== JSON.stringify(hiddenTypeFields)) {
      const storeState = store.getState();
      const userHiddenFields = storeState.settings?.settings?.userInterface?.hiddenFields || [];
      const platformHiddenFields = storeState.platform?.platform?.uiSettings?.hiddenFields || [];
      const settingsGroupsHiddenFields = flatten(
        storeState.settings?.settingsGroups?.map((sG) => sG?.hiddenFields || []) || []
      );

      const hiddenFields = [
        ...(hiddenTypeFields || []),
        ...userHiddenFields,
        ...platformHiddenFields,
        ...settingsGroupsHiddenFields,
      ]
        .map((fieldFilter) => {
          if (fieldFilter.includes(':')) {
            const filterParts = fieldFilter.split(':');

            if (filterParts[0]?.toLowerCase() == parentName?.toLowerCase()) {
              return filterParts?.pop();
            } else return null;
          } else return fieldFilter;
        })
        .filter((f) => f);

      this.setState({
        hiddenFields,
      });
    }
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleChange = (e, payload) => {
    const { onChange, setHasChanged } = this.props;
    if (setHasChanged) {
      setHasChanged(true);
    }
    if (onChange) {
      onChange(e, payload);
    } else {
      console.log('missing onChange');
    }
  };

  render() {
    const {
      id,
      max,
      min,
      type,
      name,
      label,
      value,
      onBlur,
      onFocus,
      onChange,
      inputRef,
      readOnly,
      disabled,
      required,
      maxLength,
      className,
      isDisabled,
      placeholder,
      wrapperClass,
      defaultValue,
      Component,
      ...props
    } = this.props;
    const { hiddenFields } = this.state;

    return (
      <>
        {!hiddenFields.includes((name || label)?.split?.('.')?.pop?.()) && (
          <div className={`input-group${wrapperClass ? ` ${wrapperClass}` : ''}`}>
            {type === 'switch' ? (
              <Switch
                ref={inputRef}
                id={id}
                name={name}
                className={`${className || ''}`}
                checked={value}
                onChange={(e, payload) =>
                  onChange ? onChange(e, payload) : console.log('missing onChange')
                }
                {...props}
              />
            ) : type === 'textarea' ? (
              <textarea
                ref={inputRef}
                id={id}
                name={name}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
                maxLength={maxLength}
                placeholder={placeholder || ''}
                className={`${className || ''}`}
                readOnly={readOnly}
                disabled={isDisabled || disabled}
                onChange={(e, payload) => this.handleChange(e, payload)}
                {...props}
              ></textarea>
            ) : type === 'component' ? (
              <>{Component}</>
            ) : (
              <input
                ref={inputRef}
                id={id}
                max={max}
                min={min}
                name={name}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
                readOnly={readOnly}
                type={type || 'text'}
                maxLength={maxLength}
                defaultValue={defaultValue}
                placeholder={placeholder || ''}
                className={`${className || ''}`}
                disabled={isDisabled || disabled}
                onChange={(e, payload) => this.handleChange(e, payload)}
                {...props}
              />
            )}
            {label && type !== 'component' && (
              <label>
                {i18n.t(label)}
                {required ? '*' : ''}
              </label>
            )}
          </div>
        )}
      </>
    );
  }
}

export default React.forwardRef((props, ref) => <FormInput {...props} inputRef={ref} />);
