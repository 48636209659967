import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import InformationComponent from '@uicomponents/InformationComponent';

class ContactPersonView extends Component {
  render() {
    const { t, contactPerson } = this.props;

    const fieldsToRender = [
      { name: 'firstName', type: 'text', entityType: 'contactPerson' },
      { name: 'lastName', type: 'text', entityType: 'contactPerson' },
      { name: 'email', type: 'email', entityType: 'contactPerson' },
      { name: 'phone', type: 'tel', entityType: 'contactPerson' },
      { name: 'position', type: 'text', entityType: 'contactPerson' },
    ];

    return (
      <div className="contact-detail">
        <InformationComponent entity={contactPerson} fields={fieldsToRender} />
      </div>
    );
  }
}
export default withTranslation('translation')(ContactPersonView);
