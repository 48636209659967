import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import FormInput from '@uiinputs/FormInput';

import { generateCredentials } from '@api/integrationApi';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import ExternalAttributesInput from '../../general/crud/ExternalAttributesInput';
import HeadersInput from './HeadersInput';

class CredentialsInput extends Component {
  static defaultProps = {
    enabledTypes: [],
  };
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      credentials: this.props.credentials || {},
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange = (credentials) => {
    const { onChange } = this.props;

    this.setState({ credentials }, () => {
      onChange(credentials);
    });
  };

  render() {
    const { t, integrationManualTypes, integrationManualType, enabledTypes, connectionType } =
      this.props;
    const { credentials } = this.state;
    const integrationManualTypeSelected = integrationManualTypes.find(
      (value) => value.type === integrationManualType?.value || ''
    );

    const credentialTypeOptions = [
      {
        value: 'basic',
        label: t('credentialType.basic'),
      },
      {
        value: 'apiKey',
        label: t('credentialType.apiKey'),
      },
      {
        value: 'OAuth',
        label: t('credentialType.oAuth'),
      },
      {
        value: 'accessKeys',
        label: t('credentialType.accessKeys'),
      },
      {
        value: 'bearer',
        label: t('credentialType.bearer'),
      },
      {
        value: 'openId',
        label: t('credentialType.openId'),
      },
      {
        value: 'headers',
        label: t('credentialType.headers'),
      },
    ].filter((credentialTypeOption) => {
      return (enabledTypes || [credentialTypeOption.value]).includes(credentialTypeOption.value);
    });

    var credentialsInput = null;
    const authenticationMethod =
      credentials.authenticationMethod ||
      (credentialTypeOptions.length > 0 ? credentialTypeOptions[0].value : null);

    switch (authenticationMethod) {
      case 'basic':
        credentialsInput = (
          <>
            <div>
              <FormInput
                type="text"
                required={true}
                wrapperClass="no-margin-top"
                label="form.label.username"
                readOnly={connectionType === 'web_hook'}
                value={credentials.username}
                onChange={(event) => {
                  const newCredentials = { ...credentials };
                  newCredentials.username = event.target.value;

                  this.onChange(newCredentials);
                }}
              />
              {this.validator.message(t('form.label.username'), credentials.username, 'required')}
            </div>
            <div className="input-group ">
              <div className="input-group no-margin-top">
                <input
                  readOnly={connectionType === 'web_hook'}
                  type={connectionType === 'web_hook' ? 'text' : 'password'}
                  value={credentials.password}
                  onChange={(event) => {
                    const newCredentials = { ...credentials };
                    newCredentials.password = event.target.value;

                    this.onChange(newCredentials);
                  }}
                />
                <label>{t('form.label.password')}*</label>
              </div>
              {this.validator.message(t('form.label.password'), credentials.password, 'required')}
            </div>
          </>
        );
        break;
      case 'apiKey':
        credentialsInput = (
          <div>
            <FormInput
              type="text"
              required={true}
              label="form.label.apiKey"
              readOnly={connectionType === 'web_hook'}
              value={credentials.apiKey}
              onChange={(event) => {
                const newCredentials = { ...credentials };
                newCredentials.apiKey = event.target.value;

                this.onChange(newCredentials);
              }}
            />
            {this.validator.message(t('form.label.apiKey'), credentials.apiKey, 'required')}
          </div>
        );
        break;
      case 'bearer':
        credentialsInput = (
          <div>
            <FormInput
              type="text"
              label="form.label.bearer"
              required={true}
              readOnly={connectionType === 'web_hook'}
              value={credentials.bearer}
              onChange={(event) => {
                const newCredentials = { ...credentials };
                newCredentials.bearer = event.target.value;

                this.onChange(newCredentials);
              }}
            />
            {this.validator.message(t('form.label.bearer'), credentials.bearer, 'required')}
          </div>
        );
        break;
      case 'openId':
        credentialsInput = (
          <>
            {credentials.oauthUrl && !credentials.openIdToken && (
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <a className="button" href={credentials.oauthUrl}>
                    {t('form.label.authorize')}
                  </a>
                </div>
              </div>
            )}
          </>
        );
        break;
      case 'accessKeys':
        credentialsInput = (
          <>
            <div>
              <FormInput
                type="text"
                label="form.label.accessKey"
                required={true}
                readOnly={connectionType === 'web_hook'}
                value={credentials.accessKey}
                onChange={(event) => {
                  const newCredentials = { ...credentials };
                  newCredentials.accessKey = event.target.value;

                  this.onChange(newCredentials);
                }}
              />
              {this.validator.message(t('form.label.accessKey'), credentials.accessKey, 'required')}
            </div>
            <div className="input-group ">
              <div className="input-group no-margin-top">
                <input
                  readOnly={connectionType === 'web_hook'}
                  type={connectionType === 'web_hook' ? 'text' : 'password'}
                  value={credentials.secretKey}
                  onChange={(event) => {
                    const newCredentials = { ...credentials };
                    newCredentials.secretKey = event.target.value;

                    this.onChange(newCredentials);
                  }}
                />
                <label>{t('form.label.secretKey')}*</label>
              </div>
              {this.validator.message(t('form.label.secretKey'), credentials.secretKey, 'required')}
            </div>
          </>
        );
        break;
      case 'OAuth':
        credentialsInput = (
          <>
            <div>
              <FormInput
                type="text"
                label="form.label.url"
                required={true}
                value={credentials.oauthUrl}
                onChange={(event) => {
                  const newCredentials = { ...credentials };
                  newCredentials.oauthUrl = event.target.value;

                  this.onChange(newCredentials);
                }}
              />
              {this.validator.message(t('form.label.url'), credentials.oauthUrl, 'required')}
            </div>
            <div>
              <FormInput
                type="text"
                label="form.label.grantType"
                required={true}
                value={credentials.grantType}
                onChange={(event) => {
                  const newCredentials = { ...credentials };
                  newCredentials.grantType = event.target.value;

                  this.onChange(newCredentials);
                }}
              />
              {this.validator.message(t('form.label.grantType'), credentials.grantType, 'required')}
            </div>
            <div>
              <FormInput
                type="text"
                label="form.label.accessKey"
                required={true}
                readOnly={connectionType === 'web_hook'}
                value={credentials.accessKey}
                onChange={(event) => {
                  const newCredentials = { ...credentials };
                  newCredentials.accessKey = event.target.value;

                  this.onChange(newCredentials);
                }}
              />
              {this.validator.message(t('form.label.accessKey'), credentials.accessKey, 'required')}
            </div>
            <div className="input-group ">
              <div className="input-group no-margin-top">
                <input
                  readOnly={connectionType === 'web_hook'}
                  type={connectionType === 'web_hook' ? 'text' : 'password'}
                  value={credentials.secretKey}
                  onChange={(event) => {
                    const newCredentials = { ...credentials };
                    newCredentials.secretKey = event.target.value;

                    this.onChange(newCredentials);
                  }}
                />
                <label>{t('form.label.secretKey')}*</label>
              </div>
              {this.validator.message(t('form.label.secretKey'), credentials.secretKey, 'required')}
            </div>
            <div>
              <FormInput
                type="text"
                label="form.label.scope"
                required={true}
                value={credentials.scope}
                onChange={(event) => {
                  const newCredentials = { ...credentials };
                  newCredentials.scope = event.target.value;

                  this.onChange(newCredentials);
                }}
              />
              {this.validator.message(t('form.label.scope'), credentials.scope, 'required')}
            </div>
          </>
        );
        break;
      default:
        credentialsInput = null;
    }

    return (
      <>
        <div className="input-group">
          <Select
            placeholder={t('form.label.selectCredentialsMethod')}
            noOptionsMessage={() => t('noOptions')}
            value={
              credentialTypeOptions.find(
                (option) => option.value === credentials.authenticationMethod
              ) || credentialTypeOptions.length > 0
                ? credentialTypeOptions[0]
                : null
            }
            options={credentialTypeOptions}
            onChange={(event) => {
              const newCredentials = { ...credentials };
              newCredentials.authenticationMethod = event.value;

              this.onChange(newCredentials);
            }}
          />
          {this.validator.message(t('form.label.type'), credentials.type, 'required')}
        </div>
        <div className="input-group">{credentialsInput}</div>
        {authenticationMethod !== 'openId' && (
          <>
            {connectionType !== 'web_hook' ? (
              <>
                <div className="input-group">
                  <ExternalAttributesInput
                    key={`${credentials.id} - ${integrationManualTypeSelected}`}
                    externalAttributes={credentials.externalAttributes}
                    required={integrationManualTypeSelected?.requiredExternalAttributes || []}
                    onChange={(externalAttributes) => {
                      const newCredentials = { ...credentials };
                      newCredentials.externalAttributes = externalAttributes;
                      this.onChange(newCredentials);
                    }}
                  />
                </div>
                <div className="input-group">
                  <HeadersInput
                    key={`${credentials.id} - ${integrationManualTypeSelected}`}
                    headers={credentials.headers}
                    onChange={(headers) => {
                      const newCredentials = { ...credentials };
                      newCredentials.headers = headers;
                      this.onChange(newCredentials);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="input-group">
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    generateCredentials(credentials).then((response) => {
                      this.onChange(response.credentials);
                    });
                  }}
                >
                  {t('form.generate')}
                </button>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
export default withTranslation('translation')(CredentialsInput);
