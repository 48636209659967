import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateChauffeurContainer from '@containers/chauffeur/crud/CreateChauffeurContainer';

import { search } from '@api/chauffeurApi';

import * as chauffeurActions from '@actions/chauffeurActions';
import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

const transformChauffeurToValue = (chauffeur) => ({
  label: `${chauffeur.firstName} ${chauffeur.lastName || ''}`,
  value: chauffeur.id,
  chauffeur: chauffeur,
});

class ChauffeurInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chauffeur: this.props.chauffeur ? transformChauffeurToValue(this.props.chauffeur) : null,
      chauffeurs: [],
    };
  }

  componentDidMount() {
    const { chauffeur, getChauffeur, chauffeurId } = this.props;

    if (!chauffeur && chauffeurId) {
      getChauffeur(chauffeurId).then((response) => {
        if (response.success) {
          this.setState({ chauffeur: transformChauffeurToValue(response.chauffeur) });
        }
      });
    }

    search({}, 0, 40).then((response) => {
      this.setState({
        chauffeurs: response.chauffeurs,
      });
    });
  }

  onChange = (newChauffeur) => {
    this.setState({
      chauffeur: newChauffeur,
    });
    const { onChange } = this.props;
    onChange && onChange(newChauffeur ? newChauffeur.chauffeur : null);
  };

  render() {
    const { t, isFetchingChauffeurs, addToStack } = this.props;
    const { chauffeur, chauffeurs } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          defaultOptions={chauffeurs.map((chauffeur) => transformChauffeurToValue(chauffeur))}
          placeholder={`${t('form.label.searchChauffeur')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingChauffeurs}
          isDisabled={isFetchingChauffeurs}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              search({ query: inputValue }, 0, 40).then((response) => {
                callback(
                  response.chauffeurs.map((chauffeur) => transformChauffeurToValue(chauffeur))
                );
              });
            }, 400);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('chauffeur.new'),
              component: (
                <CreateChauffeurContainer
                  targetName={inputValue}
                  callback={(newChauffeur) => {
                    this.onChange(transformChauffeurToValue(newChauffeur));
                  }}
                />
              ),
            });
          }}
          value={chauffeur}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popStack: () => dispatch(navigationActions.popStack()),
    getChauffeur: (id) => dispatch(chauffeurActions.getChauffeur(id)),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ChauffeurInput));
