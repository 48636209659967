import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import ChauffeurLongCard from '@components/chauffeur/ChauffeurLongCard';
import ChauffeurContainer from '@containers/chauffeur/ChauffeurContainer';
import AttachChauffeurContainer from '@containers/vehicle/crud/AttachChauffeurContainer';

import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';

import { defaultDateTimeFormat } from '@utils/dateUtils';

class VehicleChauffeursView extends Component {
  load = () => {
    const { vehicle, getVehicleChauffeurs } = this.props;
    getVehicleChauffeurs(vehicle.id);
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      t,
      vehicle,
      chauffeurs,
      isFetchingChauffeurs,
      isFetchingChauffeursFrom,
      addToStack,
      vehicleDetachChauffeur,
      dateFormat,
    } = this.props;

    return (
      <div className="vehicle-chauffeurs ">
        <div className="flex-container justify-between">
          <div />
          <div className="actions">
            <button
              onClick={(e) => {
                e.preventDefault();

                addToStack({
                  name: t('vehicle.chauffeurs.attach'),
                  component: <AttachChauffeurContainer vehicle={vehicle} />,
                });
              }}
            >
              {t('form.label.attachChauffeur')}
            </button>
          </div>
        </div>
        <div className="scrollable">
          {isFetchingChauffeursFrom < 1 && isFetchingChauffeurs ? (
            <Loader />
          ) : chauffeurs.length > 0 ? (
            <>
              {chauffeurs.map((chauffeur) => (
                <ChauffeurLongCard
                  chauffeur={chauffeur}
                  onClick={(chauffeur) => {
                    addToStack({
                      name: `${chauffeur.firstName} ${chauffeur.lastName}`,
                      className: 'lightgray',
                      component: <ChauffeurContainer chauffeurId={chauffeur.id} />,
                    });
                  }}
                  onRemove={(chauffeur) => {
                    vehicleDetachChauffeur(vehicle.id, {
                      type: 'detachChauffeur',
                      vehicle: vehicle,
                      chauffeur: chauffeur,
                    });
                  }}
                />
              ))}
              {isFetchingChauffeurs && <Loader />}
            </>
          ) : (
            <div className="not-found-action-box">
              <div className="icon">
                <ReactSVG src="/icons/space.svg" />
              </div>
              <NoData>{t('vehicle.chauffeurs.notFound')}</NoData>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(VehicleChauffeursView);
