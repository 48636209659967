import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import MessagesContainer from '@containers/chat/MessagesContainer';
import CreateMessageContainer from '@containers/chat/crud/CreateMessageContainer';

import Avatar from '@uicomponents/Avatar';
import Loader from '@uicomponents/Loader';
import OptionsMenu from '@uicomponents/OptionsMenu';

import UpdateChatContainer from '../../containers/chat/crud/UpdateChatContainer';
import ChatInfoView from './ChatInfoView';

class ChatPage extends Component {
  constructor(props) {
    super(props);
    this.messagesRef = React.createRef();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  componentWillUnmount() {
    this.messagesRef = null;
  }

  render() {
    const { t, changeValue, chatPopup, activeChat, activeChats, addToStack } = this.props;

    if (activeChat) {
      return (
        <div className="main">
          <div
            className="chat-header"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              chatPopup
                ? activeChat.maximize
                  ? activeChats?.length &&
                    changeValue('activeChats', [
                      ...activeChats.map((item) =>
                        item.id === activeChat.id
                          ? {
                              ...item,
                              maximize: false,
                            }
                          : item
                      ),
                    ])
                  : activeChats?.length &&
                    changeValue('activeChats', [
                      ...activeChats.map((item) =>
                        item.id === activeChat.id
                          ? {
                              ...item,
                              maximize: true,
                            }
                          : { ...item, maximize: false }
                      ),
                    ])
                : null;
            }}
          >
            <div className="thumbnail-title">
              <Avatar
                imageUrl={activeChat?.image?.url ? activeChat.image.url : '/images/group.svg'}
                name={activeChat.title}
              />
              <h2>{activeChat.title || t('chat.noTitle')}</h2>
            </div>

            <div className="icons">
              {chatPopup ? (
                <>
                  {activeChat.maximize ? (
                    <div
                      className="circle-icon-primary circle-md svg-lg"
                      onClick={() =>
                        activeChats?.length &&
                        changeValue('activeChats', [
                          ...activeChats.map((item) =>
                            item.id === activeChat.id
                              ? {
                                  ...item,
                                  maximize: false,
                                }
                              : item
                          ),
                        ])
                      }
                    >
                      <ReactSVG src="/icons/arrow-down.svg" />
                    </div>
                  ) : (
                    <div
                      className="circle-icon-primary circle-md svg-lg"
                      onClick={() =>
                        activeChats?.length &&
                        changeValue('activeChats', [
                          ...activeChats.map((item) =>
                            item.id === activeChat.id
                              ? {
                                  ...item,
                                  maximize: true,
                                }
                              : { ...item, maximize: false }
                          ),
                        ])
                      }
                    >
                      <ReactSVG src="/icons/arrow-up.svg" />
                    </div>
                  )}
                  <div
                    className="circle-icon-primary circle-md svg-lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      activeChats?.length &&
                        changeValue('activeChats', [
                          ...activeChats.filter((item) => item.id !== activeChat.id),
                        ]);
                    }}
                  >
                    <ReactSVG src="/icons/close.svg" />
                  </div>
                </>
              ) : null}

              {(!chatPopup || activeChat.maximize) && (
                <div
                  className="circle-icon-primary circle-md svg-lg svg-fix"
                  onClick={() => this.setState({ maximizeNewChat: true })}
                >
                  <OptionsMenu
                    className="self-center"
                    items={[
                      {
                        name: t('chat.edit'),
                        onClick: (e) => {
                          e.preventDefault();

                          addToStack({
                            name: activeChat.title,
                            component: <UpdateChatContainer activeChat={activeChat} />,
                          });
                        },
                      },
                      {
                        name: t('chat.information'),
                        onClick: (e) => {
                          e.preventDefault();

                          addToStack({
                            name: activeChat.title,
                            component: <ChatInfoView chat={activeChat} />,
                          });
                        },
                      },
                      {
                        name: t('form.delete'),
                        onClick: (e) => {
                          e.preventDefault();
                        },
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>

          {!chatPopup || (chatPopup && activeChat?.maximize) ? (
            <div className="chat-box">
              <MessagesContainer chat={activeChat} />
              <CreateMessageContainer
                onMessageCreate={() =>
                  (this.messagesRef.current.scrollTop = this.messagesRef.current.scrollHeigh)
                }
              />
            </div>
          ) : null}
        </div>
      );
    }
  }
}

export default withTranslation('translation')(ChatPage);
