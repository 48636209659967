import React, { Component, forwardRef } from 'react';

import { Editor } from 'react-draft-wysiwyg';

import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { filterEditorState } from 'draftjs-filters';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { i18n } from '@/index';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class ContentEditable extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  onChangeHandler = (html) => {
    const { onChange } = this.props;
    onChange(html);
  };

  myHandleReturn = (e) => {
    // if they hold the shift key, normal behavior should happen
    if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
      const { onSubmit } = this.props;
      onSubmit && onSubmit(e);
      return 'handled';
    }

    return 'not-handled';
  };

  onEditorStateChange = (nextState) => {
    const { editorState } = this.state;
    let filteredState = nextState;

    const shouldFilterPaste =
      nextState.getCurrentContent() !== editorState.getCurrentContent() &&
      (nextState.getLastChangeType() === 'insert-fragment' ||
        nextState.getLastChangeType() === 'insert-characters');

    if (shouldFilterPaste) {
      filteredState = filterEditorState(
        {
          blocks: [],
          styles: ['BOLD', 'ITALIC'],
          entities: [
            {
              type: 'LINK',
              attributes: ['url'],
            },
          ],
          maxNesting: 1,
          whitespacedCharacters: ['\n', '\t'],
        },
        filteredState
      );
    }
    console.log('setting editor state', nextState, convertToRaw(nextState.getCurrentContent()));
    this.setState({ editorState: filteredState });
  };

  handleValue = (value) => {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);

    this.setState({
      editorState,
    });
  };

  componentDidMount() {
    const { value } = this.props;
    this.handleValue(value);
  }

  componentDidUpdate(prevProps) {
    const { shouldUpdateContent, value } = this.props;
    if (
      prevProps.value !== value &&
      shouldUpdateContent &&
      prevProps.shouldUpdateContent !== shouldUpdateContent
    ) {
      this.handleValue(value);
    }
  }

  render() {
    const { editorState } = this.state;
    const { onChange, innerRef, darkMode, ...props } = this.props;

    return (
      <Editor
        ref={innerRef}
        editorState={editorState}
        wrapperClassName="editor-wrapper"
        editorClassName="message-input scrollable scrollable__y"
        toolbarClassName={`actions-bar${darkMode ? ' darkMode' : ''}`}
        handleReturn={this.myHandleReturn}
        onEditorStateChange={this.onEditorStateChange}
        onChange={(e) => {
          this.onChangeHandler(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        }}
        toolbar={{
          options: ['inline', 'emoji', 'history'],
          inline: {
            options: ['bold', 'italic'],
            className: 'actions',
          },
          history: {
            className: 'actions',
          },
          emoij: {
            className: 'actions',
          },
        }}
        localization={{
          locale: i18n.language,
        }}
        {...props}
      />
    );
  }
}
export default forwardRef((props, ref) => <ContentEditable innerRef={ref} {...props} />);
