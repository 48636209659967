import { renderRoutes } from '@routes';
import { lazy } from 'react';

const TransportOrdersContainer = lazy(() =>
  import('@containers/transportOrder/TransportOrdersContainer')
);
const PublicTransportOrderContainer = lazy(() =>
  import('@containers/transportOrder/PublicTransportOrderContainer')
);
const TransportOrderPDFContainer = lazy(() =>
  import('@containers/transportOrder/TransportOrderPDFContainer')
);

const TransportOrderCMRContainer = lazy(() =>
  import('@containers/transportOrder/TransportOrderCMRContainer')
);

const routes = [
  {
    paths: ['/:id/share/:token'],
    component: PublicTransportOrderContainer,
    type: 'open',
  },
  {
    paths: ['', '/:id', '/:id/update', '/create'],
    component: TransportOrdersContainer,
    type: 'portal',
  },
  {
    paths: ['/:id/pdf'],
    component: TransportOrderPDFContainer,
    type: 'open',
  },
  {
    paths: ['/:id/cmr'],
    component: TransportOrderCMRContainer,
    type: 'portal',
  },
];

const TransportOrderRoutes = (props) => renderRoutes(props, routes);
export default TransportOrderRoutes;
