import React, { Component } from 'react';

import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import moment from 'moment/moment';

import AdministrationInput from '@components/administration/crud/AdministrationInput';
import BusinessInput from '@components/business/BusinessInput';
import CapacityModule from '@components/capacity/crud/CapacityModule';
import ContractModule from '@components/contract/crud/ContractModule';
import IntegrationInput from '@components/integration/crud/IntegrationInput';
import MessageAutomationInput from '@components/messageAutomation/crud/MessageAutomationInput';
import PricingModulesInput from '@components/pricing/crud/PricingModulesInput';
import TransportOrderInput from '@components/transportOrder/TransportOrderInput';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import PricingModule from '@models/pricing/PricingModule';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import CapacityContractCommunicationMethodSelector from '../CapacityContractCommunicationMethodSelector';
import CapacityContractTypeSelector from './CapacityContractTypeSelector';

export default class CapacityContractForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, formCapacityContract, setHasChanged } = this.props;
    onSubmit && onSubmit(formCapacityContract);
    setHasChanged(false);
  }

  render() {
    const {
      t,
      onChange,
      formCapacityContract,
      pricingTriggerTypes,
      pricingEntityTypes,
      setHasChanged,
    } = this.props;

    const pricingTriggerTypeOptions = pricingTriggerTypes
      .filter((type) => (formCapacityContract.entityType === 'vehicle' ? type.timeBased : true))
      .map((type) => ({
        label: type,
        value: type,
      }));

    return (
      <div className="step-form" key={formCapacityContract.id}>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div>
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.name"
                required={true}
                value={formCapacityContract?.name}
                onChange={(event) => {
                  const newCapacityContract = { ...formCapacityContract };
                  newCapacityContract.name = event.target.value;

                  onChange('formCapacityContract', newCapacityContract, event);
                }}
              />
              {this.validator.message(t('form.label.name'), formCapacityContract?.name, 'required')}
            </div>

            <div className="input-group">
              <CapacityContractTypeSelector
                key={formCapacityContract.type}
                value={formCapacityContract.type}
                placeholder={t('form.label.selectCapacityContractType')}
                onChange={(e) => {
                  const newCapacityContract = { ...formCapacityContract };
                  newCapacityContract.type = e.value;

                  onChange('formCapacityContract', newCapacityContract, e);
                }}
              />
            </div>
            <div className="input-group">
              <CapacityContractCommunicationMethodSelector
                value={formCapacityContract.communicationMethod}
                placeholder={`${t('form.label.selectCommunicationMethod')}*`}
                onChange={(event) => {
                  const newCapacityContract = { ...formCapacityContract };
                  newCapacityContract.communicationMethod = event.value;

                  onChange('formCapacityContract', newCapacityContract, event);
                }}
              />
            </div>
            <div className="input-group">
              <AdministrationInput
                key={
                  formCapacityContract.administration ? formCapacityContract.administration : null
                }
                value={
                  formCapacityContract.administration ? formCapacityContract.administration : null
                }
                onChange={(newAdministration) => {
                  const newCapacityContract = { ...formCapacityContract };
                  newCapacityContract.administration = { ...newAdministration };

                  onChange('formCapacityContract', newCapacityContract);
                }}
              />
              {this.validator.message(
                t('form.label.administration'),
                formCapacityContract.administration,
                'required'
              )}
            </div>
            <div className="input-group">
              <BusinessInput
                key={formCapacityContract.business ? formCapacityContract.business : null}
                value={formCapacityContract.business ? formCapacityContract.business : null}
                onChange={(newBusiness) => {
                  const newCapacityContract = { ...formCapacityContract };
                  newCapacityContract.business = { ...newBusiness };

                  onChange('formCapacityContract', newCapacityContract);
                }}
              />
              {this.validator.message(
                t('form.label.business'),
                formCapacityContract.business,
                'required'
              )}
            </div>
            <div className="input-group no-margin-top">
              <ContractModule
                value={formCapacityContract.contract || {}}
                type={'capacity'}
                onChange={(e) => {
                  const newCapacityContract = { ...formCapacityContract };
                  newCapacityContract.contract = e;

                  onChange('formCapacityContract', newCapacityContract);
                }}
              />
            </div>
            {formCapacityContract?.type === 'permanentContract' ||
            formCapacityContract?.type === 'oneTimeContract' ? (
              <FormInput
                type="number"
                setHasChanged={setHasChanged}
                label="form.label.purchasedUnits"
                value={formCapacityContract?.purchasedUnits}
                onChange={(event) => {
                  const newCapacityContract = { ...formCapacityContract };
                  newCapacityContract.purchasedUnits = event.target.value;

                  onChange('formCapacityContract', newCapacityContract, event);
                }}
              />
            ) : null}
            <div className="input-group more flex-container">
              <div className="input-group no-margin-top one">
                <input
                  type="datetime-local"
                  value={
                    formCapacityContract?.startTime
                      ? moment(formCapacityContract.startTime).format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  onChange={(event) => {
                    const newCapacityContract = { ...formCapacityContract };
                    newCapacityContract.startTime = moment(event.target.value);

                    onChange('formCapacityContract', newCapacityContract, event);
                  }}
                />
                <label>{t('form.label.startTime')}</label>
              </div>
              <div className="input-group no-margin-top one">
                <input
                  type="datetime-local"
                  value={
                    formCapacityContract?.endTime
                      ? moment(formCapacityContract.endTime).format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  onChange={(event) => {
                    const newCapacityContract = { ...formCapacityContract };
                    newCapacityContract.endTime = moment(event.target.value);

                    onChange('formCapacityContract', newCapacityContract, event);
                  }}
                />
                <label>{t('form.label.endTime')}</label>
              </div>
            </div>
            {formCapacityContract?.communicationMethod === 'integration' && (
              <div className="input-group">
                <IntegrationInput
                  value={formCapacityContract.integration}
                  onChange={(newIntegration) => {
                    const newCapacityContract = { ...formCapacityContract };
                    newCapacityContract.integration = newIntegration;

                    onChange('formCapacityContract', newCapacityContract);
                  }}
                />
              </div>
            )}
            {formCapacityContract?.communicationMethod === 'messageAutomation' && (
              <div className="input-group">
                <MessageAutomationInput
                  value={formCapacityContract.messageAutomation}
                  onChange={(newMessageAutomation) => {
                    const newCapacityContract = { ...formCapacityContract };
                    newCapacityContract.messageAutomation = newMessageAutomation;

                    onChange('formCapacityContract', newCapacityContract);
                  }}
                />
              </div>
            )}
            {formCapacityContract?.type === 'orderContract' ? (
              <div className="input-group">
                <TransportOrderInput
                  key={formCapacityContract?.transportOrder}
                  value={formCapacityContract?.transportOrder}
                  onChange={(newTransportOrder) => {
                    const newCapacityContract = { ...formCapacityContract };
                    newCapacityContract.transportOrder = newTransportOrder;

                    onChange('formCapacityContract', newCapacityContract);
                  }}
                />
              </div>
            ) : null}

            {formCapacityContract?.type !== 'orderContract' ? (
              <div className="input-group">
                <CapacityModule
                  value={formCapacityContract.capacity || {}}
                  onChange={(e) => {
                    const newCapacityContract = { ...formCapacityContract };
                    newCapacityContract.capacity = e;

                    onChange('formCapacityContract', newCapacityContract);
                  }}
                />
              </div>
            ) : null}

            <div className="input-group">
              <h3>{t('form.label.modules')}</h3>
              <PricingModulesInput
                {...this.props}
                key={formCapacityContract.pricingModules}
                entityType={formCapacityContract.targetEntityType}
                modules={
                  formCapacityContract.pricingModules
                    ? [...formCapacityContract.pricingModules]
                    : [new PricingModule()]
                }
                onChange={(newModules) => {
                  const newCapacityContract = { ...formCapacityContract };
                  newCapacityContract.pricingModules = newModules;

                  onChange('formCapacityContract', newCapacityContract);
                }}
              />
            </div>
            <div className="input-group">
              <div>{t('form.label.selfBilling')}</div>
              <Switch
                checked={formCapacityContract.selfBilling}
                onChange={(e, newState) => {
                  const newCapacityContract = { ...formCapacityContract };
                  newCapacityContract.selfBilling = newState;

                  onChange('formCapacityContract', newCapacityContract);
                }}
              />
            </div>
            {formCapacityContract.selfBilling && (
              <>
                <div className="input-group">
                  <div className="input-grou no-margin-top">
                    <Select
                      placeholder={t('form.label.selectPricingTriggerType')}
                      noOptionsMessage={() => t('noOptions')}
                      value={pricingTriggerTypeOptions.find(
                        (option) => option.value === formCapacityContract.triggerType
                      )}
                      options={pricingTriggerTypeOptions}
                      onChange={(event) => {
                        const newCapacityContract = { ...formCapacityContract };
                        newCapacityContract.triggerType = event.value;

                        onChange('formCapacityContract', newCapacityContract);
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
