import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AttachChauffeurPage from '@components/vehicle/crud/AttachChauffeurPage.js';

import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

class AttachChauffeurContainer extends PureComponent {
  render() {
    const { onChange, vehicle, vehicleAttachChauffeur, popStack, ...props } = this.props;
    return (
      <AttachChauffeurPage
        {...props}
        onChange={(chauffeur) => {
          if (vehicle) {
            vehicleAttachChauffeur(vehicle, {
              type: 'attachChauffeur',
              chauffeur: chauffeur,
            }).then(() => {
              popStack();
            });
          } else {
            onChange?.(chauffeur);
            popStack();
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.vehicle,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vehicleAttachChauffeur: (vehicle, attachChauffeurAction) =>
      dispatch(vehicleActions.vehicleAttachChauffeur(vehicle, attachChauffeurAction)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(AttachChauffeurContainer));
