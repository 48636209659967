import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateAttributeContainer from '@containers/attribute/CreateAttributeContainer';

import * as attributeActions from '@actions/attributeActions';
import * as navigationActions from '@actions/navigationActions';

export const transformAttributeToValue = (attribute) => ({
  value: attribute.id,
  label: `${attribute.name}`,
  attribute: attribute,
});

class AttributesInput extends Component {
  constructor(props) {
    super(props);

    this.searchTimeout = null;

    this.state = {
      value: this.props.value
        ? this.props.value.map((attribute) => transformAttributeToValue(attribute))
        : [],
      attributes: [],
    };
  }

  componentDidMount() {
    const { searchAttributes } = this.props;
    searchAttributes({ query: '' }, 0, 40).then((response) => {
      this.setState({
        attributes: response.attributes.map((attribute) => transformAttributeToValue(attribute)),
      });
    });
  }

  onChange = (newAttributes) => {
    this.setState({
      value: newAttributes,
    });

    const { onChange } = this.props;
    onChange?.(
      newAttributes ? newAttributes.map((newAttribute) => newAttribute.attribute) || [] : []
    );
  };

  render() {
    const { t, isFetchingAttributes, searchAttributes, addToStack } = this.props;
    const { value, attributes } = this.state;

    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={true}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.selectAttributes')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingAttributes}
          isDisabled={isFetchingAttributes}
          defaultOptions={attributes}
          loadOptions={(inputValue, callback) => {
            if (inputValue && inputValue != '') {
              if (this.searchTimeout) clearTimeout(this.searchTimeout);
              this.searchTimeout = setTimeout(() => {
                searchAttributes({ query: inputValue }, 0, 40).then((response) => {
                  callback(
                    response.attributes.map((attribute) => transformAttributeToValue(attribute))
                  );
                });
              }, 400);
            } else {
              callback(attributes.map((attribute) => transformAttributeToValue(attribute)));
            }
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('attribute.new'),
              component: (
                <CreateAttributeContainer
                  targetName={inputValue}
                  callback={(newAttribute) => {
                    this.onChange([...value, transformAttributeToValue(newAttribute)]);
                  }}
                />
              ),
            });
          }}
          value={value}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchAttributes: (searchParameters, from, amount) =>
      dispatch(attributeActions.searchAttributes(searchParameters, from, amount)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(AttributesInput));
