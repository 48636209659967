import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import SimpleReactValidator from 'simple-react-validator';

import moment from 'moment';

import { i18n, store } from '@/index';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import { momentFormatWithoutLiterals } from '@utils/dateUtils';

export default class PricingVariableIndexForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = { selectedCurrency: null };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, formPricingVariableIndex } = this.props;

    onSubmit && onSubmit(formPricingVariableIndex);
  }

  render() {
    const { onChange, user, t, formPricingVariableIndex } = this.props;
    const { selectedCurrency } = this.state;

    return (
      <>
        <div
          className="step-form "
          key={`${formPricingVariableIndex.id}${formPricingVariableIndex.nonce}`}
        >
          <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="input-container">
              <div>
                <FormInput
                  type="text"
                  label="form.label.name"
                  required={true}
                  value={formPricingVariableIndex.name}
                  onChange={(event) => {
                    const newPricingVariableIndex = {
                      ...formPricingVariableIndex,
                    };
                    newPricingVariableIndex.name = event.target.value;
                    onChange('formPricingVariableIndex', newPricingVariableIndex, event);
                  }}
                />
                {this.validator.message(
                  t('form.label.name'),
                  formPricingVariableIndex.name,
                  'required'
                )}
              </div>
              <div>
                <FormInput
                  type="text"
                  label="form.label.variableName"
                  required={true}
                  value={formPricingVariableIndex.variableName}
                  onChange={(event) => {
                    const newPricingVariableIndex = {
                      ...formPricingVariableIndex,
                    };
                    newPricingVariableIndex.variableName = event.target.value.replace(
                      /[^a-zA-Z0-9-_]/g,
                      ''
                    );

                    onChange('formPricingVariableIndex', newPricingVariableIndex, event);
                  }}
                />
                {this.validator.message(
                  t('form.label.variableName'),
                  formPricingVariableIndex.variableName,
                  'required'
                )}
              </div>
              <FormInput
                type="number"
                label="form.label.value"
                step="any"
                value={formPricingVariableIndex.value}
                onChange={(e) => {
                  const newPricingVariableIndex = {
                    ...formPricingVariableIndex,
                  };
                  newPricingVariableIndex.value = e.target.value;

                  onChange('formPricingVariableIndex', newPricingVariableIndex);
                }}
              />
              <div className="input-group">
                <h2>{t('form.label.timeRelated')}</h2>
                <Switch
                  checked={formPricingVariableIndex.timeRelated}
                  onChange={(e, newValue) => {
                    const newPricingVariableIndex = {
                      ...formPricingVariableIndex,
                    };
                    newPricingVariableIndex.timeRelated = newValue;

                    onChange('formPricingVariableIndex', newPricingVariableIndex, e);
                  }}
                />
              </div>
              {formPricingVariableIndex.timeRelated && (
                <div className="input-group">
                  <div className="input-group no-margin-top">
                    <DatePicker
                      startDate={
                        formPricingVariableIndex.start
                          ? moment(formPricingVariableIndex.start)?.toDate()
                          : null
                      }
                      endDate={
                        formPricingVariableIndex.end
                          ? moment(formPricingVariableIndex.end)?.toDate()
                          : null
                      }
                      dateFormat={momentFormatWithoutLiterals(
                        store.getState().settings?.settings?.user?.language?.dateFormat ||
                          'DD/MM/YYYY'
                      )}
                      showWeekNumbers={true}
                      locale={i18n.language}
                      showTimeSelect
                      selectsRange
                      onChange={(dates) => {
                        const [start, end] = dates;

                        const newPricingVariableIndex = {
                          ...formPricingVariableIndex,
                          start: start ? moment(start) : null,
                          end: end ? moment(end) : null,
                        };

                        onChange('formPricingVariableIndex', newPricingVariableIndex, event);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="input-group more right">
                <input type="submit" disabled={!this.validator.allValid()} value={t('form.save')} />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
