import React, { Component, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import Select from 'react-select';
import ReactSVG from 'react-svg';

import { reactSelectCustomStyle } from '@/constants/dashboard';
import UpdateBusinessContainer from '@containers/business/crud/UpdateBusinessContainer';
import LocationContainer from '@containers/location/LocationContainer';

import Collapsible from '@uicomponents/Collapsible';
import Loader from '@uicomponents/Loader';

import Alert from '@models/Alert';

import { isExternal } from '@utils/userUtils';

import ContactDetailsView from '../general/ContactDetailsView';
import ContactPersonsView from '../general/ContactPersonsView';
import NotesView from '../note/NotesView';
import InformationComponent from '../ui/components/InformationComponent';

const BansContainer = lazy(() => import('@containers/ban/BansContainer'));
const ComplaintsContainer = lazy(() => import('@containers/complaint/ComplaintsContainer'));
const CertificatesContainer = lazy(() => import('@containers/certificate/CertificatesContainer'));
const DocumentsContainer = lazy(() => import('@containers/document/DocumentsContainer'));

class BusinessView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'business',
    };
  }

  render() {
    const { tab } = this.state;
    const {
      t,
      user,
      business,
      isFetchingBusiness,
      onChange,
      addToStack,
      createAlert,
      changeValue,
      verifyBusiness,
      unverifyBusiness,
    } = this.props;

    if (!business || isFetchingBusiness) {
      return <Loader />;
    }

    const fieldsToRender = [
      { name: 'coc', type: 'text', entityType: 'business' },
      { name: 'vat', type: 'text', entityType: 'business' },
      { name: 'website', type: 'link', entityType: 'business' },
      { name: 'description', type: 'text', entityType: 'business' },
      ...business.locations.map((association, index) => ({
        name: `location_${index}`,
        type: 'businessLocation',
        entityType: 'business',
        index,
        onClick: (location) => {
          addToStack({
            name: location.name,
            className: 'lightgray',
            component: <LocationContainer location={{ ...location }} />,
          });
        },
        value: association.entity,
      })),
    ];

    const verificationOptions = [
      { value: 'verify', label: t('verification.verify') },
      { value: 'unverify', label: t('verification.unverify') },
    ];

    return (
      <div className="business-view">
        <div className="flex-container justify-between no-wrap">
          <div>
            <div className="flex-container justify-between">
              <h1 className="no-margin flex-container align-items-end">
                {business.name}
                {business?.verified && (
                  <ReactSVG
                    src="/icons/verification-tick.svg"
                    beforeInjection={(svg) => {
                      svg.setAttribute('style', `fill: var(--primary-color); margin-left: 5px;`);
                    }}
                  />
                )}
              </h1>
            </div>
            <h3 className="no-margin-top">#{business.id}</h3>
          </div>
          {!isExternal(user) && (
            <>
              <div className="self-center flex-container no-wrap action-icons gap-10">
                <Select
                  options={verificationOptions}
                  styles={reactSelectCustomStyle({
                    menuWidth: '160px',
                    controlRounded: false,
                    selectionOptionColor: 'var(--white)',
                    border: '1px solid var(--primary-color)',
                    controlBackground: 'var(--white) !important',
                    controlBoxShadow: '0 0 0 1px var(--primary-color)',
                    activeBorderColor: 'var(--primary-color) !important',
                    optionHoverBackgroundColor: 'var(--primary-color)',
                    optionActiveBackgroundColor: 'var(--primary-color)',
                  })}
                  placeholder={t('verification')}
                  className="react-select__control"
                  onChange={(e) => {
                    if (e.value === 'verify') {
                      verifyBusiness(business)
                        .then((response) => {
                          if (response.success) {
                            createAlert(
                              new Alert(t('business.verify.succes.title'), '', 'success')
                            );
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                          createAlert(new Alert(t('business.verify.error.title'), '', 'error'));
                        });
                    } else if (e.value === 'unverify') {
                      unverifyBusiness(business)
                        .then((response) => {
                          if (response.success) {
                            createAlert(
                              new Alert(t('business.unverify.succes.title'), '', 'success')
                            );
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                          createAlert(new Alert(t('business.unverify.error.title'), '', 'error'));
                        });
                    }
                  }}
                />
                <div
                  className="action-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    addToStack({
                      name: t('business.update.header'),
                      component: (
                        <UpdateBusinessContainer
                          targetBusiness={{ ...business }}
                          callback={(result) => {
                            onChange('business', result.business || {});
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <ReactSVG src="/icons/pencil.svg" />
                </div>
              </div>
            </>
          )}
        </div>

        <ScrollContainer
          verticle={false}
          hideScrollbars={false}
          className="align-items-center d-flex gap-10 tab-links-container my-1"
        >
          <div
            className={`tab-links${tab === 'business' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'business' })}
          >
            {t('business.information')}
          </div>
          <div
            className={`tab-links ${tab === 'documents' ? 'tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'documents' })}
          >
            {t('documents.header')}
          </div>
          {!isExternal(user) && (
            <>
              <div
                className={`tab-links${tab === 'bans' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'bans' })}
              >
                {t('bans.header')}
              </div>
              <div
                className={`tab-links${tab === 'complaints' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'complaints' })}
              >
                {t('complaints.header')}
              </div>
            </>
          )}

          <div
            className={`tab-links${tab === 'certificates' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'certificates' })}
          >
            {t('certificates.header')}
          </div>
        </ScrollContainer>

        <section className={`business-view__box activity${tab === 'business' ? ' active' : ''}`}>
          {tab === 'business' ? (
            <>
              <InformationComponent entity={business} fields={fieldsToRender} />
              <Collapsible
                name={`${t('notes.header')}${
                  business.notes.length > 0 ? ` (${business.notes.length})` : ''
                }`}
                className="collapsible-element"
                defaultIsOpen={false}
              >
                <NotesView notes={business.notes} />
              </Collapsible>
              <Collapsible
                name={`${t('contactDetails.header')}${
                  business.contactDetails.length > 0 ? ` (${business.contactDetails.length})` : ''
                }`}
                className="collapsible-element"
                defaultIsOpen={false}
              >
                <ContactDetailsView contactDetails={business.contactDetails} />
              </Collapsible>
              <Collapsible
                name={`${t('contactPersons.header')}${
                  business?.contactPersons?.length > 0
                    ? ` (${business?.contactPersons?.length})`
                    : ''
                }`}
                className="collapsible-element"
                defaultIsOpen={false}
              >
                <ContactPersonsView contactPersons={business?.contactPersons || []} />
              </Collapsible>
            </>
          ) : null}
        </section>

        <section className={`business-view__box activity${tab === 'documents' ? ' active' : ''}`}>
          {tab === 'documents' && (
            <Suspense fallback={<>{<Loader />}</>}>
              <DocumentsContainer
                entity={business}
                entityType="business"
                onChange={(newBusiness) => {
                  const { updateBusiness } = this.props;

                  updateBusiness(newBusiness);
                }}
                callback={(newBusiness) => {
                  changeValue('business', newBusiness || {});
                }}
              />
            </Suspense>
          )}
        </section>
        {!isExternal(user) && (
          <>
            <section className={`business-view__box activity${tab === 'bans' ? ' active' : ''}`}>
              {tab === 'bans' ? (
                <Suspense fallback={<>{<Loader />}</>}>
                  <BansContainer entityType="business" entity={business} />
                </Suspense>
              ) : null}
            </section>
            <section
              className={`business-view__box activity${tab === 'complaints' ? ' active' : ''}`}
            >
              {tab === 'complaints' ? (
                <Suspense fallback={<>{<Loader />}</>}>
                  <ComplaintsContainer entityType="business" entity={business} />
                </Suspense>
              ) : null}
            </section>
            <section className={`business-view__box activity${tab === 'ledger' ? ' active' : ''}`}>
              {tab === 'ledger' ? (
                <Suspense fallback={<>{<Loader />}</>}>
                  <div className="ledger mt-10">
                    <LedgerTable
                      t={t}
                      key={business.id}
                      entityType={'business'}
                      data={ledgerEntries}
                    />
                  </div>{' '}
                </Suspense>
              ) : null}
            </section>
          </>
        )}

        <section
          className={`business-view__box activity${tab === 'certificates' ? ' active' : ''}`}
        >
          {tab === 'certificates' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <CertificatesContainer entityType="business" />
            </Suspense>
          ) : null}
        </section>
      </div>
    );
  }
}
export default withTranslation('translation')(BusinessView);
