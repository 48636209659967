import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import TransportEquipmentLongCard from '@components/goods/TransportEquipmentLongCard';
import AttachTransportEquipmentContainer from '@containers/vehicle/crud/AttachTransportEquipmentContainer';

import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';

class VehicleTransportEquipmentsView extends Component {
  load = () => {
    const { vehicle, getVehicleTransportEquipments } = this.props;
    getVehicleTransportEquipments?.(vehicle.id);
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      t,
      vehicle,
      transportEquipments,
      isFetchingTransportEquipments,
      isFetchingTransportEquipmentsFrom,
      addToStack,
      vehicleDetachTransportEquipment,
    } = this.props;

    return (
      <div className="vehicle-transportEquipments ">
        <div className="flex-container justify-between">
          <div />
          <div className="actions">
            <button
              onClick={(e) => {
                e.preventDefault();

                addToStack({
                  name: t('vehicle.transportEquipments.attach'),
                  component: <AttachTransportEquipmentContainer vehicle={vehicle} />,
                });
              }}
            >
              {t('form.label.attachTransportEquipment')}
            </button>
          </div>
        </div>
        <div className="scrollable">
          {isFetchingTransportEquipmentsFrom < 1 && isFetchingTransportEquipments ? (
            <Loader />
          ) : transportEquipments.length > 0 ? (
            <>
              {transportEquipments.map((transportEquipment) => (
                <TransportEquipmentLongCard
                  transportEquipment={transportEquipment}
                  onRemove={(transportEquipment) => {
                    vehicleDetachTransportEquipment(vehicle.id, {
                      type: 'detachTransportEquipment',
                      vehicle: vehicle,
                      transportEquipment: { ...transportEquipment },
                    });
                  }}
                />
              ))}
              {isFetchingTransportEquipments && <Loader />}
            </>
          ) : (
            <div className="not-found-action-box">
              <div className="icon">
                <ReactSVG src="/icons/space.svg" />
              </div>
              <NoData>{t('vehicle.transportEquipments.notFound')}</NoData>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(VehicleTransportEquipmentsView);
