import React from 'react';

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { defaultDateFormat } from '@utils/dateUtils';

// Create styles
import Montserrat500 from '@/resources/fonts/montserrat-500.ttf';
import Montserrat700 from '@/resources/fonts/montserrat-700.ttf';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      fontWeight: 500,
      src: Montserrat500,
    },
    {
      fontWeight: 700,
      src: Montserrat700,
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 10,
    fontWeight: 500,
    fontFamily: 'Montserrat',
  },

  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  itemsHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 35,
  },

  logoContainer: {
    width: 300,
    padding: 0,
  },

  logo: {
    width: 100,
    height: 100,
  },

  administrationContainer: {
    width: 250,
    padding: 25,
  },

  contactContainer: {
    marginVertical: 20,
  },

  quoteHeaderDates: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 250,
  },

  boldW20: {
    fontWeight: 700,
    flex: 2,
    paddingBottom: 5,
  },

  boldW10: {
    fontWeight: 700,
    flex: 1,
    paddingBottom: 5,
  },

  w20: {
    fontWeight: 500,
    flex: 2,
    paddingTop: 5,
  },

  w10: {
    fontWeight: 500,
    flex: 1,
    paddingTop: 5,
  },
  row: {
    borderBottom: 1,
    borderBottomColor: '#d2d2d2',
    paddingBottom: 5,
    paddingTop: 5,
  },
  total: {
    fontWeight: 500,
    flex: 1,
    marginTop: 5,
  },
  bold: {
    fontWeight: 700,
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
  },
  summerization: {
    marginTop: 15,
  },
});

// Create Document Component
export const QuotePDFDocument = ({ quote, dateFormat }) => {
  const { targetBusiness } = quote;
  const { business } = quote.administration;
  const { t } = useTranslation();

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.flexRow}>
          <View style={styles.logoContainer}>
            {business.logo ? (
              <Image style={styles.logo} src={`${business.logo.url}`}></Image>
            ) : null}
          </View>

          <View style={styles.administrationContainer}>
            <Text style={styles.bold}>{targetBusiness?.name}</Text>
            {(targetBusiness?.locations?.length || 0) > 0 && (
              <>
                <Text>
                  {targetBusiness?.locations.length
                    ? `${targetBusiness.locations[0].entity.administrativeReference.street} ${targetBusiness.locations[0].entity.administrativeReference.houseNumber}`
                    : ''}
                </Text>
                <Text>
                  {`${targetBusiness?.locations[0].entity.administrativeReference.postalCode}, ${targetBusiness?.locations[0].entity.administrativeReference.city}`}
                </Text>
                <Text>
                  {targetBusiness?.locations[0].entity.administrativeReference
                    .country || ''}
                </Text>
              </>
            )}

            <View style={styles.contactContainer}>
              {targetBusiness?.coc && (
                <Text>
                  {t('business.coc')}: {targetBusiness?.coc}
                </Text>
              )}
              {targetBusiness?.vat && (
                <Text>
                  {t('business.vat')}: {targetBusiness?.vat}
                </Text>
              )}
              {targetBusiness?.contactDetails?.filter((cd) => cd.type === 'iban')
                .length > 0 && (
                <Text>
                  {t('business.bank')}:{' '}
                  {
                    targetBusiness?.contactDetails?.filter(
                      (cd) => cd.type === 'iban'
                    )[0].value
                  }
                </Text>
              )}
            </View>
          </View>
        </View>

        <View style={styles.contactContainer}>
          <Text style={styles.bold}>{business?.name || ''}</Text>
          {business?.locations?.length > 0 && (
            <>
              <Text>
                {business?.locations.length > 0
                  ? `${business.locations[0].entity.administrativeReference.street} ${business.locations[0].entity.administrativeReference.houseNumber}`
                  : ''}
              </Text>
              <Text>
                {business?.locations[0].entity.administrativeReference
                  .postalCode || ''}
                ,{' '}
                {business?.locations[0].entity.administrativeReference.city ||
                  ''}
              </Text>
              <Text>
                {business?.locations[0].entity.administrativeReference
                  .country || ''}
              </Text>
            </>
          )}
        </View>

        <View style={styles.itemsHeaderContainer}>
          <Text style={styles.title}>{quote.name}</Text>

          <View>
            <View style={styles.quoteHeaderDates}>
              <Text style={styles.bold}>{t('quote.date')}</Text>
              <Text>{defaultDateFormat(quote.date, dateFormat)}</Text>
            </View>

            <View style={styles.quoteHeaderDates}>
              <Text style={styles.bold}>{t('quote.dueAt')}</Text>
              <Text>{defaultDateFormat(quote.dueAt, dateFormat)}</Text>
            </View>
          </View>
        </View>

        <View style={styles.flexRow}>
          <Text style={styles.boldW10}>{t('quote.quantity')}</Text>
          <Text style={styles.boldW20}>{t('quote.description')}</Text>
          <Text style={styles.boldW10}>{t('quote.price')}</Text>
          <Text style={styles.boldW10}>{t('quote.total')}</Text>
        </View>

        {quote.lines
          ? quote.lines.map((line, index) => (
              <View
                style={{ ...styles.flexRow, ...styles.row }}
                key={`${index}-${line.nonce}`}
              >
                <Text style={styles.w10}>{line.quantity}</Text>
                <Text style={styles.w20}>{line.name}</Text>
                <Text style={styles.w10}>
                  {quote?.currency?.sign || '€'}
                  {line.value.amount.toFixed(2)}
                </Text>
                <Text style={styles.w10}>
                  {quote?.currency?.sign || '€'}
                  {line.quantity && line.value.amount
                    ? quote?.tax?.inclusiveOfTax
                      ? parseFloat(line.quantity) *
                        parseFloat(line.value.amount)
                      : parseFloat(line.quantity) *
                        parseFloat(line.value.amount) *
                        (parseFloat(
                          (line.value?.taxRate?.percentage || 0) + 100
                        ) /
                          100)
                    : 0}
                </Text>
              </View>
            ))
          : null}

        <View style={{ ...styles.flexRow, ...styles.summerization }}>
          <Text style={styles.w10}></Text>
          <Text style={styles.w20}></Text>
          <Text style={styles.total}>{t('quote.subtotal')}</Text>
          <Text style={styles.total}>
            {quote?.currency?.sign || '€'}
            {quote.subTotal.amount.toFixed(2)}
          </Text>
        </View>

        <View style={styles.flexRow}>
          <Text style={styles.w10}></Text>
          <Text style={styles.w20}></Text>
          <Text style={styles.total}>{t('quote.vat')}</Text>
          <Text style={styles.total}>
            {quote?.currency?.sign || '€'}
            {quote.tax.amount.toFixed(2)}
          </Text>
        </View>

        <View style={{ ...styles.flexRow, ...styles.bold }}>
          <Text style={styles.w10}></Text>
          <Text style={styles.w20}></Text>
          <Text style={{ ...styles.total, ...styles.bold }}>
            {t('quote.total')}
          </Text>
          <Text style={{ ...styles.total, ...styles.bold }}>
            {quote?.currency?.sign || '€'}
            {quote.total.amount.toFixed(2)}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export const QuotePDFDownload = (props) => {
  return (
    <PDFDownloadLink
      document={<QuotePDFDocument {...props} />}
      fileName={props.quote.name}
      {...props}
    />
  );
};
