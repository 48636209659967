import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import AttributesInput from '@components/attribute/AttributesInput';
import LocationInput from '@components/location/crud/LocationInput';
import EmissionsClassSelector from '@components/vehicle/EmissionsClassSelector';
import FuelTypeSelector from '@components/vehicle/FuelTypeSelector';
import VehicleTypeSelector from '@components/vehicle/VehicleTypeSelector';

import CloseButton from '@uicomponents/CloseButton';
import FormInput from '@uiinputs/FormInput';
import GenericTypesInput from '@uiinputs/GenericTypesInput';

import Constraint from '@models/constraint/Constraint';

import BoundsTypeSelector from './BoundsTypeSelector';

function debounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

const constraintTypeOptions = [
  { label: 'constraintType.andConstraint', value: 'andConstraint' },
  { label: 'constraintType.orConstraint', value: 'orConstraint' },
  { label: 'constraintType.notConstraint', value: 'notConstraint' },
  {
    label: 'constraintType.timeWindowsConstraint',
    value: 'timeWindowsConstraint',
  },
  {
    label: 'constraintType.startDateTimeConstraint',
    value: 'startDateTimeConstraint',
  },
  {
    label: 'constraintType.endDateTimeConstraint',
    value: 'endDateTimeConstraint',
  },
  { label: 'constraintType.sizeConstraint', value: 'sizeConstraint' },
  { label: 'constraintType.weightConstraint', value: 'weightConstraint' },
  { label: 'constraintType.speedConstraint', value: 'speedConstraint' },
  {
    label: 'constraintType.sensorValueConstraint',
    value: 'sensorValueConstraint',
  },
  { label: 'constraintType.fuelTypeConstraint', value: 'fuelTypeConstraint' },
  {
    label: 'constraintType.vehicleTypeConstraint',
    value: 'vehicleTypeConstraint',
  },
  {
    label: 'constraintType.transportEquipmentTypeConstraint',
    value: 'transportEquipmentTypeConstraint',
  },
  { label: 'constraintType.routeConstraint', value: 'routeConstraint' },
  { label: 'constraintType.genericConstraint', value: 'genericConstraint' },
  { label: 'constraintType.emissionStandardConstraint', value: 'emissionStandardConstraint' },
  { label: 'constraintType.attributeConstraint', value: 'attributeConstraint' },
];

class ConstraintForm extends Component {
  state = {
    constraint: this.props.constraint || new Constraint(),
  };

  setValue(propertyName, value, constraint, onChange) {
    const newConstraint = { ...constraint };

    if (propertyName === 'vehicleTypes') {
      newConstraint[propertyName] = [...value];
    } else {
      const newValue = { ...(newConstraint.value || {}) };

      newValue[propertyName] = value;
      newConstraint.value = newValue;
    }

    onChange?.(newConstraint);
  }

  debouncedSetValue = debounce((field, e) => {
    this.setValue(field, e, this.state.constraint, (newConstraint) => {
      this.setState({
        constraint: newConstraint,
      });
      this.props.onChange?.(newConstraint);
    });
  }, 500);

  renderInputs(setValue) {
    const { constraint } = this.state;
    const type = constraint?.value?.type || constraint?.type;
    let inputComponents = [];

    switch (type) {
      case 'timeWindowsConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top half">
              <FormInput
                type="text"
                label="form.label.datetime"
                defaultValue={
                  constraint?.value?.startingDateTime || constraint?.startingDateTime || ''
                }
                onFocus={(e) => (e.currentTarget.type = 'datetime-local')}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.currentTarget.type = 'text';
                  }
                }}
                onChange={(e) => {
                  this.debouncedSetValue('startingDateTime', e.target.value);
                }}
              />
            </div>
            <div className="input-group no-margin-top half">
              <FormInput
                type="text"
                label="form.label.datetime"
                defaultValue={constraint?.value?.endingDateTime || constraint?.endingDateTime || ''}
                onFocus={(e) => (e.currentTarget.type = 'datetime-local')}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.currentTarget.type = 'text';
                  }
                }}
                onChange={(e) => {
                  this.debouncedSetValue('endingDateTime', e.target.value);
                }}
              />
            </div>
          </div>
        );
        break;
      case 'startDateTimeConstraint':
        inputComponents.push(
          <FormInput
            type="text"
            label="form.label.datetime"
            defaultValue={constraint?.value?.startDateTime || constraint?.startDateTime || ''}
            onFocus={(e) => (e.currentTarget.type = 'datetime-local')}
            onBlur={(e) => {
              if (!e.target.value) {
                e.currentTarget.type = 'text';
              }
            }}
            onChange={(e) => {
              this.debouncedSetValue('startDateTime', e.target.value);
            }}
          />
        );
        break;
      case 'endDateTimeConstraint':
        inputComponents.push(
          <FormInput
            type="text"
            label="form.label.datetime"
            defaultValue={constraint?.value?.endDateTime || constraint?.endDateTime || ''}
            onFocus={(e) => (e.currentTarget.type = 'datetime-local')}
            onBlur={(e) => {
              if (!e.target.value) {
                e.currentTarget.type = 'text';
              }
            }}
            onChange={(e) => {
              this.debouncedSetValue('endDateTime', e.target.value);
            }}
          />
        );
        break;
      case 'emissionStandardConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <EmissionsClassSelector
                value={constraint?.value?.minimum || constraint?.minimum}
                placeholder={`${this.props.t('form.label.emissionsClass')}*`}
                onChange={(selectedOption) => {
                  setValue(
                    'minimum',
                    selectedOption.value,
                    this.state.constraint,
                    (newConstraint) => {
                      this.setState({
                        constraint: newConstraint,
                      });
                      this.props.onChange?.(newConstraint);
                    }
                  );
                }}
              />
            </div>
          </div>
        );
        break;
      case 'routeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <LocationInput
                placeholder={this.props.t('form.label.location')}
                location={constraint.value?.route?.points?.[0] || constraint?.route?.points?.[0]}
                onChange={(location) => {
                  setValue(
                    'route',
                    {
                      type: 'latLonArrayGeoReference',
                      points: [location?.geoReference],
                    },
                    this.state.constraint,
                    (newConstraint) => {
                      this.setState({
                        constraint: newConstraint,
                      });
                      this.props.onChange?.(newConstraint);
                    }
                  );
                }}
              />
            </div>
          </div>
        );
        break;
      case 'sizeConstraint':
      case 'speedConstraint':
      case 'weightConstraint':
      case 'sensorValueConstraint':
        const boundsType = type === 'sizeConstraint' ? 'bounds' : `${type}Type`;
        const boundsTypeValue = constraint?.value?.[boundsType] || constraint?.[boundsType];
        inputComponents.push(
          <>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <BoundsTypeSelector
                  placeholder={`${this.props.t('form.label.boundsType')}*`}
                  value={boundsTypeValue}
                  onChange={(type) => {
                    setValue(boundsType, type?.value, this.state.constraint, (newConstraint) => {
                      this.setState({
                        constraint: newConstraint,
                      });
                      this.props.onChange?.(newConstraint);
                    });
                  }}
                />
              </div>
            </div>
            {boundsTypeValue === 'minimum' || boundsTypeValue === 'range' ? (
              <FormInput
                type="number"
                placeholder={this.props.t('form.label.minimum')}
                value={constraint?.value?.minimum?.value || constraint?.minimum?.value}
                onChange={(e) => {
                  this.debouncedSetValue('minimum', { value: e.target.value });
                }}
              />
            ) : null}
            {boundsTypeValue === 'maximum' || boundsTypeValue === 'range' ? (
              <FormInput
                type="number"
                placeholder={this.props.t('form.label.maximum')}
                value={constraint?.value?.maximum?.value || constraint?.maximum?.value}
                onChange={(e) => {
                  this.debouncedSetValue('maximum', { value: e.target.value });
                }}
              />
            ) : null}
          </>
        );
        break;
      case 'vehicleTypeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <VehicleTypeSelector
                placeholder={`${this.props.t('form.label.vehicleTypes')}*`}
                value={
                  this.state.constraint?.value?.vehicleTypes || this.state.constraint?.vehicleTypes
                }
                isMulti={true}
                onChange={(types) => {
                  setValue(
                    'vehicleTypes',
                    types ? types.map((type) => type.value) : types,
                    this.state.constraint,
                    (newConstraint) => {
                      this.setState({
                        constraint: newConstraint,
                      });
                      this.props.onChange?.(newConstraint);
                    }
                  );
                }}
              />
            </div>
          </div>
        );
        break;
      case 'fuelTypeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <FuelTypeSelector
                placeholder={`${this.props.t('form.label.fuelTypes')}*`}
                value={constraint?.value?.fuelTypes || constraint?.fuelTypes}
                isMulti={true}
                onChange={(types) => {
                  setValue(
                    'fuelTypes',
                    types ? types.map((type) => type.value) : types,
                    this.state.constraint,
                    (newConstraint) => {
                      this.setState({
                        constraint: newConstraint,
                      });
                      this.props.onChange?.(newConstraint);
                    }
                  );
                }}
              />
            </div>
          </div>
        );
        break;
      case 'attributeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <AttributesInput
                placeholder={`${this.props.t('form.label.selectAttributes')}*`}
                value={constraint?.value?.attributes || constraint?.attributes}
                isMulti={true}
                onChange={(newAttributes) => {
                  setValue('attributes', newAttributes, this.state.constraint, (newConstraint) => {
                    this.setState({
                      constraint: newConstraint,
                    });
                    this.props.onChange?.(newConstraint);
                  });
                }}
              />
            </div>
          </div>
        );
        break;
      case 'transportEquipmentTypeConstraint':
        inputComponents.push(
          <div className="input-group">
            <div className="input-group no-margin-top">
              <GenericTypesInput
                entityType="transportEquipmentType"
                entitySubType="transportEquipmentSubType"
                value={constraint?.value?.equipmentTypes?.[0] || constraint?.equipmentTypes?.[0]}
                subTypeValue={
                  constraint?.value?.subEquipmentTypes?.[0] || constraint?.subEquipmentTypes?.[0]
                }
                placeholder={`${this.props.t('form.label.selectTransportEquipmentType')}*`}
                onChange={(type, subType) => {
                  setValue(
                    'equipmentTypes',
                    [type.value],
                    this.state.constraint,
                    (newConstraint) => {
                      this.setState({
                        constraint: newConstraint,
                      });
                      this.props.onChange?.(newConstraint);
                    }
                  );
                  setValue(
                    'subEquipmentTypes',
                    [subType.value],
                    this.state.constraint,
                    (newConstraint) => {
                      this.setState({
                        constraint: newConstraint,
                      });
                      this.props.onChange?.(newConstraint);
                    }
                  );
                }}
              />
            </div>
          </div>
        );
        break;
      case 'andConstraint':
      case 'orConstraint':
      case 'notConstraint':
        const constraintType = type?.split?.('Constraint')?.[0];
        this.state.constraint?.value?.[constraintType]?.map((andConstraint, index) => {
          return inputComponents.push(
            <div
              className="input-group bg-default p-5"
              key={`${constraintType}-constraint-${index}`}
            >
              <div
                className="list-sector constraint-form-element"
                key={`input-constraint-${index}`}
              >
                <div className="list-actions">
                  <div></div>
                  <CloseButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      const { onChange } = this.props;

                      const { constraint } = this.state;
                      const newConstraints = [...constraint?.value?.[constraintType]];

                      newConstraints.splice(index, 1);

                      const newParentConstraint = { ...constraint };
                      newParentConstraint.value = {
                        ...newParentConstraint.value,
                        [constraintType]: [...newConstraints],
                      };

                      this.setState({
                        constraint: newParentConstraint,
                      });

                      onChange && onChange(constraint);
                    }}
                  />
                </div>
                <ConstraintForm
                  key={JSON.stringify(andConstraint?.value)}
                  t={this.props.t}
                  constraint={andConstraint}
                  enabledOptions={this.props.enabledOptions.filter(
                    (option) =>
                      option !== 'andConstraint' &&
                      option !== 'orConstraint' &&
                      option !== 'notConstraint'
                  )}
                  onChange={(newConstraint) => {
                    const { onChange } = this.props;

                    const { constraint } = this.state;
                    const newConstraints = [...constraint?.value?.[constraintType]];

                    newConstraints[index] = {
                      ...newConstraint.value,
                      enforceability: newConstraint.enforceability,
                    };

                    const newParentConstraint = { ...constraint };
                    newParentConstraint.value = {
                      ...newParentConstraint.value,
                      [constraintType]: [...newConstraints],
                    };

                    this.setState({
                      constraint: newParentConstraint,
                    });
                    onChange?.(newParentConstraint);
                  }}
                  setValue={(propertyName, value) => {
                    const newConstraints = [...this.state.constraint?.value?.[constraintType]];
                    const newConstraint = newConstraints[index];
                    const newValue = {
                      ...(newConstraint.value || {
                        type: newConstraint?.type,
                        enforceability: newConstraint?.enforceability,
                      }),
                    };

                    newConstraint[propertyName] = value;
                    newValue[propertyName] = value;
                    newConstraint.value = newValue;

                    newConstraints[index] = {
                      ...newConstraint,
                    };
                    const newParentConstraint = { ...this.state.constraint };
                    newParentConstraint.value = {
                      ...newParentConstraint.value,
                      [constraintType]: [...newConstraints],
                    };

                    this.setState({
                      constraint: newParentConstraint,
                    });
                    this.props.onChange?.(newParentConstraint);
                  }}
                />
              </div>
            </div>
          );
        });

        inputComponents.push(
          <div className="input-group left">
            <button
              type="button"
              onClick={(e) => {
                const { onChange } = this.props;
                const { constraint } = this.state;

                const newConstraint = { ...constraint };
                const newValue = { ...(newConstraint.value || {}) };

                newValue[constraintType] = [...newValue[constraintType], { type: '' }];
                newConstraint.value = newValue;

                this.setState({
                  constraint: newConstraint,
                });
                onChange && onChange(newConstraint);
              }}
            >
              {this.props.t('form.label.addConstraint')}
            </button>
          </div>
        );

        break;

      default:
        return inputComponents;
    }
    return inputComponents;
  }

  render() {
    const { t, enabledOptions } = this.props;

    const translatedConstraintTypeOptions = constraintTypeOptions
      .filter((option) => enabledOptions.includes(option.value))
      .map((option) => ({
        label: t(option.label),
        value: option.value,
      }));

    const enforceabilityOptions = [
      { label: t('constraint.enforceability.enforced'), value: 'enforced' },
      { label: t('constraint.enforceability.preference'), value: 'preference' },
    ];

    return (
      <>
        <div className="input-group">
          <div className="input-group no-margin-top">
            <Select
              placeholder={t('form.label.selectConstraintValueType')}
              options={translatedConstraintTypeOptions}
              noOptionsMessage={() => t('noOptions')}
              value={translatedConstraintTypeOptions.find(
                (o) =>
                  o.value === this.state.constraint?.value?.type ||
                  o.value === this.state.constraint?.type
              )}
              onChange={(e) => {
                if (
                  e.value === 'andConstraint' ||
                  e.value === 'notConstraint' ||
                  e.value === 'orConstraint'
                ) {
                  const { onChange } = this.props;
                  const { constraint } = this.state;

                  const newConstraint = { ...constraint };
                  const newValue = { ...(newConstraint.value || {}) };

                  newValue.type = e.value;
                  newValue[e.value?.split('Constraint')?.[0]] = [{ type: '' }];

                  newConstraint.value = newValue;

                  this.setState({
                    constraint: newConstraint,
                  });
                  onChange?.(newConstraint);
                } else {
                  const { onChange } = this.props;
                  const { constraint } = this.state;

                  const newConstraint = { ...constraint };
                  const newValue = { ...(newConstraint.value || {}) };

                  newValue.type = e.value;
                  newConstraint.type = e.value;
                  newConstraint.value = newValue;

                  this.setState({
                    constraint: newConstraint,
                  });
                  onChange?.(newConstraint);
                }
              }}
            />
          </div>
        </div>
        <div className="input-group">
          <Select
            placeholder={t('form.label.enforceability')}
            options={enforceabilityOptions}
            noOptionsMessage={() => t('noOptions')}
            value={enforceabilityOptions.find(
              (o) =>
                o.value === this.state.constraint?.value?.enforceability ||
                o.value === this.state.constraint?.enforceability
            )}
            onChange={(selectedOption) => {
              const { onChange } = this.props;
              const { constraint } = this.state;

              const newConstraint = { ...constraint };
              const newValue = { ...(newConstraint.value || {}) };

              newValue.enforceability = selectedOption.value;
              newConstraint.enforceability = selectedOption.value;
              newConstraint.value = newValue;

              this.setState({
                constraint: newConstraint,
              });
              onChange?.(newConstraint);
            }}
          />
        </div>
        {this.renderInputs(this.props.setValue ? this.props.setValue : this.setValue)}
      </>
    );
  }
}

export default withTranslation('translation')(ConstraintForm);
