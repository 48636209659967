import React, { Component } from 'react';

import CurrencyInput from 'react-currency-input-field';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import IntegrationFiltersInput from '@components/integration/filter/IntegrationFiltersInput';
import LocationGranularitySelector from '@components/location/LocationGranularitySelector';
import PostalCodeRangeInput from '@components/location/PostalCodeRangeInput';
import LocationInput from '@components/location/crud/LocationInput';
import PricingVariableIndexInput from '@components/pricingVariableIndex/crud/PricingVariableIndexInput';
import TQLInput from '@components/tql/TQLInput';

import Attachments from '@uicomponents/Attachments';
import Loader from '@uicomponents/Loader';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';
import SelectFieldpathInput from '@uiinputs/SelectFieldpathInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import { convertToEnLocale } from '@utils/moneyUtils';

import PricingModuleTypeSelector from '../PricingModuleTypeSelector';
import CellsOverwriteInput from './CellsOverwriteInput';
import PricingModulesInput from './PricingModulesInput';
import PricingStartValueInput from './PricingStartValueInput';
import PricingTaxRatesInput from './PricingTaxRatesInput';

class PricingModuleForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      selectedCurrency: this.props.currency,
      module: this.props.module,
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  addColumn = () => {
    const { module } = this.state;
    const newModule = { ...module };
    const newColumns = [...newModule.columns];

    newColumns.push({
      cells: [...Array(newColumns[0].cells.length).keys()].map(() => ({
        value: '',
      })),
    });

    newModule.columns = newColumns;

    this.onChange(newModule);
  };

  removeColumn = (index) => {
    const { module } = this.state;
    const newModule = { ...module };
    const newColumns = [...newModule.columns].filter((column, cIndex) => cIndex !== index);

    newModule.columns = newColumns;

    this.onChange(newModule);
  };

  addCell = () => {
    const { module } = this.state;
    const newModule = { ...module };

    const newColumns = [...newModule.columns];

    newModule.columns = newColumns.map((newColumn) => {
      const newCells = [...newColumn.cells];

      newCells.push({});

      newColumn.cells = newCells;
      return newColumn;
    });

    this.onChange(newModule);
  };

  removeCell = (index) => {
    const { module } = this.state;
    const newModule = { ...module };

    const newColumns = [...newModule.columns];

    newModule.columns = newColumns.map((newColumn) => {
      newColumn.cells = [...newColumn.cells].filter((c, cIndex) => cIndex !== index);
      return newColumn;
    });

    this.onChange(newModule);
  };

  changeCell = (column, cell, value) => {
    const { module } = this.state;
    const newModule = { ...module };
    const newColumns = [...newModule.columns];
    const newColumn = { ...newColumns[column] };

    const newCells = [...newColumn.cells];
    const newCell = { ...newCells[cell] };

    newCell.value = value;

    newCells[cell] = newCell;
    newColumn.cells = newCells;
    newColumns[column] = newColumn;
    newModule.columns = newColumns;
    this.onChange(newModule);
  };

  changeCellValue = (column, cell, name, value) => {
    const { module } = this.state;
    const newModule = { ...module };
    const newColumns = [...newModule.columns];
    const newColumn = { ...newColumns[column] };

    const newCells = [...newColumn.cells];
    const newCell = { ...newCells[cell] };

    newCell[name] = value;

    newCells[cell] = newCell;
    newColumn.cells = newCells;
    newColumns[column] = newColumn;
    newModule.columns = newColumns;
    this.onChange(newModule);
  };

  onChange = (newModule) => {
    this.setState({
      module: newModule,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onChange, popStack } = this.props;
    const { module } = this.state;

    onChange?.(module);
    popStack?.();
  };

  render() {
    const {
      t,
      taxRates,
      entityType,
      dataTypes,
      pricingModuleTypes,
      user,
      pricingModuleSubUnits,
      pricingEntityTypes,
      pricingCategories,
    } = this.props;
    const { module, selectedCurrency } = this.state;

    if (!module) {
      return <Loader />;
    }

    const scheduleTypeOptions = [
      {
        value: 'minutes',
        label: t('schedulerScheduleType.minutes'),
      },
      {
        value: 'hours',
        label: t('schedulerScheduleType.hours'),
      },
      {
        value: 'days',
        label: t('schedulerScheduleType.days'),
      },
      {
        value: 'weeks',
        label: t('schedulerScheduleType.weeks'),
      },
      {
        value: 'months',
        label: t('schedulerScheduleType.months'),
      },
      {
        value: 'years',
        label: t('schedulerScheduleType.years'),
      },
    ];

    const timeEntityLevelOptions = [
      {
        value: 'trip',
        label: t('entityType.trip'),
      },
      {
        value: 'action',
        label: t('entityType.action'),
      },
    ];

    const movementLevelOptions = [
      {
        value: 'moveAction',
        label: t('entityType.moveAction'),
      },
      {
        value: 'consignment',
        label: t('entityType.consignment'),
      },
    ];

    const changeTypeOptions = [
      {
        value: 'fixed',
        label: t('changeType.fixed'),
      },
      {
        value: 'percentage',
        label: t('changeType.percentage'),
      },
    ];

    const pricingModuleSubUnitsOptions = pricingModuleSubUnits.map((unit) => ({
      label: unit,
      value: unit,
    }));

    const pricingCategoriesOptions = pricingCategories.map((pc) => ({
      label: pc.name,
      value: pc.id,
      pricingCategory: pc,
    }));

    const pricingEntityTypeOptions = pricingEntityTypes
      .filter((type) =>
        pricingModuleTypes
          .find((mt) => mt.type === module.type)
          ?.pricingEntityTypes?.includes(type.type)
      )
      .filter((type) => {
        switch (entityType) {
          case 'vehicle':
            return (
              type.type === 'vehicle' || type.type === 'accumlatedData' || type.type === 'trip'
            );
          case 'fleetVehicle':
            return (
              type.type === 'fleetVehicle' || type.type === 'accumlatedData' || type.type === 'trip'
            );
          case 'transportOrder':
            return (
              type.type === 'transportOrder' ||
              type.type === 'accumlatedData' ||
              type.type === 'trip'
            );
          case 'consignment':
            return (
              type.type === 'consignment' || type.type === 'accumlatedData' || type.type === 'trip'
            );
          case 'trip':
            return type.type === 'accumlatedData' || type.type === 'trip';
        }
      })
      .map((type) => ({
        label: t(`entityType.${type.type}`),
        value: type.type,
        type,
      }));

    return (
      <div className="pricing-module-form">
        <div className="input-group type">
          <PricingModuleTypeSelector
            types={pricingModuleTypes}
            value={module.type}
            placeholder={t('form.label.moduleType')}
            onChange={(e) => {
              const newModule = { ...module };
              newModule.type = e.value;
              newModule.targetEntityType = 'trip';

              this.onChange(newModule);
            }}
          />
        </div>
        <div className="name">
          <FormInput
            type="text"
            label="form.label.name"
            required={true}
            value={module.name}
            onChange={(event) => {
              const newModule = { ...module };
              newModule.name = event.target.value;

              this.onChange(newModule);
            }}
          />
          {this.validator.message(t('form.label.name'), module.name, 'required')}
        </div>

        {pricingEntityTypeOptions.length > 1 && (
          <div className="input-group entity  ">
            <div className="input-grou no-margin-top">
              <Select
                placeholder={t('form.label.selectPricingEntityType')}
                noOptionsMessage={() => t('noOptions')}
                value={pricingEntityTypeOptions.find(
                  (option) => option.value === module.targetEntityType
                )}
                options={pricingEntityTypeOptions}
                onChange={(event) => {
                  const newModule = { ...module };
                  newModule.targetEntityType = event.value;

                  this.onChange(newModule);
                }}
              />
            </div>
          </div>
        )}

        {module.type === 'field' && (
          <div className="input-group more">
            <div className="input-group no-margin-top">
              <SelectFieldpathInput
                type="text"
                value={module.selector}
                label="form.label.selector"
                required={true}
                entityType={
                  module.timeEntityLevel ||
                  module.targetEntityType ||
                  pricingEntityTypeOptions[0]?.value ||
                  'trip'
                }
                onChange={(value) => {
                  const newModule = { ...module };
                  newModule.selector = value;

                  this.onChange(newModule);
                }}
              />
            </div>
            {this.validator.message(t('form.label.selector'), module.selector, 'required')}
          </div>
        )}
        {(module.type === 'ab' || module.type === 'zone') && (
          <>
            <div className="input-group">
              <LocationGranularitySelector
                value={module.granularity}
                onChange={(e) => {
                  const newModule = { ...module };
                  newModule.granularity = e.value;

                  this.onChange(newModule);
                }}
              />
            </div>
            <div className="input-group">
              <div className="input-grou no-margin-top">
                <Select
                  placeholder={t('form.label.selectMovementLevel')}
                  noOptionsMessage={() => t('noOptions')}
                  value={movementLevelOptions.find(
                    (option) => option.value === module.movementLevel
                  )}
                  options={movementLevelOptions}
                  onChange={(event) => {
                    const newModule = { ...module };
                    newModule.movementLevel = event.value;

                    this.onChange(newModule);
                  }}
                />
              </div>
            </div>
          </>
        )}

        {module.type === 'element' && (
          <div className="input-group more">
            <div className="input-group no-margin-top ">
              <Select
                options={pricingCategoriesOptions}
                placeholder={t('form.label.pricingCategory')}
                value={
                  pricingCategoriesOptions.find(
                    (option) => option.value === module?.pricingCategory?.id
                  ) || pricingCategoriesOptions[0]
                }
                noOptionsMessage={() => t('noOptions')}
                onChange={(event) => {
                  const newModule = { ...module };
                  newModule.pricingCategory = event.pricingCategory;

                  this.onChange(newModule);
                }}
              />
            </div>
            {this.validator.message(
              t('form.label.pricingCategory'),
              module.pricingCategory,
              'required'
            )}
          </div>
        )}

        {module.type === 'time' && (
          <div className="input-group">
            <div className="input-grou no-margin-top">
              <Select
                placeholder={t('form.label.selectTimeEntityLevel')}
                noOptionsMessage={() => t('noOptions')}
                value={timeEntityLevelOptions.find(
                  (option) => option.value === module.timeEntityLevel
                )}
                options={timeEntityLevelOptions}
                onChange={(event) => {
                  const newModule = { ...module };
                  newModule.timeEntityLevel = event.value;

                  this.onChange(newModule);
                }}
              />
            </div>
          </div>
        )}

        {module.type === 'percentage' && (
          <div className="input-group center more no-margin-top">
            <FormInput
              type="number"
              label="form.label.percentage"
              value={module.value}
              onChange={(e) => {
                const newModule = { ...module };
                newModule.value = e.target.value;

                this.onChange(newModule);
              }}
            />
          </div>
        )}
        {module.type === 'time' && (
          <div className="input-group no-margin-top">
            <div className="input-group half more no-margin-top">
              <FormInput
                type="number"
                wrapperClass="no-margin-top"
                label="form.label.value"
                value={module.timeUnitAmount}
                onChange={(e) => {
                  const newModule = { ...module };
                  newModule.timeUnitAmount = e.target.value;

                  this.onChange(newModule);
                }}
              />
            </div>
            <div className="input-group half more">
              <Select
                placeholder={t('form.label.selectScheduleUnitType')}
                options={scheduleTypeOptions}
                value={scheduleTypeOptions.find((option) => option.value === module.timeUnit)}
                onChange={(e) => {
                  const newModule = { ...module };
                  newModule.timeUnit = e.value;

                  this.onChange(newModule);
                }}
              />
            </div>
          </div>
        )}
        {module.type === 'unit' && (
          <div className="input-group more">
            <div className="input-grou no-margin-top">
              <Select
                placeholder={t('form.label.selectUnitOptions')}
                noOptionsMessage={() => t('noOptions')}
                value={pricingModuleSubUnitsOptions.find((option) => option.value === module.unit)}
                options={pricingModuleSubUnitsOptions}
                onChange={(event) => {
                  const newModule = { ...module };
                  newModule.unit = event.value;

                  this.onChange(newModule);
                }}
              />
            </div>
          </div>
        )}
        {module.type === 'ab' && (
          <div className="input-group more">
            <div
              className="input-group flex-container no-margin-top"
              key={`stop-${module.nonce}-${module.id}`}
            >
              <div className="input-group one no-margin-top">
                <div className="input-group no-margin-top">
                  <LocationInput
                    location={module.from}
                    placeholder={t('form.label.from')}
                    onChange={(newLocation) => {
                      const newModule = { ...module };
                      newModule.from = newLocation;

                      this.onChange(newModule);
                    }}
                  />
                </div>
              </div>
              <div className="input-group one no-margin-top">
                <div className="input-group no-margin-top">
                  <LocationInput
                    location={module.to}
                    placeholder={t('form.label.to')}
                    onChange={(newLocation) => {
                      const newModule = { ...module };
                      newModule.to = newLocation;

                      this.onChange(newModule);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {module.type === 'zone' && (
          <div className="input-group">
            <PostalCodeRangeInput
              value={module.postalCodeRanges}
              onChange={(newPostalCodes) => {
                const newModule = { ...module };
                newModule.postalCodeRanges = [...newPostalCodes];

                this.onChange(newModule);
              }}
            />
          </div>
        )}
        {module.type === 'excel' && (
          <>
            <div className="input-group">
              <h3 className="no-margin-top no-margin-bottom">{t('form.label.document')}</h3>
              <Attachments
                {...this.props}
                max={1}
                accept={{
                  'application/vnd.ms-excel': ['.xls'],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                }}
                files={module.document ? [module.document.file] : []}
                onChange={(files) => {
                  const newModule = { ...module };
                  newModule.document = files
                    ? new Association('inline', {
                        name: newFile.originalName,
                        mimeType: newFile.mimeType,
                        content: {
                          contentType: 'uri',
                          uri: newFile.url,
                        },
                        file: newFile,
                      })
                    : null;

                  this.onChange(newModule);
                }}
              />
            </div>
            <div className="input-group">
              <CellsOverwriteInput
                value={module.cellsValue}
                onChange={(newCells) => {
                  const newModule = { ...module };
                  newModule.cellsValue = [...newCells];

                  this.onChange(newModule);
                }}
              />
            </div>
            <FormInput
              type="text"
              label="form.label.resultCell"
              value={module.resultCell}
              onChange={(e) => {
                const newModule = { ...module };
                newModule.resultCell = e.target.value;

                this.onChange(newModule);
              }}
            />
          </>
        )}

        {(module.type === 'element' || module.type === 'excel' || module.type === 'field') && (
          <FormInput
            type="number"
            label="form.label.factor"
            value={module.factor}
            onChange={(e) => {
              const newModule = { ...module };
              newModule.factor = e.target.value;

              this.onChange(newModule);
            }}
          />
        )}

        {(module.type === 'variable_adjustment' || module.type === 'percentage') && (
          <PricingStartValueInput
            value={module.startValue}
            entityType={
              module.timeEntityLevel ||
              module.targetEntityType ||
              pricingEntityTypeOptions[0]?.value ||
              'trip'
            }
            onChange={(newStartValue) => {
              const newModule = { ...module };
              newModule.startValue = newStartValue;

              this.onChange(newModule);
            }}
          />
        )}

        {module.type === 'variable_adjustment' && (
          <>
            <div className="input-group more">
              <PricingVariableIndexInput
                key={module.pricingVariableIndex?.id || ''}
                pricingVariableIndex={module.pricingVariableIndex}
                onChange={(newPricingVariableIndex) => {
                  const newModule = { ...module };
                  newModule.pricingVariableIndex = newPricingVariableIndex;

                  this.onChange(newModule);
                }}
              />
            </div>
            <FormInput
              type="number"
              label="form.label.baseLevel"
              value={module.baseLevel}
              onChange={(e) => {
                const newModule = { ...module };
                newModule.baseLevel = e.target.value;

                this.onChange(newModule);
              }}
            />
            <div className="input-group">
              <div className="input-group no-margin-top">
                <Select
                  placeholder={t('form.label.selectPerDifferenceType')}
                  noOptionsMessage={() => t('noOptions')}
                  value={changeTypeOptions.find(
                    (option) => option.value === module.perDifferenceType
                  )}
                  options={changeTypeOptions}
                  onChange={(event) => {
                    const newModule = { ...module };
                    newModule.perDifferenceType = event.value;

                    this.onChange(newModule);
                  }}
                />
              </div>
            </div>
            <FormInput
              type="number"
              label="form.label.perDifference"
              value={module.perDifference}
              onChange={(e) => {
                const newModule = { ...module };
                newModule.perDifference = e.target.value;

                this.onChange(newModule);
              }}
            />
            <div className="input-group">
              <div className="input-group no-margin-top">
                <Select
                  placeholder={t('form.label.selectChangeType')}
                  noOptionsMessage={() => t('noOptions')}
                  value={changeTypeOptions.find((option) => option.value === module.changeType)}
                  options={changeTypeOptions}
                  onChange={(event) => {
                    const newModule = { ...module };
                    newModule.changeType = event.value;

                    this.onChange(newModule);
                  }}
                />
              </div>
            </div>
            <FormInput
              type="number"
              label="form.label.change"
              value={module.change}
              onChange={(e) => {
                const newModule = { ...module };
                newModule.change = e.target.value;

                this.onChange(newModule);
              }}
            />
          </>
        )}

        {module.type === 'formula' && (
          <FormInput
            type="text"
            label="form.label.formula"
            value={module.formula}
            onChange={(e) => {
              const newModule = { ...module };
              newModule.formula = e.target.value;

              this.onChange(newModule);
            }}
          />
        )}

        {['fixed', 'time', 'unit', 'zone', 'ab', 'field'].includes(module.type) && (
          <div className="input-group more">
            <div className="input-grou no-margin-top">
              <CurrencyInput
                decimalScale={2}
                value={module.amount}
                intlConfig={{
                  locale: user?.language?.iso3166 || 'en-GB',
                  currency: 'EUR',
                }}
                onValueChange={(value, name, values) => {
                  const newModule = { ...module };
                  newModule.amount = convertToEnLocale(value, {
                    thousandDelimiter: user?.language?.thousandDelimiter || ',',
                    decimalDelimiter: user?.language?.decimalDelimiter || '.',
                  });
                  this.onChange(newModule);
                }}
              />
              <label>{t('form.label.amount')}</label>
            </div>
          </div>
        )}

        {module.type === 'tql_formula' && (
          <div className="input-group no-margin-top">
            <div className="input-group center more">
              <TQLInput
                value={module?.automationConfig?.query}
                onChange={(newQuery) => {
                  const newModule = { ...module };
                  newModule.query = newQuery;
                  newModule.query.entityType = getEntityStructure(newModule.query.entityType);

                  this.onChange(newModule);
                }}
              />
            </div>
          </div>
        )}

        {module.type === 'table' && (
          <>
            <div className="input-group more">
              <div className="input-group no-margin-top">
                <div className="input-group no-margin-top">
                  <LocationInput
                    location={module.from}
                    placeholder={t('form.label.from')}
                    onChange={(newLocation) => {
                      const newModule = { ...module };
                      newModule.from = newLocation;

                      this.onChange(newModule);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="input-group">
              <div className="input-group no-margin-top">
                <Select
                  placeholder={t('form.label.selectUnitOptions')}
                  noOptionsMessage={() => t('noOptions')}
                  value={pricingModuleSubUnitsOptions.find(
                    (option) => option.value === module.columnUnit
                  )}
                  options={pricingModuleSubUnitsOptions}
                  onChange={(event) => {
                    const newModule = { ...module };
                    newModule.columnUnit = event.value;

                    this.onChange(newModule);
                  }}
                />
              </div>
            </div>
            <div className="input-group">
              <div className="input-grou no-margin-top">
                <Select
                  placeholder={t('form.label.selectUnitOptions')}
                  noOptionsMessage={() => t('noOptions')}
                  value={pricingModuleSubUnitsOptions.find(
                    (option) => option.value === module.cellUnit
                  )}
                  options={pricingModuleSubUnitsOptions}
                  onChange={(event) => {
                    const newModule = { ...module };
                    newModule.cellUnit = event.value;

                    this.onChange(newModule);
                  }}
                />
              </div>
            </div>
            <div className="input-group matrix-view scrollable matrix-table">
              {module.columns?.map((column, columnIndex) => (
                <>
                  <div className={`column`}>
                    {column.cells?.map((cell, cellIndex) => {
                      return (
                        <>
                          <div
                            className={`cell${
                              cellIndex === 0
                                ? ' cell--header cell--header--large row'
                                : columnIndex === 0
                                ? ' column'
                                : ''
                            }`}
                          >
                            {cellIndex === 0 && columnIndex === 0 ? (
                              <div>
                                {module.columnUnit}/{module.cellUnit}
                              </div>
                            ) : cellIndex === 0 || columnIndex === 0 ? (
                              <>
                                {(cellIndex === 0 && module.cellUnit == 'location') ||
                                (columnIndex === 0 && module.columnUnit == 'location') ? (
                                  <LocationInput
                                    location={cell.location}
                                    onChange={(newLocation) => {
                                      this.changeCellValue(
                                        columnIndex,
                                        cellIndex,
                                        'location',
                                        newLocation
                                      );
                                    }}
                                  />
                                ) : (
                                  <FormInput
                                    type="number"
                                    value={cell.value}
                                    wrapperClass={'no-margin-top'}
                                    min={
                                      columnIndex == 0
                                        ? module.columns[columnIndex]?.cells[cellIndex - 1]
                                            ?.value || 1
                                        : cellIndex == 0
                                        ? module.columns[columnIndex - 1]?.cells[cellIndex]
                                            ?.value || 1
                                        : 0
                                    }
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.changeCell(
                                        columnIndex,
                                        cellIndex,
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              <CurrencyInput
                                value={cell.value}
                                decimalScale={2}
                                intlConfig={{
                                  locale: user?.language?.iso3166 || 'en-GB',
                                  currency: 'EUR',
                                }}
                                onValueChange={(value, name, values) => {
                                  const parsedValue = convertToEnLocale(value, {
                                    thousandDelimiter: user?.language?.thousandDelimiter || ',',
                                    decimalDelimiter: user?.language?.decimalDelimiter || '.',
                                  });
                                  this.changeCell(columnIndex, cellIndex, parsedValue);
                                }}
                              />
                            )}
                          </div>
                          {cellIndex === column.cells.length - 1 && columnIndex === 0 && (
                            <div
                              className={`cell cell--action`}
                              onClick={() => {
                                this.addCell();
                              }}
                            >
                              {t('add')}
                            </div>
                          )}
                          {cellIndex === column.cells.length - 1 && columnIndex !== 0 && (
                            <div
                              className={`cell cell--action`}
                              onClick={() => {
                                this.removeColumn(columnIndex);
                              }}
                            >
                              {t('remove')}
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  {columnIndex === module.columns.length - 1 && (
                    <div className={`column`}>
                      {column.cells?.map((cell, cellIndex) => (
                        <>
                          {cellIndex === 0 ? (
                            <div
                              className={`cell cell--action cell--header row column`}
                              onClick={(e) => {
                                this.addColumn();
                              }}
                            >
                              {t('add')}
                            </div>
                          ) : (
                            <div
                              className={`cell cell--action cell--action--large cell--header row column`}
                              onClick={(e) => {
                                this.removeCell(cellIndex);
                              }}
                            >
                              {t('remove')}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                </>
              ))}
            </div>
          </>
        )}

        {(module.type === 'modules' ||
          module.type === 'highest_module_value' ||
          module.type === 'lowest_module_value') && (
          <div className="input-group">
            <h3>{t('form.label.modules')}</h3>
            <PricingModulesInput
              {...this.props}
              key={module.modules}
              modules={module.modules ? [...module.modules] : null}
              onChange={(newModlues) => {
                const newModule = { ...module };
                newModule.modules = [...newModlues];

                this.onChange(newModule);
              }}
            />
          </div>
        )}

        {module.type === 'ab' && (
          <div className="input-group more">
            <div className="input-group no-margin-top">
              <div>{t('form.label.reverse')}</div>
              <Switch
                checked={module.reverse}
                onChange={(e, newValue) => {
                  const newModule = { ...module };
                  newModule.reverse = newValue;

                  this.onChange(newModule);
                }}
              />
            </div>
          </div>
        )}

        <div className="input-group more filters">
          <IntegrationFiltersInput
            key={`${module.nonce}-${
              module.targetEntityType || pricingEntityTypeOptions[0]?.value || 'trip'
            }`}
            value={module.filters ? module.filters : []}
            entityType={
              module.timeEntityLevel ||
              module.targetEntityType ||
              pricingEntityTypeOptions[0]?.value ||
              'trip'
            }
            dataTypes={[...dataTypes]}
            onChange={(newFilters) => {
              const newModule = { ...module };
              newModule.filters = [...newFilters];

              this.onChange(newModule);
            }}
          />
        </div>
        <div className="input-group more category">
          <div className="input-group no-margin-top ">
            <Select
              options={pricingCategoriesOptions}
              placeholder={t('form.label.pricingCategory')}
              value={pricingCategoriesOptions.find(
                (option) => option.value === module?.category?.id
              )}
              noOptionsMessage={() => t('noOptions')}
              onChange={(event) => {
                const newModule = { ...module };
                newModule.category = event.pricingCategory;

                this.onChange(newModule);
              }}
            />
          </div>
          {this.validator.message(t('form.label.pricingCategory'), module.category, 'required')}
        </div>
        <div className="input-group pricing-module">
          <h3>{t('form.label.taxRates')}</h3>
          <PricingTaxRatesInput
            {...this.props}
            key={module.taxRates}
            taxRates={module.taxRates?.length > 0 ? [...module.taxRates] : []}
            taxRateOptions={this.props.taxRates}
            onChange={(newTaxRates) => {
              const newModule = { ...module };
              newModule.taxRates = newTaxRates;

              this.onChange(newModule);
            }}
          />
        </div>
        <div className="input-group more">
          <div className="input-group no-margin-top">
            <div>{t('form.label.oncePerInvoice')}</div>
            <Switch
              checked={module.oncePerInvoice}
              onChange={(e, newValue) => {
                const newModule = { ...module };
                newModule.oncePerInvoice = newValue;

                this.onChange(newModule);
              }}
            />
          </div>
        </div>
        <FormInput
          type="textarea"
          label="form.label.description"
          className="small"
          value={module.description}
          onChange={(event) => {
            const newModule = { ...module };
            newModule.description = event.target.value;

            this.onChange(newModule);
          }}
        />
        <div className="input-group more right">
          <input
            type="submit"
            disabled={!this.validator.allValid()}
            onClick={(e) => this.handleSubmit(e)}
            value={t('form.save')}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation('translation')(PricingModuleForm);
