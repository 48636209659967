
import { settingsGroupConstants } from "@constants/settingsGroup.constants";

const initialState = {
    isFetchingSettingsGroup: false,
    formSettingsGroup: null,
    settingsGroup: null,

    isFetchingSettingsGroups: false,
    isFetchingSettingsGroupsFrom: 0,
    settingsGroupsTotalResults: 0,
    settingsGroupsLoaded: 0,
    settingsGroups: [],
};

export default function settingsGroup(state = initialState, action) {
    switch (action.type) {
        case settingsGroupConstants.SETTINGSGROUP_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case settingsGroupConstants.GET_SETTINGSGROUP_STARTED:
            return { ...state, isFetchingSettingsGroup: true };
        case settingsGroupConstants.GET_SETTINGSGROUP_FAILURE:
            return { ...state, isFetchingSettingsGroup: false, settingsGroup: null, error: action.message || "" };
        case settingsGroupConstants.GET_SETTINGSGROUP_SUCCESS:
            return {
                ...state,
                isFetchingSettingsGroup: false,
                settingsGroup: action.settingsGroup,
            };
            
        case settingsGroupConstants.GET_SETTINGSGROUPS_STARTED:
            return { ...state, isFetchingSettingsGroups: true,  isFetchingSettingsGroupsFrom: action.from || 0 };
        case settingsGroupConstants.GET_SETTINGSGROUPS_FAILURE:
            return { ...state, isFetchingSettingsGroups: false, settingsGroups: [], error: action.message || "" };
        case settingsGroupConstants.GET_SETTINGSGROUPS_SUCCESS:
            return {
                ...state,
                isFetchingSettingsGroups: false,
                settingsGroups: action.from > 0 ? [...state.settingsGroups, ...action.settingsGroups] : action.settingsGroups,
                settingsGroupsLoaded:
                    action.from > 0
                        ? state.settingsGroups.length + action.settingsGroups.length
                        : action.settingsGroups.length,
                settingsGroupsTotalResults: action.totalResults || 0,
            };

        case settingsGroupConstants.SEARCH_SETTINGSGROUPS_STARTED:
            return { ...state, isFetchingSettingsGroups: true, isFetchingSettingsGroupsFrom: action.from || 0  };
        case settingsGroupConstants.SEARCH_SETTINGSGROUPS_FAILURE:
            return { ...state, isFetchingSettingsGroups: false, settingsGroups: [], error: action.message || "" };
        case settingsGroupConstants.SEARCH_SETTINGSGROUPS_SUCCESS:
            return {
                ...state,
                isFetchingSettingsGroups: false,
                settingsGroups: action.from > 0 ? [...state.settingsGroups, ...action.settingsGroups] : action.settingsGroups,
                settingsGroupsLoaded:
                    action.from > 0
                        ? state.settingsGroups.length + action.settingsGroups.length
                        : action.settingsGroups.length,
                settingsGroupsTotalResults: action.totalResults || 0,
            };

        case settingsGroupConstants.CREATING_SETTINGSGROUP_STARTED:
            return { ...state, isFetchingSettingsGroup: true };
        case settingsGroupConstants.CREATING_SETTINGSGROUP_FAILURE:
            return { ...state, isFetchingSettingsGroup: false, settingsGroup: null, error: action.message || "" };
        case settingsGroupConstants.CREATING_SETTINGSGROUP_SUCCESS:
            return {
                ...state,
                isFetchingSettingsGroup: false,
                settingsGroup: action.settingsGroup,
                settingsGroups: [action.settingsGroup, ...state.settingsGroups],
            };

        case settingsGroupConstants.UPDATING_SETTINGSGROUP_STARTED:
            return { ...state, isFetchingSettingsGroup: true };
        case settingsGroupConstants.UPDATING_SETTINGSGROUP_FAILURE:
            return { ...state, isFetchingSettingsGroup: false, settingsGroup: null, error: action.message || "" };
        case settingsGroupConstants.UPDATING_SETTINGSGROUP_SUCCESS:
            return {
                ...state,
                isFetchingSettingsGroup: false,
                settingsGroup: action.settingsGroup,
                settingsGroups: [...state.settingsGroups].map((settingsGroup) => {
                    if (action.settingsGroup.id === settingsGroup.id) {
                        return action.settingsGroup;
                    }
                    return settingsGroup;
                }),
            };

        case settingsGroupConstants.DELETE_SETTINGSGROUP_STARTED:
            return { ...state, isFetchingSettingsGroup: true };
        case settingsGroupConstants.DELETE_SETTINGSGROUP_FAILURE:
            return { ...state, isFetchingSettingsGroup: false, settingsGroup: null, message: action.message || "" };
        case settingsGroupConstants.DELETE_SETTINGSGROUP_SUCCESS:
            return {
                ...state,
                isFetchingSettingsGroup: false,
                settingsGroup: action.settingsGroup,
                settingsGroups: [...state.settingsGroups].filter((settingsGroup) => action.settingsGroup.id !== settingsGroup.id),
            };
        default:
            return state;
    }
}    
    