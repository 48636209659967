import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import { administrativeReferenceToString } from '@utils/locationUtils';

class TransportEquipmentCard extends Component {
  render() {
    const { t, goods } = this.props;
    return (
      <div className="transportEquipment-card">
        <h2>{goods.name ? `${goods.name}` : ''}</h2>
        {goods.type && (
          <p className="no-margin-bottom value-list-item">
            <b>{t('goods.equipmentType')}:</b> {goods.equipmentType}, {goods.equipmentSubType}
          </p>
        )}
        {goods.quantity ? (
          <div className="quantity">
            <b>{t('goods.quantity')}:</b> {goods.quantity}
          </div>
        ) : null}
        {goods.weight && (
          <div className="weight">
            <b>{t('goods.weight')}</b>: {`${goods.weight.value} ${goods.weight.unit}`}
          </div>
        )}
        {goods.grossWeight && (
          <div className="grossWeight">
            <b>{t('goods.grossWeight')}</b>:{' '}
            {`${goods.grossWeight.value} ${goods.grossWeight.unit}`}
          </div>
        )}
        {goods.width && goods.length && goods.height && (
          <div>
            <b>{t('goods.dimensions')}</b>:{' '}
            {`${goods?.length?.value || 0} x ${goods?.width?.value || 0} x ${
              goods?.height?.value || 0
            } ${goods?.width?.unit}`}
          </div>
        )}
        {goods.loadMeters && (
          <div className="loadMeter">
            <b>{t('goods.loadMeters')}</b>: {`${goods.loadMeters.value} ${goods.loadMeters.unit}`}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(TransportEquipmentCard);
